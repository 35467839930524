<div *ngIf="icon || label" class="card-chip margin-bottom-2" [ngClass]="{ chip: showChipsLabel }">
  <div
    matBadgeOverlap="false"
    [matBadgeColor]="badgeColor"
    [matBadge]="showCount ? count : null"
    class="vertical-center"
  >
    <img
      *ngIf="!labelOnly && icon && !iconType"
      [src]="icon"
      width="30"
      [matTooltip]="label"
      [matTooltipDisabled]="showChipsLabel"
      alt="icon for {{ icon }}"
    />
    <mat-icon
      class="chip-icon"
      *ngIf="!labelOnly && icon && iconType === 'material'"
      [matTooltip]="label"
      [matTooltipDisabled]="showChipsLabel"
    >
      {{ icon }}
    </mat-icon>
    <mat-icon
      class="chip-icon"
      *ngIf="!labelOnly && icon && iconType === 'svg'"
      [matTooltip]="label"
      [matTooltipDisabled]="showChipsLabel"
      [svgIcon]="icon"
    >
    </mat-icon>
    <span class="chip-label" *ngIf="showChipsLabel && label">{{ label }}</span>
  </div>
</div>
