import { Inject, Injectable } from '@angular/core';
import { APP_VERSION } from '@cmx/shared/util/environment-config';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  versionMissMatch$ = new BehaviorSubject<boolean>(false);
  public latestVersion = this.getAppVersion();
  public latestVersionString = this.appVersion;
  constructor(@Inject(APP_VERSION) private appVersion: string) {}

  getLatestAppVersion(): Observable<number> {
    return of(this.getAppVersion());
  }

  getAppVersion() {
    return parseInt(this.appVersion.replace(/\./g, ''));
  }
}
