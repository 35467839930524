<mat-horizontal-stepper>
  <mat-step *ngFor="let step of field.fieldGroup; let index = index; let last = last">
    <ng-template matStepLabel>{{ step.templateOptions.label }}</ng-template>
    <formly-field [field]="step"></formly-field>

    <div>
      <button matStepperPrevious mat-raised-button color="primary" *ngIf="index !== 0">Back</button>

      <button matStepperNext *ngIf="!last" mat-raised-button color="primary" [disabled]="!isValid(step)">Next</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
