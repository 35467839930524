<div class="outer-container">
  <div class="logo-wrapper">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/prodeo-admin-app.appspot.com/o/icons%2Flogos%2Fprodeo-full-logo.svg?alt=media&token=3e11dbfb-91bc-4f1d-9f6a-6b6a480df666"
      alt="logo"
    />
  </div>
  <div class="text-wrapper">
    <p>Hello! {{ auth.currentUser?.firstName ? auth.currentUser.firstName : '' }}</p>
    <p>Please wait while we download data for offline operations. It will take few minutes to complete</p>
    <p>
      <mat-progress-bar mode="determinate" color="red" [value]="100"></mat-progress-bar>
    </p>
  </div>
</div>
