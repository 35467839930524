import { environment } from '../../environments/environment';
import { HTTPSERVICESETTINGS, APP_VERSION, HEADER_COLOR } from '@cmx/shared/util/environment-config';
import { InjectionToken } from '@angular/core';

export const services: ProdeoProvider[] = [
  { provide: HTTPSERVICESETTINGS, useValue: environment.httpSettings },
  { provide: APP_VERSION, useValue: environment.appVersion },
  { provide: HEADER_COLOR, useValue: environment.headerColor },
];

interface ProdeoProvider {
  provide: InjectionToken<unknown>;
  useValue: unknown;
}
