import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGeneratorComponent } from './form-generator.component';

import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { FormStepperComponent } from '@cmx/shared/ui/form-stepper';
import { BarcodeScannerComponent } from '@cmx/shared/ui/barcode-scanner';
import { FilterBarComponent } from '@cmx/shared/ui/filter-bar';
import { FileUploadComponent } from '@cmx/shared/ui/file-upload';
import { FileViewerComponent } from '@cmx/shared/ui/file-viewer';
import { CommentsComponent } from '@cmx/shared/feature/comments';
import { HeaderComponent } from '@cmx/shared/ui/header';
import { ButtonComponent } from '@cmx/shared/ui/button';
import { FabButtonComponent } from '@cmx/shared/ui/fab-button';
import { LabelComponent } from '@cmx/shared/ui/label';
import { RepeatSectionComponent } from '@cmx/shared/ui/repeat-section';
import { VideoComponent } from '@cmx/shared/ui/video';
import { FormTabsComponent } from '@cmx/shared/ui/form-tabs';
import { TextEditorComponent } from '@cmx/shared/ui/text-editor';
import { SignaturePadComponent } from '@cmx/shared/ui/signature-pad';
import { SearchbarComponent } from '@cmx/shared/ui/searchbar';
import { CounterComponent } from '@cmx/shared/ui/counter';
import { CardComponent, CardListComponent } from '@cmx/shared/ui/card';
import { IconComponent } from '@cmx/shared/ui/icon';
import { FormlyListComponent } from '@cmx/shared/ui/formly-list';
import { CardWithCounterComponent, CardCounterListComponent } from '@cmx/shared/ui/card-with-counter';
import { AutoCompleteComponent, AutoCompleteModule } from '@cmx/shared/ui/formly-autocomplete';
import { SelectBoxComponent } from '@cmx/shared/ui/select-box';
import { TagBoxComponent } from '@cmx/shared/ui/tag-box';
import { TabsComponent } from '@cmx/shared/ui/tabs';
import { ImageCaptureComponent } from '@cmx/shared/ui/camera';
import { ChipsComponent } from '@cmx/shared/ui/chips';
import { PdfFileViewerComponent } from '@cmx/shared/feature/pdf-file-viewer';
import { BadgeListComponent } from '@cmx/shared/ui/badge-list';
import {
  CardWidgetChipsComponent,
  CardWidgetComponent,
  DynamicCardComponent,
  CardWidgetModule,
  ChipsListWithPropsComponent,
} from '@cmx/shared/ui/card-widget';
import { PanelWrapperComponent, PanelModule } from '@cmx/shared/ui/panel';
import { MilestoneComponent, MilestoneModule } from '@cmx/shared/ui/milestone';

import {
  PieChartComponent,
  GridPieChartComponent,
  AdvancePieChartComponent,
  BarHorizontalChartComponent,
  BarHorizontalGroupedChartComponent,
  BarHorizontalNormalizedChartComponent,
  BarHorizontalStackedChartComponent,
  BarVerticalChartComponent,
  BarVerticalGroupedChartComponent,
  BarVerticalNormalizedChartComponent,
  BarVerticalStackedChartComponent,
  LineChartComponent,
  AreaChartComponent,
  StackedAreaChartComponent,
  NormalizedAreaChartComponent,
  GaugeChartComponent,
  HeatMapChartComponent,
  PolarChartComponent,
  TreeMapChartComponent,
  BubbleChartComponent,
  NumberCardChartComponent,
  ChartsModule,
} from '@cmx/shared/ui/charts';

import { PouchdbGenericCrudModule } from '@cmx/shared/data-access/pouchdb-generic-crud';

import { BarcodeListnerModule } from '@cmx/shared/feature/barcode-listner';
import { GridComponent, GridModule } from '@cmx/shared/ui/grid';
import { VideoStatusComponent, VideoStatusModule, FileStatusComponent } from '@cmx/shared/feature/video-status';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { ZxingScannerComponent } from '@cmx/shared/feature/zxing-scanner';
import { ImageViewerComponent } from '@cmx/shared/ui/image-viewer';

export function minLengthValidationMessage(err, field) {
  return `${field.templateOptions.label || field.key} should have atleast ${
    field.templateOptions.minLength
  } characters`;
}

export function maxLengthValidationMessage(err, field) {
  return `${field.templateOptions.label || field.key} should be less than ${
    field.templateOptions.maxLength
  } characters`;
}

export function minValidationMessage(err, field) {
  return `${field.templateOptions.label || field.key} should be more than ${field.templateOptions.min - 1}`;
}

export function maxValidationMessage(err, field) {
  return `${field.templateOptions.label || field.key} should be less than ${field.templateOptions.max + 1}`;
}

export function startWithLetterValidatorMessage(err, field) {
  return `${field.templateOptions.label || field.key} should start with a letter`;
}

export function scannedStringValidatorMessage(err, field) {
  return `${field.templateOptions.label || field.key} does not match required format`;
}

export function onlyNumbersValidatorMessage(err, field) {
  return `${field.templateOptions.label || field.key} should contain only numbers`;
}

export function noBlankSpacesValidatorMessage(err, field) {
  return `This field is required`;
}

export function startWithLetterValidator(control: FormControl): ValidationErrors {
  return /^[a-zA-Z].*[\s.]*$/g.test(control.value) ? null : { startWithLetter: true };
}

export function scannedStringValidator(control: FormControl): ValidationErrors {
  return /^[a-zA-Z0-9]*$/g.test(control.value) ? null : { scannedString: true };
}

export function onlyNumbersValidator(control: FormControl): ValidationErrors {
  return /^[0-9]*$/g.test(control.value) ? null : { onlyNumbers: true };
}

export function noBlankSpacesValidator(control: FormControl): ValidationErrors {
  return control.value && control.value.trim() == '' ? { noBlankSpaces: true } : null;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    AutoCompleteModule,
    GridModule,
    MatNativeDateModule,
    MilestoneModule,
    FormlyMatDatepickerModule,
    CardWidgetModule,
    PanelModule,
    VideoStatusModule,
    FormlyModule.forRoot({
      extras: { lazyRender: true },
      validators: [
        { name: 'scannedString', validation: scannedStringValidator },
        { name: 'startWithLetter', validation: startWithLetterValidator },
        { name: 'onlyNumbers', validation: onlyNumbersValidator },
        { name: 'noBlankSpaces', validation: noBlankSpacesValidator },
      ],
      validationMessages: [
        { name: 'scannedString', message: scannedStringValidatorMessage },
        { name: 'startWithLetter', message: startWithLetterValidatorMessage },
        { name: 'noBlankSpaces', message: noBlankSpacesValidatorMessage },
        { name: 'onlyNumbers', message: onlyNumbersValidatorMessage },
        { name: 'required', message: 'This field is required' },
        { name: 'minlength', message: minLengthValidationMessage },
        { name: 'maxlength', message: maxLengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
      ],
      types: [
        {
          name: 'milestone',
          component: MilestoneComponent,
        },
        {
          name: 'barcode-scanner',
          component: BarcodeScannerComponent,
        },
        {
          name: 'filter-bar',
          component: FilterBarComponent,
        },
        {
          name: 'file-upload',
          component: FileUploadComponent,
        },
        {
          name: 'file-viewer',
          component: FileViewerComponent,
        },
        {
          name: 'image-viewer',
          component: ImageViewerComponent,
        },
        {
          name: 'comments',
          component: CommentsComponent,
        },
        {
          name: 'stepper',
          component: FormStepperComponent,
          wrappers: [],
        },
        {
          name: 'button',
          component: ButtonComponent,
        },
        {
          name: 'fab-button',
          component: FabButtonComponent,
        },
        {
          name: 'label',
          component: LabelComponent,
        },
        {
          name: 'repeatSection',
          component: RepeatSectionComponent,
        },
        {
          name: 'header',
          component: HeaderComponent,
        },
        {
          name: 'form-tabs',
          component: FormTabsComponent,
        },
        {
          name: 'text-editor',
          component: TextEditorComponent,
        },
        {
          name: 'signature-pad',
          component: SignaturePadComponent,
        },
        {
          name: 'search-bar',
          component: SearchbarComponent,
        },
        {
          name: 'counter',
          component: CounterComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'card',
          component: CardComponent,
        },
        {
          name: 'card-list',
          component: CardListComponent,
        },
        {
          name: 'icon',
          component: IconComponent,
        },
        {
          name: 'video',
          component: VideoComponent,
        },
        {
          name: 'pie-chart',
          component: PieChartComponent,
        },
        {
          name: 'advance-pie-chart',
          component: AdvancePieChartComponent,
        },
        {
          name: 'grid-pie-chart',
          component: GridPieChartComponent,
        },
        {
          name: 'horizonatal-bar',
          component: BarHorizontalChartComponent,
        },
        {
          name: 'horizonatal-grouped-bar',
          component: BarHorizontalGroupedChartComponent,
        },
        {
          name: 'horizonatal-stacked-bar',
          component: BarHorizontalStackedChartComponent,
        },
        {
          name: 'horizonatal-normalized-bar',
          component: BarHorizontalNormalizedChartComponent,
        },
        {
          name: 'vertical-bar',
          component: BarVerticalChartComponent,
        },
        {
          name: 'vertical-grouped-bar',
          component: BarVerticalGroupedChartComponent,
        },
        {
          name: 'vertical-stacked-bar',
          component: BarVerticalStackedChartComponent,
        },
        {
          name: 'vertical-normalized-bar',
          component: BarVerticalNormalizedChartComponent,
        },
        {
          name: 'line-chart',
          component: LineChartComponent,
        },
        {
          name: 'area-chart',
          component: AreaChartComponent,
        },
        {
          name: 'stacked-area-chart',
          component: StackedAreaChartComponent,
        },
        {
          name: 'normalized-area-chart',
          component: NormalizedAreaChartComponent,
        },
        {
          name: 'bubble-chart',
          component: BubbleChartComponent,
        },
        {
          name: 'gauge-chart',
          component: GaugeChartComponent,
        },
        {
          name: 'heat-map-chart',
          component: HeatMapChartComponent,
        },
        {
          name: 'tree-map-chart',
          component: TreeMapChartComponent,
        },
        {
          name: 'number-card-chart',
          component: NumberCardChartComponent,
        },
        {
          name: 'polar-chart',
          component: PolarChartComponent,
        },
        {
          name: 'list',
          component: FormlyListComponent,
        },
        {
          name: 'card-counter-list',
          component: CardCounterListComponent,
        },
        {
          name: 'card-with-counter',
          component: CardWithCounterComponent,
        },
        {
          name: 'autocomplete',
          component: AutoCompleteComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'select-box',
          component: SelectBoxComponent,
        },
        {
          name: 'tag-box',
          component: TagBoxComponent,
        },
        {
          name: 'tabs',
          component: TabsComponent,
        },
        {
          name: 'image-capture',
          component: ImageCaptureComponent,
        },
        { name: 'grid', component: GridComponent },
        { name: 'chips', component: ChipsComponent },
        { name: 'pdf-viewer', component: PdfFileViewerComponent },
        { name: 'badge-list', component: BadgeListComponent },
        { name: 'card-widget', component: CardWidgetComponent },
        { name: 'dynamic-card', component: DynamicCardComponent },
        { name: 'card-chips', component: CardWidgetChipsComponent },
        { name: 'card-chips-list', component: ChipsListWithPropsComponent },
        { name: 'video-status', component: VideoStatusComponent },
        { name: 'file-status', component: FileStatusComponent },
        { name: 'zxing-scanner', component: ZxingScannerComponent },
      ],
      wrappers: [{ name: 'panel', component: PanelWrapperComponent }],
    }),
    FormlyMaterialModule,
    MatButtonModule,
    PouchdbGenericCrudModule,
    BarcodeListnerModule,
  ],
  declarations: [FormGeneratorComponent],
  exports: [FormGeneratorComponent],
})
export class FormGeneratorModule {}
