import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cmx-error-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss'],
})
export class ErrorAlertComponent {
  @Input() errorAlertTitle = '';
  @Input() errorBodyClass = '';
  @Input() errorAlertContent = 'There was an error with your request, please try again';

  constructor(@Inject(MAT_DIALOG_DATA) public data: SimpleErrorDialogData) {
    this.errorAlertTitle = data.errorAlertTitle || this.errorAlertTitle;
    this.errorBodyClass = data.errorBodyClass || this.errorBodyClass;
    this.errorAlertContent = data.errorAlertContent || this.errorAlertContent;
  }
}

interface SimpleErrorDialogData {
  errorAlertTitle: string;
  errorAlertContent: string;
  errorBodyClass?: string;
}
