import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { Dimensions, SeriesData } from '../charts.model';
import { FieldType } from '@ngx-formly/core';
import { seriesData } from '../chart.data';

@Component({
  selector: 'cmx-tree-map-chart',
  templateUrl: './tree-map-chart.component.html',
  styleUrls: ['./tree-map-chart.component.scss'],
})
export class TreeMapChartComponent extends FieldType implements OnInit {
  @Input() data: SeriesData[] = seriesData || [];
  @Input() dimensions: Dimensions = { width: '', height: '' };
  @Input() allowResize = false;
  @Input() enableGradient = true;
  @Input() tooltipDisabled = false;
  @Input() enableAnimations = true;
  @Input() isFormlyField = true;

  @Output() raiseTreeMapChartEvent = new EventEmitter<UiEventRaised>();

  view: [number, number] = [500, 300];

  constructor(private element: ElementRef) {
    super();
  }

  ngOnInit() {
    if (this.isFormlyField) {
      this.dimensions = this.to.dimensions || this.dimensions;
      this.enableGradient = this.to.enableGradient || this.enableGradient;
      this.tooltipDisabled = this.to.tooltipDisabled || this.tooltipDisabled;
      this.enableAnimations = this.to.enableAnimations || this.enableAnimations;
      this.data = this.to.data || this.data;
      this.allowResize = this.to.allowResize || this.allowResize;
    }
    this.resize(this.dimensions.width, this.dimensions.height);
  }

  onSelect(data: unknown): void {
    this.raiseEvent('tree-map-chart-clicked', data, false);
  }

  raiseEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseTreeMapChartEvent.emit(eventToRaise);
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (this.allowResize) if (this.allowResize) this.resize();
  }

  resize(width?: string | number, height?: string | number, timeout = 0) {
    setTimeout(() => {
      const parentElement = this.element.nativeElement.parentElement.parentElement;
      if (parentElement) {
        this.view = [+(width || parentElement.offsetWidth - 30), +(height || parentElement.offsetHeight - 60)];
      }
    }, timeout);
  }
}
