export const loadingButton: TimerButton = {
  label: 'Loading',
  icon: 'loop',
  color: 'disabled',
  event: 'none',
};

export const startButton: TimerButton = {
  label: 'Record',
  icon: 'play_arrow',
  color: 'primary',
  event: 'start',
};

export const resumeButton: TimerButton = {
  label: 'Resume',
  icon: 'play_arrow',
  color: 'warn',
  event: 'resume',
};

export const stopButton: TimerButton = {
  label: 'Pause',
  icon: 'pause',
  color: 'light',
  event: 'pause',
};

interface TimerButton {
  label: string;
  icon: string;
  color: string;
  event: string;
}
