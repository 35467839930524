import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { Axis, Dimensions, Legend, MultiSeriesData } from '../charts.model';
import { Curves } from '../charts.utils';
import { FieldType } from '@ngx-formly/core';
import { multiSeriesData } from '../chart.data';
@Component({
  selector: 'cmx-polar-chart',
  templateUrl: './polar-chart.component.html',
  styleUrls: ['./polar-chart.component.scss'],
})
export class PolarChartComponent extends FieldType implements OnInit {
  @Input() data: MultiSeriesData[] = multiSeriesData || [];

  @Input() dimensions: Dimensions = { width: '', height: '' };
  @Input() allowResize = false;
  @Input() legend: Legend = {
    visible: true,
    title: 'Legend',
    position: 'right' || 'below',
  };
  @Input() axis: Axis = {
    showXAxis: true,
    showYAxis: true,
    xAxisLabel: 'xAxis',
    yAxisLabel: 'yAxis',
    showXAxisLabel: true,
    showYAxisLabel: false,
  };
  @Input() enableAnimations = true;
  @Input() showGridLines = true;
  @Input() autoScale = true;
  @Input() tooltipDisabled = false;
  @Input() curveType = 'cardinalClosed';
  @Input() isFormlyField = true;

  @Output() raisePolarChartEvent = new EventEmitter<UiEventRaised>();

  view: [number, number] = [500, 300];
  curve = Curves[this.curveType];

  constructor(private element: ElementRef) {
    super();
  }

  ngOnInit() {
    if (this.isFormlyField) {
      this.dimensions = this.to.dimensions || this.dimensions;
      this.legend = this.to.legend || this.legend;
      this.axis = this.to.axis || this.axis;
      this.showGridLines = this.to.showGridLines || this.showGridLines;
      this.autoScale = this.to.autoScale || this.autoScale;
      this.curveType = this.to.curveType || this.curveType;
      this.tooltipDisabled = this.to.tooltipDisabled || this.tooltipDisabled;
      this.enableAnimations = this.to.enableAnimations || this.enableAnimations;
      this.data = this.to.data || this.data;
      this.allowResize = this.to.allowResize || this.allowResize;
    }
    this.resize(this.dimensions.width, this.dimensions.height);
  }

  onSelect(data: unknown): void {
    this.raiseEvent('polar-chart-clicked', data, false);
  }

  raiseEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raisePolarChartEvent.emit(eventToRaise);
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.resize(undefined, this.dimensions.height);
  }

  resize(width?: string | number, height?: string | number, timeout = 0) {
    setTimeout(() => {
      const parentElement = this.element.nativeElement.parentElement.parentElement;
      if (parentElement) {
        this.view = [+(width || parentElement.offsetWidth - 30), +(height || parentElement.offsetHeight - 100)];
      }
    }, timeout);
  }
}
