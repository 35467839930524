import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CounterCard, CounterChildCard, UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { mapDataWithTemplate } from '@cmx/shared/util/helper-functions';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'cmx-card-with-counter',
  templateUrl: './card-with-counter.component.html',
  styleUrls: ['./card-with-counter.component.scss'],
})
export class CardWithCounterComponent extends FieldType implements OnInit {
  @Input() isFormlyField = true;
  @Input() showBadge = false;
  @Input() showRightIcon = false;
  @Input() showLeftIcon = true;
  @Input() enableChildList = false;
  @Input() leftIconColor = '';
  @Input() rightIconColor = '';
  @Input() enableCollapse = true;
  @Input() allowCommenting = false;
  @Input() restrictKeys: any[] = [];

  @Input() data: CounterCard;

  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  childProperties = true;
  commentValueChange = new Subject<{ comment: string; item: CounterChildCard }>();

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.showBadge = this.to.showBadge || this.showLeftIcon;
      this.showRightIcon = this.to.showRightIcon || this.showRightIcon;
      this.showLeftIcon = this.to.showLeftIcon || this.showLeftIcon;
      this.allowCommenting = this.to.allowCommenting || this.allowCommenting;
      this.enableCollapse = this.to.enableCollapse || this.enableCollapse;
      this.restrictKeys = this.to.restrictKeys || this.restrictKeys;
      this.data = this.formControl.value || this.data;
    }

    this.data = this.parseTemplate({ ...this.data });

    this.raiseEventOnCommentChange();
  }

  parseTemplate(data: any) {
    const template = {
      cardContent: {
        displayName: 'reference',
        rightIcon: "'close'",
        imageSrc:
          "'https://firebasestorage.googleapis.com/v0/b/prodeo-admin-app.appspot.com/o/icons%2Fcargo%2FCM_task_canine.svg?alt=media&token=627e6e22-6d6c-478a-aba3-9e5f477b405e'",
        count: 'totalPieces',
        childCard: [
          {
            displayName: "'ALARMED'",
            count: { key: "'alarmedCount'", value: 'alarmedCount', maxCount: 'totalPieces' },
            comment: { key: "'alarmedReason'", value: 'alarmedReason' },
            cardColor: "'warn'",
            svgIcon: "'alarmed'",
          },
          {
            displayName: "'UNSCREENED'",
            count: { key: "'unscreenedCount'", value: 'unscreenedCount', maxCount: 'totalPieces' },
            comment: { key: "'unscreenedReason'", value: 'unscreenedReason' },
            cardColor: "'accent'",
            svgIcon: "'unscreened'",
          },
        ],
      },
    };

    return mapDataWithTemplate(data, template, data);
  }

  cardClicked() {
    const eventData: UiEventRaised = {
      isNavigation: false,
      data: this.data,
      eventName: 'CARD_COUNTER_ITEM_CLICKED',
    };
    this.cardEvent(eventData);
  }

  rightIconClicked() {
    if (this.enableCollapse) {
      this.childProperties = !this.childProperties;
    } else {
      const eventData: UiEventRaised = {
        isNavigation: false,
        data: this.data,
        eventName: 'CARD_RIGHT_ICON_CLICKED',
      };
      this.cardEvent(eventData);
    }
  }

  raiseCouterEvent(event: UiEventRaised, item: CounterChildCard) {
    if (item?.count?.key) {
      this.data.cardContent.childCard.forEach(child => {
        if (child.count && child.count.key == item.count.key) {
          child.count.value = event.data;
        }
      });
      this.updateOriginalData(this.data, event.data, item?.count?.key);

      const eventData: UiEventRaised = {
        isNavigation: false,
        data: this.data,
        eventName: 'UPDATE_REFERENCE',
      };
      this.cardEvent(eventData);
    }
  }

  commentChange(comment: string, item: CounterChildCard) {
    this.commentValueChange.next({ comment, item });
  }

  raiseEventOnCommentChange() {
    this.commentValueChange.pipe(debounceTime(500), distinctUntilChanged()).subscribe(({ comment, item }) => {
      if (item?.comment?.key) {
        this.updateOriginalData(this.data, comment, item?.comment?.key);
        const eventData: UiEventRaised = {
          isNavigation: false,
          data: this.data,
          eventName: 'UPDATE_REFERENCE',
        };
        this.cardEvent(eventData);
      }
    });
  }

  cardEvent(event: UiEventRaised) {
    event.data = JSON.parse(JSON.stringify(event.data));
    delete event.data.cardContent;
    if (this.isFormlyField) {
      this.formControl.setValue(event.data);
      this.to.getFieldEvent(event);
    } else {
      this.raiseEvent.emit(event);
    }
  }

  updateOriginalData(obj: unknown, value: unknown, path: any) {
    let i: number;
    path = path.split('_');
    for (i = 0; i < path.length - 1; i++) {
      obj = obj[path[i]];
    }
    obj[path[i]] = value;
  }
}
