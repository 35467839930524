import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { Axis, Dimensions, Legend, MultiSeriesData } from '../charts.model';
import { Curves, getRandomColor } from '../charts.utils';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss'],
})
export class AreaChartComponent extends FieldType implements OnInit {
  @Input() data: MultiSeriesData[] = [];
  @Input() dimensions: Dimensions = { width: '', height: '' };
  @Input() allowResize = false;
  @Input() legend: Legend = {
    title: 'Legend',
    position: 'right' || 'below',
    visible: true,
  };
  @Input() axis: Axis = {
    showXAxis: false,
    showYAxis: false,
    xAxisLabel: 'xAxis',
    yAxisLabel: 'yAxis',
    showXAxisLabel: false,
    showYAxisLabel: false,
  };
  @Input() enableAnimations = true;
  @Input() showGridLines = true;
  @Input() timeline = true;
  @Input() schemeType = 'ordinal' || 'linear';
  @Input() tooltipDisabled = false;
  @Input() itemColor: string[] = [];
  @Input() curveType = 'linear';
  @Input() isFormlyField = true;

  @Output() raiseAreaChartEvent = new EventEmitter<UiEventRaised>();

  colorScheme: { domain: string[] } = { domain: [] };
  view: [number, number] = [500, 400];
  curve: unknown;

  constructor(private element: ElementRef) {
    super();
  }

  ngOnInit() {
    if (this.isFormlyField) {
      this.data = this.to.data || this.data;
      this.allowResize = this.to.allowResize || this.allowResize;

      this.dimensions = this.to.dimensions || this.dimensions;
      this.axis = this.to.axis || this.axis;
      this.legend = this.to.legend || this.legend;
      this.enableAnimations = this.to.enableAnimations || this.enableAnimations;
      this.tooltipDisabled = this.to.tooltipDisabled || this.tooltipDisabled;
      this.curveType = this.to.curveType || this.curveType;
      this.itemColor = this.to.itemColor || this.itemColor;
      this.schemeType = this.to.schemeType || this.schemeType;
      this.timeline = this.to.timeline || this.timeline;
      this.showGridLines = this.to.showGridLines || this.showGridLines;
    }
    this.curve = Curves[this.curveType];
    this.resize(this.dimensions.width, this.dimensions.height);
    this.applyColors();
  }

  applyColors() {
    if (this.itemColor && !this.itemColor.length) {
      this.data.forEach(() => {
        this.colorScheme.domain.push(getRandomColor());
      });
    } else this.colorScheme.domain = this.itemColor;
  }

  onSelect(data: unknown): void {
    this.raiseEvent('area-chart-clicked', data, false);
  }

  raiseEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseAreaChartEvent.emit(eventToRaise);
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.resize('', this.dimensions.height);
  }

  resize(width?: number | string, height?: number | string, timeout = 0) {
    setTimeout(() => {
      const parentElement = this.element.nativeElement.parentElement.parentElement;
      if (parentElement) {
        this.view = [+(width || parentElement.offsetWidth - 30), +(height || parentElement.offsetHeight)];
      }
    }, timeout);
  }
}
