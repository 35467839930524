import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends FieldType {
  @Output() raiseEvent = new EventEmitter<UiEventRaised>();
  @Input() isFormlyField = true;

  @Input() color = '';
  @Input() label = '';
  @Input() icon = '';
  @Input() buttonType = '';
  @Input() svgIcon = '';
  @Input() disabled = false;
  @Input() eventName = '';

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.label = this.to.label || this.label;
      this.eventName = this.to.eventName || '';
      this.icon = this.to.icon || this.icon;
      this.svgIcon = this.to.svgIcon || this.svgIcon;
      this.color = this.to.color || this.color;
      this.buttonType = this.to.buttonType || this.buttonType;
      this.disabled = this.to.disabled || this.disabled;
    }
  }

  buttonClicked(): void {
    console.log(this.eventName);
    const eventToRaise: UiEventRaised = {
      eventName: this.eventName,
      isNavigation: false,
      data: {},
    };
    if (eventToRaise.eventName === 'BEGIN_SCREENING') {
      setTimeout(() => {
        if (!this.isFormlyField) {
          this.raiseEvent.emit(eventToRaise);
        } else {
          this.to.getFieldEvent(eventToRaise);
        }
      }, 1500);
    } else {
      if (!this.isFormlyField) {
        this.raiseEvent.emit(eventToRaise);
      } else {
        this.to.getFieldEvent(eventToRaise);
      }
    }
  }
}
