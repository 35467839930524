<div class="card-counter-list">
  <cmx-card-with-counter
    *ngFor="let card of cardList"
    [isFormlyField]="false"
    [data]="card.reference"
    (raiseEvent)="raiseEventFromCard($event)"
    [showBadge]="showBadge"
    [showRightIcon]="showRightIcon"
    [showLeftIcon]="showLeftIcon"
    [allowCommenting]="allowCommenting"
    [enableCollapse]="enableCollapse"
    [restrictKeys]="restrictKeys"
  ></cmx-card-with-counter>
</div>
