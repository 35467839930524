import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { FieldType } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { stripRestrictedKeys } from '@cmx/shared/util/helper-functions';

@Component({
  selector: 'cmx-zxing-scanner',
  templateUrl: './zxing-scanner.component.html',
  styleUrls: ['./zxing-scanner.component.scss'],
})
export class ZxingScannerComponent extends FieldType implements OnInit {
  qrResponse = false;
  showScanner = false;

  @ViewChild('scanner') scanner: ZXingScannerComponent | any;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.AZTEC,
    BarcodeFormat.CODABAR,
    BarcodeFormat.CODE_39,
    BarcodeFormat.CODE_93,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_8,
    BarcodeFormat.EAN_13,
    BarcodeFormat.ITF,
    BarcodeFormat.MAXICODE,
    BarcodeFormat.PDF_417,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.RSS_14,
    BarcodeFormat.RSS_EXPANDED,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION,
  ];

  hasDevices = false;

  hasPermission = false;

  qrResultString = '';

  torchEnabled = false;

  torchAvailable$ = new BehaviorSubject<boolean>(false);

  tryHarder = false;

  availableDevices: MediaDeviceInfo[] = [];

  currentDevice: any;

  @Input() isFormlyField = true;
  @Output() scannedBarcode = new EventEmitter<UiEventRaised>();

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.isFormlyField) {
      console.log('I am a formly field.');
    }
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(result: any) {
    if (!this.qrResponse) {
      this.raiseEvent('BARCODE_EVENT', { barcode: result.trim(), manual: false }, false);
    }
    this.qrResponse = true;
  }

  onDeviceSelectChange(selected: any) {
    const device = this.availableDevices.find(x => x.deviceId === selected.value);
    this.currentDevice = device || null;
  }

  openFormatsDialog() {
    const data = {
      formatsEnabled: this.formatsEnabled,
    };
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onScanComplete(e: any) {
    /* console.log('------------------------------');
    console.log(e);
    console.log('------------------------------'); */
  }

  openInfoDialog() {
    const data = {
      hasDevices: this.hasDevices,
      hasPermission: this.hasPermission,
    };
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }

  raiseEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = {
      eventName,
      data: stripRestrictedKeys(data as string, ["'", '\\', '"', '<', '>']),
      isNavigation,
    };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.scannedBarcode.emit(eventToRaise);
    }
  }
}
