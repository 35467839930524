import { MangoQuery } from 'rxdb';

export enum TraceType {
  info,
  warning,
  error,
}

export enum CollectionNames {
  assets = 'assets',
  states = 'states',
  timezones = 'timezones',
  countries = 'countries',
  basemodels = 'basemodels',
  locations = 'locations',
  basemodeldefaults = 'basemodeldefaults',
  canines = 'canines',
  references = 'references',
  tasks = 'tasks',
  companies = 'companies',
  users = 'users',
  tasksubtypes = 'tasksubtypes',
  comments = 'comments',
  modes = 'modes',
  facilities = 'facilities',
  tasktypes = 'tasktypes',
  settings = 'settings',
  statuses = 'statuses',
  transactions = 'transactions',
  transactioncodes = 'transactioncodes',
  milestonecodes = 'milestonecodes',
  milestones = 'milestones',
  caninescreenings = 'caninescreenings',
  tenantconfigs = 'tenantconfigs',
  priorities = 'priorities',
  cargoscreenings = 'cargoscreenings',
  forms = 'forms',
  statemachines = 'statemachines',
  cardtemplates = 'cardtemplates',
  error = 'error',
  log = 'log',
  cargoscreening = 'cargoscreening',
}

export enum SyncStatus {
  default,
  synced,
}

export interface QuerySelector {
  selector: MangoQuery;
  collection: string;
  documentId?: string;
}

export interface MachineEvent {
  type: string;
  data?: any;
}

export interface FileInfo {
  asset: any;
  file: Blob;
}
