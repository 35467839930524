import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';

@Component({
  selector: 'cmx-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent extends FieldType implements OnInit {
  @Input() isFormlyField = true;
  @Input() iconMode: 'mat-icon' | 'image-icon' = 'mat-icon';
  @Input() iconSrc = '';
  @Input() icon = '';
  @Input() svgIcon = '';
  @Input() color = 'primary';
  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.iconMode = this.to.iconMode || this.iconMode;
      this.icon = this.to.icon || this.icon;
      this.svgIcon = this.to.svgIcon || this.svgIcon;
      this.iconSrc = this.to.iconSrc || this.iconSrc;
      this.color = this.to.color || this.color;
    }
  }

  iconClicked() {
    const iconEvent: UiEventRaised = {
      isNavigation: false,
      data: this.iconSrc,
      eventName: 'icon-clicked',
    };
    if (this.isFormlyField) {
      this.to.getFieldEvent(iconEvent);
    } else {
      this.raiseEvent.emit(iconEvent);
    }
  }
}
