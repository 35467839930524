/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'cmx-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploaderComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploaderComponent implements ControlValueAccessor {
  @Input() label = 'Upload File';
  @Input() iconName = 'attach_file';
  @Input() multiple = false;
  @Input() extensions = '';
  @Input() enabled = true;
  @Input() files: File[] = [];

  @Output() changed = new EventEmitter<File[]>();

  public onChangeCallback: (val: any) => void;
  public onTouchedCallback: () => void;

  private filesSubject$ = new BehaviorSubject([]);

  public files$: Observable<File[]> = this.filesSubject$.asObservable();

  constructor(private cdr: ChangeDetectorRef) {}

  public onChange(input: HTMLInputElement): void {
    this.doChange(Array.from(input.files));
    input.value = '';
  }

  private allowed(file: File): boolean {
    const exists = !!this.files.find(
      item => item.name === file.name && item.size === file.size && item.type === file.type,
    );

    const exts = this.extensions.split(',').filter(ext => (ext || '').trim());
    const allowed = !exts.length || (exts.length && exts.some(ext => file.name.endsWith(ext)));
    return !exists && allowed;
  }

  public removeFile(file: File): void {
    const index = this.files.indexOf(file);

    if (index > -1) {
      this.files.splice(index, 1);
      this.changed.emit(this.files);
      this.filesSubject$.next([...this.files]);
    }
    this.cdr.detectChanges();
  }

  public clearFiles() {
    this.files.splice(0, this.files.length);

    this.cdr.detectChanges();
  }

  public doChange(files: File | File[]): void {
    let changed = false;
    if (!files) {
      this.files.length = 0;
      changed = true;
    } else {
      if (!(files instanceof Array)) {
        files = [files];
      }
      for (const file of files) {
        if (this.allowed(file)) {
          if (this.multiple) {
            this.files.push(file);
          } else {
            this.files.length = 0;
            this.files.push(file);
          }

          changed = true;
        }
      }
    }

    if (changed) {
      this.changed.emit(this.files);
      this.clearFiles();
      this.call(this.onChangeCallback, files);
      this.filesSubject$.next([...this.files]);
      this.cdr.detectChanges();
    }
  }

  writeValue(files: File[]): void {
    this.doChange(files);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.enabled = !isDisabled;
  }

  private call(fn: (val: any) => void, val?: any): void {
    if (fn && typeof fn === 'function') {
      fn(val);
    }
  }
}
