import { Component, OnInit } from '@angular/core';
import { AppVersionService } from './app-version.service';
import { SwUpdate } from '@angular/service-worker';
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
  deleteItemFromLocalStorage,
} from '@cmx/shared/data-access/local-storage';
@Component({
  selector: 'cmx-app-version-loader',
  templateUrl: './app-version-loader.component.html',
  styleUrls: ['./app-version-loader.component.scss'],
})
export class AppVersionLoaderComponent implements OnInit {
  reloads = 0;
  constructor(public appVersionService: AppVersionService, private swUpdate: SwUpdate) {}

  ngOnInit(): void {
    this.reloads = getItemFromLocalStorage('reloads') ? parseInt(getItemFromLocalStorage('reloads') as string) : 0;
    if (this.reloads <= 0) {
      setTimeout(() => {
        if (this.appVersionService.getAppVersion() < this.appVersionService.latestVersion) {
          setItemToLocalStorage('reloads', 1);
          window.location.reload();
        }
      }, 1000);
    }

    this.initializeApp();
  }

  initializeApp(): void {
    if (this.swUpdate.available) {
      this.swUpdate.available.subscribe(() => {
        deleteItemFromLocalStorage('reloads');
        window.location.reload();
      });
    }
  }
}
