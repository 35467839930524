import { Component, ElementRef, HostListener, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FieldType } from '@ngx-formly/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ImageCaptureComponent } from './image-capture/image-capture.component';

export interface DialogData {
  width: number;
  height: number;
}

@Component({
  selector: 'cmx-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class CameraComponent extends FieldType implements OnInit {
  /** Defines the max width of the webcam area in px */
  public width = 340;
  /** Defines the max height of the webcam area in px */
  public height = 600;
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId = '';
  public videoOptions: MediaTrackConstraints = {
    width: { ideal: 1024 },
    height: { ideal: 800 },
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: any = null;
  public images: any = [];

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor(
    private element: ElementRef,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ImageCaptureComponent>,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.width = this.data?.width;
    this.height = this.data?.height;
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });
    this.resize();
  }

  public close(): void {
    this.dialogRef.close(this.showWebcam ? null : this.webcamImage);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
    this.toggleWebcam();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.log('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    this.images.push(webcamImage);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.resize();
  }

  resize() {
    setTimeout(() => {
      const parentElement = this.element.nativeElement.parentElement;
      if (parentElement) {
        this.width = parentElement.offsetWidth - 30;
        this.height = parentElement.offsetHeight;
        console.log(this.width, this.height);
      }
    });
  }
}
