<mat-chip-list aria-label="chip-list" class="padding-1">
  <ng-container *ngFor="let item of data?.chips">
    <mat-chip *ngIf="item.icon || item.label" [color]="item.color">
      <div matBadgeOverlap="false" [matBadgeColor]="item?.badgeColor" class="vertical-center" [matBadge]="item?.count">
        <mat-icon
          class="margin-right-2"
          *ngIf="item.iconType === 'mat-icon' && item.icon"
          [matTooltip]="item?.label"
          [matTooltipDisabled]="showLabel"
          >{{ item?.icon }}</mat-icon
        >
        <mat-icon class="margin-right-2" *ngIf="item.svgIcon" [svgIcon]="item?.svgIcon" [matTooltip]="item.label">
        </mat-icon>
        <img
          *ngIf="item?.icon && item.iconType === 'custom'"
          width="35"
          [src]="item.icon"
          alt="icon for {{ item.icon }}"
          [matTooltip]="item?.label"
          [matTooltipDisabled]="showLabel"
        />
        <span *ngIf="showLabel && item.label">{{ item?.label }}</span>
      </div>
    </mat-chip></ng-container
  >
</mat-chip-list>
