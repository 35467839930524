<div class="comments">
  <div class="comments-container" *ngFor="let comment of comments">
    <cmx-comment
      (newComment)="newComment($event)"
      (deleteComment)="deleteComment($event)"
      (updateComment)="updateComment($event)"
      *ngIf="!comment.parentId"
      [comment]="comment"
      [replies]="getReplies(comment.id)"
    ></cmx-comment>
  </div>
  <div class="comment-input-container">
    <cmx-user-avatar [image]="currentUser?.picture"></cmx-user-avatar>
    <cmx-comment-input (doneEditing)="newComment($event)" placeholderText="Write a comment..."></cmx-comment-input>
  </div>
</div>
