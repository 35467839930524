import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised, HeaderConfiguration } from '@cmx/shared/feature/platform-configuration';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends FieldType implements OnInit {
  @Input() headerConfiguration: HeaderConfiguration = {
    title: 'Header Title',
    visible: true,
  };

  @Input() isFormlyField = true;
  @Input() headerColor = 'primary';
  @Output() raiseHeaderEvent = new EventEmitter<UiEventRaised>();

  showContextMenuButton = false;

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.headerConfiguration = this.to.headerConfiguration || this.headerConfiguration;
    }
    this.checkContextMenu();
  }

  ngOnChanges(): void {
    this.showContextMenuButton = false;
    this.checkContextMenu();
  }

  checkContextMenu() {
    if (
      this.headerConfiguration &&
      this.headerConfiguration.rightIconList &&
      this.headerConfiguration.rightIconList.length
    )
      this.showContextMenuButton = this.headerConfiguration.rightIconList.some(item => !item.showInBar);
  }

  raiseEvent(eventToRaise: UiEventRaised): void {
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseHeaderEvent.emit(eventToRaise);
    }
  }

  sideDrawerClicked(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseHeaderEvent.emit(eventToRaise);
    }
  }
}
