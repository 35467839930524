import { Component, Input, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-pdf-file-viewer',
  templateUrl: './pdf-file-viewer.component.html',
  styleUrls: ['./pdf-file-viewer.component.scss'],
})
export class PdfFileViewerComponent extends FieldType implements OnInit {
  @Input() height = '100%';
  @Input() isFormlyField = true;
  @Input() width = '100%';
  @Input() pdfSrc = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';

  ngOnInit() {
    if (this.isFormlyField) {
      this.height = this.to.height || this.height;
      this.width = this.to.width || this.width;
      this.pdfSrc = this.to.pdfSrc || this.pdfSrc;
    }
  }
}
