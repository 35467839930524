<div class="outer-container">
    <div class="logo-wrapper">
        <img src="https://firebasestorage.googleapis.com/v0/b/prodeo-admin-app.appspot.com/o/icons%2Flogos%2Fprodeo-full-logo.svg?alt=media&token=3e11dbfb-91bc-4f1d-9f6a-6b6a480df666"
            alt="logo" />
    </div>
    <div class="text-wrapper">
        <div class="title-container">
            <mat-icon class="info-icon">info</mat-icon>
            <p class="version-update">New Version update!</p>
        </div>     
        <p>Please wait while we are updating your version to {{appVersionService.latestVersionString}}</p>
        <p>
            <mat-progress-bar mode="indeterminate" color="red">
            </mat-progress-bar>
        </p>
    </div>
</div>