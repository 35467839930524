import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'cmx-tag-box',
  templateUrl: './tag-box.component.html',
  styleUrls: ['./tag-box.component.scss'],
})
export class TagBoxComponent extends FieldType implements OnInit {
  @Input() data: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];
  @Input() displayName = 'value';
  @Input() label = 'Tag Box';
  @Input() multiple = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() isFormlyField = true;
  @Input() changeEvent = 'TAG_BOX_CHANGE_EVENT';
  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  tagBoxcontrol = new FormControl();

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.displayName = this.to.displayName || this.displayName;
      this.label = this.to.label || this.label;
      this.multiple = this.to.multiple || this.multiple;
      this.appearance = this.to.appearance || this.appearance;
      this.data = (this.to.data as string[]) || this.data;
      this.changeEvent = this.to.changeEvent || this.changeEvent;
    }
  }

  onToppingRemoved(item: string) {
    const selectedItems = this.tagBoxcontrol.value as string[];
    this.removeFirst(selectedItems, item);
    this.tagBoxcontrol.setValue(selectedItems); // To trigger change detection
    this.raiseTagBoxEvent();
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  raiseTagBoxEvent() {
    const eventToRaise: UiEventRaised = {
      eventName: this.changeEvent,
      data: this.tagBoxcontrol.value,
      isNavigation: false,
    };
    if (this.isFormlyField) {
      this.formControl.setValue(this.tagBoxcontrol.value);
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseEvent.emit(eventToRaise);
    }
  }
}
