<ngx-charts-pie-grid
  [view]="view"
  [scheme]="colorScheme"
  [results]="data"
  [animations]="enableAnimations"
  [label]="label"
  [tooltipDisabled]="tooltipDisabled"
  (select)="onSelect($event)"
>
</ngx-charts-pie-grid>
