import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { HeaderConfiguration, UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { mapDataWithTemplate } from '@cmx/shared/util/helper-functions';
import { Observable, Subscription } from 'rxjs';
import { VideoStatusService } from './video-status.service';
import { IconService } from '@cmx/shared/feature/icon-manager';
import { Router } from '@angular/router';
import { HEADER_COLOR } from '@cmx/shared/util/environment-config';
import { getItemFromLocalStorage } from '@cmx/shared/data-access/local-storage';
import { AssetsManagerService } from '@cmx/shared/feature/asset-manager';

@Component({
  selector: 'cmx-video-status',
  templateUrl: './video-status.component.html',
  styleUrls: ['./video-status.component.scss'],
})
export class VideoStatusComponent implements OnInit, OnDestroy {
  headerColor = 'primary';
  header: HeaderConfiguration = {
    title: 'Video Upload Status',
    visible: true,
    leftIcon: {
      icon: 'arrow_back',
      visible: true,
      event: {
        eventName: 'TOGGLE_MENU',
        data: '',
        isNavigation: false,
      },
    },
    rightIconList: [
      {
        event: {
          data: null,
          eventName: 'PROCESS_ASSETS',
          isNavigation: false,
        },
        icon: 'sync',
        showInBar: false,
        text: 'Process Assets',
      },
    ],
  };

  @Input() selected = 'inProgress';
  @Input() buttonList = [
    { icon: 'cloud_upload', label: 'In Progress', value: 'inProgress' },
    { icon: 'cloud_done', label: 'Completed', value: 'completed' },
  ];

  completedVideoList: any[] = [];
  inprogressVideoList: any[] = [];

  completedVideoSubscription: Subscription | undefined;
  inprogressVideoSubscription: Subscription | undefined;

  template = {
    cardContent: {
      title: { key: '', value: 'asset.displayName ? asset.displayName : displayName' },
      subHeader: [
        { key: "'Progress'", value: "asset.uploadProgress ? asset.uploadProgress : uploadProgress & '%'" },
        { key: "'Start'", value: 'asset.timestamp ? asset.timestamp: timestamp', type: "'date'" },
        { key: "'End'", value: 'asset.completedTime ? asset.completedTime: completedTime', type: "'date'" },
      ],
      leftIcon: {
        type: "'svg'",
        icon: "'video-upload'",
      },
    },
  };

  constructor(
    private readonly videoService: VideoStatusService,
    private readonly iconService: IconService,
    private readonly router: Router,
    @Inject(HEADER_COLOR) private headerColorProvider: string,
    private readonly assetManagerService: AssetsManagerService,
  ) {}

  ngOnInit(): void {
    this.headerColor = this.headerColorProvider;
    this.iconService.registerIcon('video-upload', `assets/icons/video-upload.svg`);
    this.getVideoList();
  }

  ngOnDestroy(): void {
    this.inprogressVideoSubscription?.unsubscribe();
    this.completedVideoSubscription?.unsubscribe();
  }

  getVideoList() {
    const inProgressVideos$: Observable<any[]> = this.videoService.getInProgressVideoList();
    this.inprogressVideoSubscription = inProgressVideos$.subscribe(videos => {
      const videoList = videos.filter(video => video.uploadProgress < 100);
      this.inprogressVideoList = this.mapData(videoList);
    });

    const completedVideos$: Observable<any[]> = this.videoService.getCompletedVideoList();
    this.completedVideoSubscription = completedVideos$.subscribe(videos => {
      this.completedVideoList = this.mapData(videos);
    });
  }

  async toggleButton(e: MatButtonToggleChange) {
    this.selected = e.value;
    this.getVideoList();
  }

  mapData(data: unknown[]): any[] {
    if (data.length === 0) {
      return [];
    }
    return data.map((video: unknown) => mapDataWithTemplate(video, this.template, video));
  }

  processAssets(): void {
    const currentUser: any = getItemFromLocalStorage('loggedInUser');
    if (!currentUser) {
      return;
    }

    this.assetManagerService.processAssets(currentUser.id);
  }

  handleEvent(uiEventRaised: UiEventRaised) {
    switch (uiEventRaised.eventName) {
      case 'TOGGLE_MENU':
        this.router.navigateByUrl(
          `/workflow${this.videoService.currentWorkflow ? '/' + this.videoService.currentWorkflow : ''}`,
        );
        break;
      case 'PROCESS_ASSETS':
        this.processAssets();
        break;
      default:
        break;
    }
  }
}
