import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CardData, UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { ProdeoFirestoreQuery } from '@cmx/shared/feature/firestore';
import { CollectionNames } from '@cmx/shared/util/interfaces';
import { CoreMachine } from '@cmx/state-machines/core';
import { mapDataWithTemplate } from '@cmx/shared/util/helper-functions';
@Component({
  selector: 'cmx-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent extends FieldType implements OnInit {
  @Input() data: CardData;
  @Input() counterButtonColor = '';
  @Input() leftIconColor = '';
  @Input() cardColor = '';
  @Input() rightIconColor = '';
  @Input() showCounter = false;
  @Input() showBadge = false;
  @Input() showRightIcon = false;
  @Input() dataIndex: number = null;
  @Input() showLeftIcon = false;
  @Input() clickEventName = 'CARD_CLICKED';
  @Input() isFormlyField = true;
  @Input() cardTemplateId: string;
  @Input() cardTemplate: any;

  @Output() raiseCardEvent = new EventEmitter<UiEventRaised>();
  cardData: { key: string; value: unknown }[];

  constructor(private coreMachineDef: CoreMachine) {
    super();
  }

  async ngOnInit(): Promise<void> {
    if (this.isFormlyField) {
      this.showLeftIcon = this.to.showLeftIcon || this.showLeftIcon;
      this.showRightIcon = this.to.showRightIcon || this.showRightIcon;
      this.showBadge = this.to.showBadge || this.showBadge;
      this.showCounter = this.to.showCounter || this.showCounter;
      this.counterButtonColor = this.to.counterButtonColor || this.counterButtonColor;
      this.leftIconColor = this.to.leftIconColor || this.leftIconColor;
      this.cardColor = this.to.color || this.cardColor;
      this.rightIconColor = this.to.rightIconColor || this.rightIconColor;
      this.clickEventName = this.to.clickEventName || this.clickEventName;
      this.data = this.to.data || this.data;
      this.cardTemplateId = this.to.cardTemplateId;
      this.cardTemplate = this.to.template;
    }

    await this.parseTemplateData();
  }

  async parseTemplateData() {
    try {
      if (this.cardTemplate) {
        this.data = mapDataWithTemplate(this.data, this.cardTemplate, this.data);
      } else {
        if (this.cardTemplateId) {
          const query: ProdeoFirestoreQuery = {
            action: 'FIND',
            queryList: [
              {
                property: 'name',
                operator: '==',
                value: this.cardTemplateId,
              },
              {
                property: 'collectionName',
                operator: '==',
                value: CollectionNames.cardtemplates,
              },
            ],
          };

          const cardTemplates: any = await this.coreMachineDef.executeDBAction(query);

          this.cardTemplate = cardTemplates.length > 0 ? cardTemplates[0] : null;

          delete this.cardTemplate.id;
          delete this.cardTemplate.industry;
          delete this.cardTemplate.collectionName;
          delete this.cardTemplate.name;
          delete this.cardTemplate.vertical;

          this.data = mapDataWithTemplate(this.data, this.cardTemplate, this.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  cardClicked() {
    const eventData: UiEventRaised = {
      isNavigation: false,
      data: this.data,
      eventName: this.clickEventName || 'CARD_CLICKED',
    };
    this.raiseEvent(eventData);
  }

  iconClicked() {
    const eventData: UiEventRaised = {
      isNavigation: false,
      data: this.data,
      eventName: 'card-icon-clicked',
    };
    this.raiseEvent(eventData);
  }

  rightIconClicked() {
    const eventData: UiEventRaised = {
      isNavigation: false,
      data: { obj: this.data, dataIndex: this.dataIndex },
      eventName: 'REMOVE_ITEM_CLICKED',
    };
    this.raiseEvent(eventData);
  }

  counterEvent(event) {
    this.data.count = event.data;
    const eventData: UiEventRaised = {
      isNavigation: false,
      data: this.data,
      eventName: 'counter-clicked',
    };
    console.log(eventData);

    this.raiseEvent(eventData);
  }

  raiseEvent(data: UiEventRaised) {
    const newData = JSON.parse(JSON.stringify(data));
    if (newData.data && newData.data.cardContent) delete newData.data.cardContent;
    if (this.isFormlyField) {
      this.to.getFieldEvent(newData);
    } else {
      this.raiseCardEvent.emit(newData);
    }
  }
}
