<div fxFlexFill fxLayout="column" fxLayoutGap="10px" class="file-upload-wrap" *ngIf="!onlyUploadButton">
  <div class="upload-section">
    <mat-icon class="file-upload-icon">cloud_upload</mat-icon>
    <p>Select file</p>
    <div class="file-upload-button">
      <input
        type="file"
        #photoInput
        value=""
        [accept]="allowedTypes"
        (click)="photoInput.value = ''"
        hidden
        (change)="onFileChange($event)"
      />
      <button
        fxFlex="200px"
        [type]="buttonType"
        mat-raised-button
        [color]="uploadButtonColor"
        (click)="photoInput.click()"
        [disabled]="!multiple && files.length >= 1"
      >
        <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
        {{ label || 'Upload' }}
      </button>
    </div>
  </div>
  <div fxFlexFill class="image-grid">
    <div fxFlexFill [fxLayout]="'row wrap'" fxLayoutGap="10px 10px grid">
      <p class="text-center">No File Selected</p>
      <div fxFlex="200px" *ngFor="let file of files; let fileIndex = index">
        <div class="one" fxFlexFill fxLayoutAlign="center center" class="image-container">
          <img class="image" [src]="getSource(file)" />
          <mat-icon class="delete-icon pointer" [color]="deleteIconColor" (click)="deleteFile(file, fileIndex)"
            >delete</mat-icon
          >
          <span class="mat-caption image-name">{{ file.displayName }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="onlyUploadButton">
  <input
    type="file"
    #photoInput
    value=""
    [accept]="allowedTypes"
    (click)="photoInput.value = ''"
    hidden
    (change)="onFileChange($event)"
  />
  <button
    class="full-width"
    [type]="buttonType"
    mat-raised-button
    [color]="uploadButtonColor"
    (click)="photoInput.click()"
    [disabled]="!multiple && files.length >= 1"
  >
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    {{ isFormlyField ? to?.label : 'Upload' }}
  </button>
</ng-container>
