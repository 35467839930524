import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

import { Tab, UiEventRaised } from '@cmx/shared/feature/platform-configuration';

@Component({
  selector: 'cmx-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent extends FieldType implements OnInit {
  @Input() isFormlyField = true;
  @Input() tabs: Tab[] = [
    //sample

    {
      id: 'id',
      label: 'Tab-1',
      icon: 'home',
      closable: true,
    },
  ];
  @Input() selectedIndex = 0;

  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  ngOnInit(): void {
    console.log(this.tabs);
    if (this.isFormlyField) {
      this.tabs = this.to.data && this.to.data.length ? this.to.data && this.to.data : this.tabs || [];
      this.selectedIndex = this.to.selectedIndex || this.selectedIndex;
    }
  }

  addTab(newTab: Tab, selectAfterAdding = true) {
    const tab = JSON.parse(JSON.stringify(newTab));
    tab.id = this.getId();
    this.tabs.push(tab);

    if (selectAfterAdding) {
      setTimeout(() => (this.selectedIndex = this.tabs.length - 1));
    }
    console.log(this.tabs);
  }

  removeTab(tab: Tab, index: number) {
    index = this.tabs.findIndex(item => item.id == tab.id);
    this.tabs.splice(index, 1);
    if (this.selectedIndex == index) {
      this.selectedIndex = 0;
    }
  }

  selectedIndexChange(index: number) {
    const data = {
      tab: this.tabs[index],
      selectedIndex: index,
    };
    this.raiseTabEvent(data, 'tab-change-event');
  }

  raiseTabEvent(data: { tab: Tab; selectedIndex: number }, eventName: string, isNavigation = false): void {
    const eventToRaise: UiEventRaised = {
      eventName,
      isNavigation,
      data,
    };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseEvent.emit(eventToRaise);
    }
  }

  getId() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }
}
