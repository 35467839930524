import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthenticationComponent } from './authentication.component';
import { SimpleFormGeneratorModule } from '@cmx/shared/feature/simple-form-generator';
import { LoaderModule } from '@cmx/shared/ui/loader';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: '', pathMatch: 'full', component: AuthenticationComponent }]),
    SimpleFormGeneratorModule,
    LoaderModule,
  ],
  declarations: [AuthenticationComponent],
  exports: [AuthenticationComponent],
})
export class SharedFeatureAuthenticationModule {}
