<cmx-form-generator
  [workflowConfiguration]="data?.workflowConfiguration"
  [formTitle]="data?.formTitle"
  [allowSubmit]="data?.allowSubmit"
  [allowReset]="data?.allowReset"
  [submitButtonColor]="data.submitButtonColor"
  (formEvent)="formEvent($event)"
  *ngIf="data?.workflowConfiguration"
  (formSubmit)="onSubmit($event)"
>
</cmx-form-generator>
