import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { Dimensions, Legend, SeriesData } from '../charts.model';
import { getRandomColor } from '../charts.utils';
import { FieldType } from '@ngx-formly/core';
import { seriesData } from '../chart.data';

@Component({
  selector: 'cmx-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent extends FieldType implements OnInit {
  @Input() data: SeriesData[] = seriesData;
  @Input() dimensions: Dimensions = { width: '', height: '' };
  @Input() allowResize = false;
  @Input() legend: Legend = {
    visible: true,
    title: 'Legend',
    position: 'right',
  };
  @Input() enableGradient = false;
  @Input() isDoughnut = false;
  @Input() showLabels = true;
  @Input() trimLabels = true;
  @Input() tooltipDisabled = false;
  @Input() enableAnimations = true;
  @Input() enableExplodeSlices = false;
  @Input() itemColor: string[] = [];
  @Input() isFormlyField = true;

  @Output() raisePieChartEvent = new EventEmitter<UiEventRaised>();

  colorScheme: { domain: string[] } = { domain: [] };
  view: any = [];

  constructor(private element: ElementRef) {
    super();
  }

  ngOnInit() {
    if (this.isFormlyField) {
      this.data = this.to.data || this.data;
      this.allowResize = this.to.allowResize || this.allowResize;
      this.dimensions = this.to.dimensions || this.dimensions;
      this.enableGradient = this.to.enableGradient || this.enableGradient;
      this.legend = this.to.legend || this.legend;
      this.tooltipDisabled = this.to.tooltipDisabled || this.tooltipDisabled;
      this.enableAnimations = this.to.enableAnimations || this.enableAnimations;
      this.itemColor = this.to.itemColor || this.itemColor;
      this.enableExplodeSlices = this.to.enableExplodeSlices || this.enableExplodeSlices;
      this.trimLabels = this.to.trimLabels || this.trimLabels;
      this.showLabels = this.to.showLabels || this.showLabels;
      this.isDoughnut = this.to.isDoughnut || this.isDoughnut;
    }
    this.resize(this.dimensions.width, this.dimensions.height);
    this.applyColors();
  }

  applyColors() {
    if (this.itemColor && !this.itemColor.length) {
      this.data.forEach(() => {
        this.colorScheme.domain.push(getRandomColor());
      });
    } else this.colorScheme.domain = this.itemColor;
  }

  onSelect(data: unknown): void {
    this.raiseEvent('pie-chart-clicked', data, false);
  }

  raiseEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raisePieChartEvent.emit(eventToRaise);
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    if (this.allowResize) this.resize();
  }

  resize(width?: string | number, height?: string | number, timeout = 0) {
    setTimeout(() => {
      const parentElement = this.element.nativeElement.parentElement.parentElement;
      if (parentElement) {
        this.view = [+(width || parentElement.offsetWidth - 30), +(height || parentElement.offsetHeight - 60)];
      }
    }, timeout);
  }
}
