import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised, Badge } from '@cmx/shared/feature/platform-configuration';
import { FieldType } from '@ngx-formly/core';
@Component({
  selector: 'cmx-badge-list',
  templateUrl: './badge-list.component.html',
  styleUrls: ['./badge-list.component.scss'],
})
export class BadgeListComponent extends FieldType implements OnInit {
  @Input() badgeList: Badge[] = [];
  @Input() badgeColor = 'accent';
  @Input() isFormlyField = true;
  @Input() eventName = 'BADGE_CLICK';
  @Output() clickedBadge = new EventEmitter<UiEventRaised>();

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.badgeList = this.to.data;
      this.eventName = this.to.eventName || this.eventName;
    }
  }
  clickEvent(event: UiEventRaised) {
    this.raiseEvent(this.eventName, event.data, event.isNavigation);
  }

  raiseEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.clickedBadge.emit(eventToRaise);
    }
  }
}
