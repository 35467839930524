<div class="form-container">
  <h2 class="form-title" *ngIf="formTitle">
    {{ formTitle }}
  </h2>

  <form [formGroup]="customForm" (ngSubmit)="onSubmit(customForm)" *ngIf="formFieldList">
    <formly-form
      [model]="workflowConfiguration.model"
      [fields]="formFieldList"
      [form]="customForm"
      [options]="options"
    ></formly-form>

    <div class="row space-between">
      <button
        type="submit"
        mat-raised-button
        [color]="submitButtonColor"
        [disabled]="!customForm.valid"
        *ngIf="allowSubmit"
      >
        {{ submitButtonText || 'Submit' }}
      </button>
      <button type="reset" mat-raised-button *ngIf="allowReset" (click)="onResetForm()">
        {{ clearButtonText || 'Clear' }}
      </button>
    </div>
  </form>
</div>
