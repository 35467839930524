import { Injectable } from '@angular/core';
import { AuthenticationService } from '@cmx/shared/feature/authentication';
import { FirestoreService } from '@cmx/shared/feature/firestore';
import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { createMachine, interpret, Interpreter } from 'xstate';
import { FileUploaderService, UploadError, UploadStatus, UploadProgress } from '..';
import { TraceType, CollectionNames } from '@cmx/shared/util/interfaces';

const MACHINE_ID = 'fileUploaderService';
export interface UploadFileContext {
  file: any;
  fileName: string;
  fileId: string;
  bucketPath?: string;
  bucketName?: string;
  sessionURL?: string;
  type?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileUploaderMachineService {
  private interpreter!: any;

  get started$(): BehaviorSubject<UploadStatus> {
    return this.fileUploader.started$;
  }

  get progress$(): BehaviorSubject<UploadProgress> {
    return this.fileUploader.progress$;
  }

  get error$(): BehaviorSubject<UploadError> {
    return this.fileUploader.error$;
  }

  get completed$(): BehaviorSubject<UploadStatus> {
    return this.fileUploader.completed$;
  }

  constructor(
    private auth: AuthenticationService,
    private firestore: FirestoreService,
    private fileUploader: FileUploaderService,
  ) {
    this.initUploaderEvents();
  }

  init(context: UploadFileContext) {
    if (!context) throw new Error('Invalid parameters');

    const fileUploaderMachine = createMachine<UploadFileContext>(
      {
        id: MACHINE_ID,
        initial: 'idle',
        states: {
          idle: {
            on: {
              FILE_VERIFIED: [
                {
                  target: 'uploading',
                  cond: 'validateContext',
                },
              ],
            },
          },
          uploading: {
            invoke: {
              src: 'uploadFile',
              onDone: [],
              onError: [
                {
                  target: 'failure',
                },
              ],
            },
            on: {
              STARTED: {},
              PROGRESS: {},
              COMPLETED: {
                target: 'uploaded',
              },
              FAILURE: {
                target: 'failure',
              },
            },
          },
          uploaded: {
            type: 'final',
            entry: 'end',
          },
          failure: {
            type: 'final',
            entry: 'end',
          },
        },
      },
      {
        guards: {
          validateContext: context => !!context,
        },
        actions: {
          saveSessionURL: (_, event) => console.log(event),
          end: (_, event) => this.interpreter.stop(),
        },
        services: {
          uploadFile: (context: UploadFileContext) => {
            return this.fileUploader.upload(
              context.file,
              context.fileName,
              context.fileId,
              context.bucketName,
              context.bucketPath,
              context.sessionURL,
              context.type,
            );
          },
        },
        activities: {},
      },
    ).withContext(context);

    this.interpreter = interpret(fileUploaderMachine, { devTools: true });
    this.interpreter.onTransition((context, event) => {
      console.log(context, event);
    });
    this.interpreter.start();
    this.sendEvent({ type: 'FILE_VERIFIED' });
  }

  async trace(traceEvent: any) {
    const error = {
      userId: this.auth.currentUser.id,
      type: traceEvent.type,
      collectionName: CollectionNames.log,
      timestamp: Date.now(),
      data: traceEvent.data,
      context: JSON.stringify(traceEvent.context),
      ignoreSync: true,
    };
    return this.firestore.storeDocument(error);
  }

  initUploaderEvents() {
    this.fileUploader.started$
      .pipe(
        filter(event => !!event),
        tap(event => {
          this.sendEvent({ type: 'STARTED', data: event });
        }),
      )
      .subscribe();
    this.fileUploader.progress$
      .pipe(
        filter(event => !!event),
        tap(event => {
          this.sendEvent({ type: 'PROGRESS', data: event });
        }),
      )
      .subscribe();
    this.fileUploader.completed$
      .pipe(
        filter(event => !!event),
        tap(event => {
          this.sendEvent({ type: 'COMPLETED', data: event });
        }),
      )
      .subscribe();
    this.fileUploader.error$
      .pipe(
        filter(event => !!event),
        tap(event => {
          this.trace({
            referenceDocId: event.fileId,
            context: JSON.stringify(this.interpreter.state.context),
            data: JSON.stringify(event),
            type: TraceType.error,
          });
          this.sendEvent({ type: 'FAILURE', data: event });
        }),
      )
      .subscribe();
  }

  sendEvent(event: any) {
    if (this.interpreter) {
      this.interpreter.send(event);
    }
  }
}
