import { Inject, Injectable } from '@angular/core';
import {
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  Firestore,
  getDocs,
  limit,
  orderBy,
  query,
  Query,
  QueryConstraint,
  setDoc,
  where,
  collectionData,
  WhereFilterOp,
  onSnapshot,
  docData,
} from '@angular/fire/firestore';
import { ApiAccessService } from '@cmx/prodeo/data-access/api-access';
import { getItemFromLocalStorage } from '@cmx/shared/data-access/local-storage';
import { ErrorHandlerService } from '@cmx/shared/feature/error-handler';
import { HttpServiceSettings } from '@cmx/shared/feature/platform-configuration';
import { APP_VERSION, HTTPSERVICESETTINGS } from '@cmx/shared/util/environment-config';
import { isOnline } from '@cmx/shared/util/helper-functions';
import jsonata from 'jsonata';
import { DocumentData } from 'rxfire/firestore/interfaces';
import { forkJoin, lastValueFrom, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { FirestoreQuery, FirestoreSort } from './firestore.operators';

interface DataDictionary {
  [key: string]: {
    [key: string]: any;
  };
}

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  initialWorkflowData: any = null;
  constructor(
    private readonly firestore: Firestore,
    @Inject(HTTPSERVICESETTINGS) private httpServiceSettings: HttpServiceSettings,
    @Inject(APP_VERSION) private appVersion: string,
    private readonly apiAccessService: ApiAccessService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  private alreadyLoadedData: DataDictionary = {};

  async storeDocument(documentToStore: any, privateDocument: boolean = true): Promise<unknown> {
    const bucketName = privateDocument ? this.httpServiceSettings.bucketName : 'generic';
    const databasePath = `tenants/${bucketName}/data`;
    documentToStore.appVersion = this.appVersion;
    try {
      let newDocumentReference: DocumentReference<DocumentData>;
      if (documentToStore.id) {
        newDocumentReference = doc(this.firestore, databasePath, documentToStore.id);
      } else {
        newDocumentReference = doc(collection(this.firestore, databasePath));
        documentToStore.id = newDocumentReference.id;
      }

      let resolvePromise = false;

      // resolve promise as soon as local storage is updated. do not wait for remote FB update
      const promise = new Promise((resolve, reject) => {
        const unsubscribe = onSnapshot(
          newDocumentReference,
          snapshot => {
            if (resolvePromise) {
              const data = snapshot.data();

              if (data) {
                unsubscribe();
                this.alreadyLoadedData[bucketName][documentToStore.id] = data;
                resolve(data);
              }
            } else {
              try {
                setDoc(newDocumentReference, documentToStore, { merge: true });
                resolvePromise = true;
              } catch (error: any) {
                const ticketNumber: number = Math.floor(Math.random() * 9999);
                const user: any = getItemFromLocalStorage('loggedInUser');
                const userId: string = user ? user.id : 'noUserIdAvailable';
                let message = `An unknown error has ocurred while trying to store the data, , please contact support and give them this number: ${ticketNumber} and your email address`;

                if (error.code) {
                  if (error.code === 'invalid-argument') {
                    message = `There was an error saving your data, please contact support and give them this number: ${ticketNumber} and your email address`;
                  } else if (error.code === 'permission-denied') {
                    message = `You need to log in to be able to perform that action, if you're logged in, please contact support and give them this number: ${ticketNumber} and your email address`;
                  }
                }

                return this.storeDocument({
                  id: `${userId}-${ticketNumber}`,
                  error: error.message ? error.message : JSON.stringify(error),
                  data: message.includes(`is longer than`)
                    ? 'One of the properties was too heavy'
                    : JSON.stringify(documentToStore),
                  collectionName: 'log',
                  ignoreSync: true,
                }).then(() => {
                  return this.errorHandlerService.openSimpleErrorAlert(``, message);
                });
              }
            }
          },
          () => reject(),
        );
      });

      return await promise;
    } catch (error) {
      console.dir(documentToStore);
      throw console.log(error);
    }
  }

  async getDocumentById(documentId: string, privateDocument: boolean = true): Promise<any> {
    const bucketName = privateDocument ? this.httpServiceSettings.bucketName : 'generic';

    if (this.alreadyLoadedData[bucketName] && this.alreadyLoadedData[bucketName][documentId]) {
      //state machine sets data null. clone it to avoid multiple loads
      const data = JSON.parse(JSON.stringify(this.alreadyLoadedData[bucketName][documentId]));
      return Promise.resolve(data);
    }

    const databasePath = `tenants/${bucketName}/data/${documentId}`;
    const docRef = doc(this.firestore, databasePath);

    if (!this.alreadyLoadedData[bucketName]) {
      this.alreadyLoadedData[bucketName] = {};
    }

    const promise = new Promise((resolve, reject) => {
      onSnapshot(docRef, snapshot => {
        const data = snapshot.data();
        if (data) {
          this.alreadyLoadedData[bucketName][documentId] = data;
          resolve(JSON.parse(JSON.stringify(this.alreadyLoadedData[bucketName][documentId])));
        } else {
          if (this.alreadyLoadedData[bucketName][documentId]) {
            delete this.alreadyLoadedData[bucketName][snapshot.id];
          }
          resolve(null);
        }
      });
    });

    return await promise;
  }

  async populateDocument(documentToPopulate: any, propertiesToPopulate: string[]) {
    const populatedDocument: any = documentToPopulate;
    for (const documentProperty of propertiesToPopulate) {
      if (Array.isArray(populatedDocument[documentProperty])) {
        const idListToPopulate: string[] = [...populatedDocument[documentProperty]];
        populatedDocument[documentProperty] = [];

        for (const idToPopulate of idListToPopulate) {
          if (typeof idToPopulate === 'string') {
            const populatedProperty = await this.getDocumentById(idToPopulate);
            populatedDocument[documentProperty].push(populatedProperty);
          } else {
            populatedDocument[documentProperty].push(idToPopulate);
          }
        }
      } else {
        const splitProperty = documentProperty.split('.');
        if (splitProperty.length > 1) {
          if (typeof populatedDocument[splitProperty[0]][splitProperty[1]] === 'string') {
            populatedDocument[splitProperty[0]][splitProperty[1]] = await this.getDocumentById(
              populatedDocument[splitProperty[0]][splitProperty[1]],
            );
          }
        }
        if (typeof populatedDocument[documentProperty] === 'string') {
          populatedDocument[documentProperty] = await this.getDocumentById(populatedDocument[documentProperty]);
        }
      }
    }

    return populatedDocument;
  }

  async deleteDocumentById(documentId: string): Promise<void> {
    const bucketName: string = this.httpServiceSettings.bucketName;
    try {
      const documentToDeleteReference = doc(this.firestore, `tenants/${bucketName}/data`, documentId);

      let resolvePromise = false;

      // resolve promise as soon as local storage is updated. do not wait for remote FB update
      const promise: Promise<void> = new Promise((resolve, reject) => {
        const unsubscribe = onSnapshot(
          documentToDeleteReference,
          () => {
            if (resolvePromise) {
              unsubscribe();
              delete this.alreadyLoadedData[bucketName][documentId];
              resolve();
            } else {
              deleteDoc(documentToDeleteReference);
              resolvePromise = true;
            }
          },
          () => reject(),
        );
      });

      return await promise;
    } catch (error) {
      console.dir(documentId);
      throw console.log(error);
    }
  }

  async queryDocument(
    queryList: FirestoreQuery[],
    limitTo?: number,
    sortByStatements?: FirestoreSort[],
    privateDocument: boolean = true,
  ) {
    const databasePath = `tenants/${privateDocument ? this.httpServiceSettings.bucketName : 'generic'}/data`;
    const queryConditions: QueryConstraint[] = queryList.map(condition => {
      return where(condition.property, condition.operator, condition.value);
    });

    if (sortByStatements) {
      for (const sortBy of sortByStatements) {
        queryConditions.push(orderBy(sortBy.property, sortBy.order));
      }
    }

    if (limitTo) {
      queryConditions.push(limit(limitTo));
    }

    const queryToPerform: Query<DocumentData> = query(collection(this.firestore, databasePath), ...queryConditions);

    const documentListSnapshot = await getDocs(queryToPerform);
    const documentListToReturn: any[] = [];
    documentListSnapshot.forEach(doc => {
      documentListToReturn.push(doc.data());
    });

    return documentListToReturn;
  }

  getRealTimeUpdatesFromQuery(
    queryList: FirestoreQuery[],
    limitTo?: number,
    sortBy?: FirestoreSort,
    privateDocument: boolean = true,
  ): Observable<any[]> {
    const databasePath = `tenants/${privateDocument ? this.httpServiceSettings.bucketName : 'generic'}/data`;
    const queryConditions: QueryConstraint[] = queryList.map(condition => {
      return where(condition.property, condition.operator, condition.value);
    });

    if (sortBy) {
      queryConditions.push(orderBy(sortBy.property, sortBy.order));
    }

    if (limitTo) {
      queryConditions.push(limit(limitTo));
    }

    const queryToPerform: Query<DocumentData> = query(collection(this.firestore, databasePath), ...queryConditions);

    return collectionData(queryToPerform);
  }

  async loadInitialData(userId: string, timeRangeToDownloadDataInHours: number = 24): Promise<any[]> {
    const timeRangeInMiliseconds = timeRangeToDownloadDataInHours * 60 * 60 * 1000;
    const dateToCompare: number = new Date().getTime() - timeRangeInMiliseconds;

    const userCapsule = {
      userId: userId,
      collections: [
        {
          collectionName: 'modes',
          queryList: [
            {
              property: 'collectionName',
              operator: '==',
              value: 'modes',
            },
          ],
        },
        {
          collectionName: 'users',
          queryList: [
            {
              property: 'collectionName',
              operator: '==',
              value: 'users',
            },
          ],
        },
        {
          collectionName: 'forms',
          queryList: [
            {
              property: 'collectionName',
              operator: '==',
              value: 'forms',
            },
          ],
        },
        {
          collectionName: 'statemachines',
          queryList: [
            {
              property: 'collectionName',
              operator: '==',
              value: 'statemachines',
            },
          ],
        },
        {
          collectionName: 'canines',
          queryList: [
            {
              property: 'collectionName',
              operator: '==',
              value: 'canines',
            },
          ],
        },
        {
          collectionName: 'transactioncodes',
          queryList: [
            {
              property: 'collectionName',
              operator: '==',
              value: 'transactioncodes',
            },
          ],
        },
        {
          collectionName: 'milestonecodes',
          queryList: [
            {
              property: 'collectionName',
              operator: '==',
              value: 'milestonecodes',
            },
          ],
        },
        {
          collectionName: 'facilities',
          queryList: [
            {
              property: 'collectionName',
              operator: '==',
              value: 'facilities',
            },
          ],
        },
        {
          collectionName: 'statuses',
          queryList: [
            {
              property: 'collectionName',
              operator: '==',
              value: 'statuses',
            },
          ],
        },
        {
          collectionName: 'tasks',
          queryList: [
            {
              property: 'collectionName',
              operator: '==',
              value: 'tasks',
            },
            {
              property: 'assignees',
              operator: 'array-contains',
              value: userId,
            },
            {
              property: 'start',
              operator: '>',
              value: dateToCompare,
            },
          ],
        },
      ],
    };

    const databasePath = `tenants/${this.httpServiceSettings.bucketName}/data`;

    const collectionList: Observable<DocumentData[]>[] = [];

    userCapsule.collections.forEach(currentCollection => {
      const queryConditions: QueryConstraint[] = currentCollection.queryList.map(condition => {
        const operator: WhereFilterOp = condition.operator as WhereFilterOp;
        return where(condition.property, operator, condition.value);
      });
      currentCollection.queryList.forEach;
      collectionList.push(
        collectionData(query(collection(this.firestore, databasePath), ...queryConditions)).pipe(first()),
      );
    });

    const allData: any[] = await lastValueFrom(forkJoin(collectionList).pipe(first()));

    const allFlattenedData = [].concat(...allData);

    allFlattenedData.forEach((prodeoDocument: any) => {
      const collectionName: string = prodeoDocument.collectionName;
      if (!this.alreadyLoadedData[collectionName]) {
        this.alreadyLoadedData[collectionName] = {};
      }
      this.alreadyLoadedData[collectionName][prodeoDocument.id] = prodeoDocument;
    });

    return allFlattenedData;
  }

  async loadWorkflowIcons(): Promise<void> {
    if (isOnline()) {
      const databasePath = `tenants/${this.httpServiceSettings.bucketName}/data/k9_icons`;
      const docRef = doc(this.firestore, databasePath);

      const iconDocument: any = await lastValueFrom(docData(docRef).pipe(first()));

      if (iconDocument && iconDocument.icons) {
        const icons: string[] = iconDocument.icons;
        icons.forEach(icon => {
          this.apiAccessService.sendGetForImage(icon).pipe(first()).subscribe();
        });
      }
    }
  }

  async loadInitialWorkflowData(currentUser: any) {
    console.log(currentUser);
    if (this.initialWorkflowData) {
      return this.initialWorkflowData;
    }

    const currentWorkflowData: WorkflowInitialDataQuery[] = [
      {
        collectionName: 'forms',
        queryList: [
          {
            property: 'collectionName',
            operator: '==',
            value: "'forms'",
          },
        ],
      },
      {
        collectionName: 'modes',
        queryList: [
          {
            property: 'collectionName',
            operator: '==',
            value: "'modes'",
          },
        ],
        sortBy: [{ property: 'name', order: 'asc' }],
      },
      {
        collectionName: 'companies',
        queryList: [
          {
            property: 'collectionName',
            operator: '==',
            value: "'companies'",
          },
        ],
        sortBy: [{ property: 'name', order: 'asc' }],
      },
      {
        collectionName: 'canines',
        queryList: [
          {
            property: 'collectionName',
            operator: '==',
            value: "'canines'",
          },
          {
            property: 'id',
            operator: 'in',
            value: 'settings.canineScreeningConfig.canines',
          },
        ],
        sortBy: [{ property: 'name', order: 'asc' }],
      },
      {
        collectionName: 'facilities',
        queryList: [
          {
            property: 'collectionName',
            operator: '==',
            value: "'facilities'",
          },
        ],
        sortBy: [{ property: 'name', order: 'asc' }],
      },
      {
        collectionName: 'transactioncodes',
        queryList: [
          {
            property: 'collectionName',
            operator: '==',
            value: "'transactioncodes'",
          },
        ],
      },
      {
        collectionName: 'milestonecodes',
        queryList: [
          {
            property: 'collectionName',
            operator: '==',
            value: "'milestonecodes'",
          },
        ],
      },
      {
        collectionName: 'statuses',
        queryList: [
          {
            property: 'collectionName',
            operator: '==',
            value: "'statuses'",
          },
        ],
      },
    ];

    const databasePath = `tenants/${this.httpServiceSettings.bucketName}/data`;

    const collectionList: Observable<DocumentData[]>[] = [];

    currentWorkflowData.forEach(currentCollection => {
      let isCurrentConditionValid = true;
      const queryConditions: QueryConstraint[] = currentCollection.queryList.map(condition => {
        const value: any = this.evaluate(condition.value as string, currentUser);
        const isEmptyArray: boolean = Array.isArray(value) && value.length === 0;
        isCurrentConditionValid = !isEmptyArray;
        const operator: WhereFilterOp = condition.operator as WhereFilterOp;
        return where(condition.property, operator, this.evaluate(condition.value as string, currentUser));
      });

      if (currentCollection.sortBy) {
        for (const sortBy of currentCollection.sortBy) {
          queryConditions.push(orderBy(sortBy.property, sortBy.order));
        }
      }

      if (isCurrentConditionValid) {
        collectionList.push(
          collectionData(query(collection(this.firestore, databasePath), ...queryConditions)).pipe(first()),
        );
      }
    });

    const allData = await lastValueFrom(forkJoin(collectionList).pipe(first()));
    this.initialWorkflowData = {};

    allData.forEach(collectionResult => {
      this.initialWorkflowData[collectionResult[0].collectionName] = collectionResult;
    });

    return this.initialWorkflowData;
  }

  evaluate(expresion: string, data: any): any {
    const result = jsonata(expresion).evaluate(data);
    return result;
  }
}

interface WorkflowInitialDataQuery {
  collectionName: string;
  queryList: FirestoreQuery[];
  sortBy?: FirestoreSort[];
}
