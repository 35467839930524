<div fxFlexFill [fxLayout]="'row wrap'" class="flex-center" fxLayoutGap="10px 10px grid">
  <p class="text-center" *ngIf="!files.length">No File</p>
  <div fxFlex="180px" *ngFor="let file of files; let fileIndex = index">
    <div class="one" fxFlexFill fxLayoutAlign="center center" class="image-container">
      <img class="image" [src]="getSource(file)" />
      <mat-icon class="delete-icon pointer" [color]="deleteIconColor" (click)="deleteFile(file, fileIndex)"
        >delete</mat-icon
      >
      <span class="mat-caption image-name">{{ file.displayName }}</span>
    </div>
  </div>
</div>
