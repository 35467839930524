import { Component, Inject, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AuthenticationService } from './services/shared-feature-authentication.service';
import { APP_VERSION } from '@cmx/shared/util/environment-config';
import { ErrorHandlerService } from '@cmx/shared/feature/error-handler';
import { lastValueFrom } from 'rxjs';

const enum PageType {
  LOGIN = 'login',
  RESET_EMAIL = 'password-reset',
  RESETPASSWORD = 'reset',
}
@Component({
  selector: 'cmx-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  version: string;
  formTitle = 'Sign In';
  formActionText = 'Sign In';
  isLoginPage = true;
  formModel: unknown;
  formFields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'Email Address',
        required: true,
        type: 'email',
      },
      validators: {
        validation: Validators.compose([Validators.required, Validators.email]),
      },
    },
  ];
  url: string;
  resetPasswordToken: string;
  resetEmail: string;
  loading: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    @Inject(APP_VERSION) private appVersion: string,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {
    this.authService.signOutUser();
    this.version = this.appVersion;
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id');
      if (id) {
        this.resetPasswordToken = id.split(' ')[0];
        this.resetEmail = id.split(' ')[1];
      }
    });

    this.url = this.router.url.substring(1);
    if (this.url.includes('/')) {
      this.url = this.url.split('/')[0];
    }

    this.isLoginPage = this.url == 'login';
    this.initializeForm();
  }

  initializeForm(): void {
    switch (this.url) {
      case PageType.LOGIN: {
        this.formModel = {
          email: '',
          password: '',
        };
        this.formFields.push({
          key: 'password',
          type: 'input',
          templateOptions: {
            label: 'Password',
            required: true,
            type: 'password',
          },
        });
        break;
      }
      case PageType.RESET_EMAIL: {
        this.formTitle = 'Reset Your Password';
        this.formActionText = 'Reset Password';
        this.formModel = {
          email: '',
        };
        break;
      }
      case PageType.RESETPASSWORD: {
        this.formTitle = 'Create New Password';
        this.formActionText = 'Create Password';
        this.formModel = {
          newPassword: '',
        };
        this.formFields = [
          {
            key: 'newPassword',
            type: 'input',
            templateOptions: {
              label: 'New Password',
              required: true,
              type: 'password',
            },
          },
        ];
        break;
      }
    }
  }

  async submitUserCredentials(event) {
    const { value, valid } = event.data;

    if (valid) {
      switch (this.url) {
        case PageType.LOGIN: {
          let loginErrorMessage = `Either the password is incorrect or there's no user with that account.`;
          this.loading = true;
          this.authService
            .signInUser(value)
            .then((result: any) => {
              this.loading = false;
              this.router.navigateByUrl('');
            })
            .catch(error => {
              if (error && error.code && error.code === 'auth/network-request-failed') {
                loginErrorMessage = `No internet connection, please connect to the internet in order to log in.`;
              }
              this.loading = false;
              this.displayConfirmationDialog(loginErrorMessage);
            });
          break;
        }
        case PageType.RESET_EMAIL: {
          const passwordResetMessage = `An email has been sent to the address associated with your account.Please contact support if you have not received the email
          after a few minutes.`;
          this.loading = true;

          this.authService
            .resetPasswordMail(value.email)
            .then(async (result: any) => {
              console.log(result);
              this.loading = false;
              await this.displayConfirmationDialog(passwordResetMessage);
              this.router.navigateByUrl('login');
            })
            .catch(async error => {
              console.log(error);
              await this.displayConfirmationDialog(passwordResetMessage);
              this.router.navigateByUrl('login');
              this.loading = false;
            });
          break;
        }
      }
    }
  }

  signOutUser() {
    this.authService.signOutUser();
  }

  async displayConfirmationDialog(message: string): Promise<void> {
    const informationAlert = this.errorHandlerService.openSimpleErrorAlert(``, message);
    await lastValueFrom(informationAlert.afterClosed());
  }
}
