import { Injectable } from '@angular/core';
import { CollectionNames } from '@cmx/shared/util/interfaces';
import { BaseDataLoaderService } from './base-data-loader.service';

@Injectable({
  providedIn: 'root',
})
export class MachineLoaderService extends BaseDataLoaderService {
  load(machineId: string) {
    return this.loadData(machineId, CollectionNames.statemachines);
  }
}
