import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SyncLoaderComponent } from './sync-loader.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    ToastrModule.forRoot({
      toastComponent: SyncLoaderComponent,
      positionClass: 'toast-center-center',
      toastClass: 'cmx-toast ngx-toastr',
    }),
  ],
  declarations: [SyncLoaderComponent],
  exports: [SyncLoaderComponent],
})
export class SyncLoaderModule {}
