import { Component, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FormlyFormBuilder, FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-repeat-section',
  templateUrl: './repeat-section.component.html',
  styleUrls: ['./repeat-section.component.scss'],
})
export class RepeatSectionComponent extends FieldArrayType implements OnInit {
  formControl: any;
  /**
   * Necessary because FormlyFormBuilder doesn't seem to handle
   * FieldArray types correctly if the key contains periods.
   * For v5, will need to use the prePopulate hook insetead of createControl.
   * */
  static createControl(): FormArray {
    return new FormArray([]);
  }

  get fieldArrayClassName(): string {
    return this.field?.fieldArray?.className || '';
  }

  constructor(private formlyFormBuilder: FormlyFormBuilder) {
    super(formlyFormBuilder);
  }

  ngOnInit() {
    // Make sure the form array reflects the model; see comment above
    // const form = new FormArray([]);
    // for (let i = 0; i < this.model.length; i++) {
    //     this.formlyFormBuilder.buildForm(form, [this.field.fieldGroup[i]], this.model, this.options);
    //     this.formControl.insert(i, form.at(0));
    // }
    // (<any>this.options).resetTrackModelChanges();
  }

  canAdd(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const canAdd = this.to['canAdd'] as Function | boolean;
    return canAdd == null || (typeof canAdd === 'function' ? canAdd.apply(this) : canAdd) === true;
  }

  canRemove(index: number): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const canRemove = this.to['canRemove'] as Function | boolean;
    if (canRemove === false) {
      return false;
    }

    const value = this.model[index];
    if (value && value.canRemove === false) {
      return false;
    }

    return typeof canRemove !== 'function' || canRemove.apply(this, [index]) === true;
  }
}
