<mat-drawer-container [hasBackdrop]="hasBackdrop">
  <mat-drawer #drawer [mode]="mode" [opened]="isOpened" [position]="position">
    <div class="sidenav-header">
      <div class="text-center">
        <img matListAvatar matPrefix [src]="app?.logo" [alt]="app?.logo" class="app-logo" />
      </div>

      <mat-list>
        <mat-list-item>
          <img
            matListAvatar
            matPrefix
            [src]="currentUser?.image || defaultUserAvatar"
            class="user-image"
            [alt]="currentUser?.image"
          />
          <span class="dot dot-position" [ngClass]="currentUser?.status"></span>
          <div matLine class="user-name">{{ currentUser?.fullName }}</div>
          <div matLine class="user-email">{{ currentUser?.email }}</div>
          <div matLine class="user-status" *ngIf="currentUser?.status">
            <span [matMenuTriggerFor]="menu">
              {{ userStatus?.label }}
            </span>

            <mat-menu #menu="matMenu">
              <div class="text-center">Status</div>
              <mat-radio-group class="sidenav-radio-group" [(ngModel)]="currentUser.status" (change)="changeStatus()">
                <mat-radio-button
                  class="sidenav-radio-button"
                  *ngFor="let status of statusItems"
                  [value]="status.value"
                >
                  <span class="dot" [ngClass]="status?.value"></span>
                  <span class="margin-left-5px">{{ status?.label }}</span>
                </mat-radio-button>
              </mat-radio-group>
            </mat-menu>
          </div>
        </mat-list-item>
      </mat-list>
    </div>

    <mat-divider></mat-divider>

    <div class="sidenav-content">
      <mat-nav-list>
        <mat-list-item *ngFor="let item of menuItems" (click)="navigate(item)">
          <img matListAvatar *ngIf="item?.iconSrc" [src]="item.iconSrc" [alt]="item.iconSrc" />
          <mat-icon *ngIf="!item?.iconSrc" matListIcon [color]="item?.iconColor">{{ item?.icon }}</mat-icon>
          <div matLine>{{ item?.name }}</div>
        </mat-list-item>
      </mat-nav-list>
    </div>

    <mat-divider></mat-divider>

    <div class="sidenav-footer">
      <p *ngIf="app?.version">
        Version: <span>{{ app?.version }}</span>
      </p>
      <button mat-raised-button [color]="logoutButtonColor" (click)="logout()">
        {{ logoutText }}
      </button>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <ng-content select=".project"> </ng-content>
  </mat-drawer-content>
</mat-drawer-container>
