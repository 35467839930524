<li class="uploader-list-item" *ngIf="src$ | async as src" (click)="download(src, file.name)">
  <div class="info">
    <div class="img-container">
      <div class="img" [ngStyle]="getBackgroundImage(src)"></div>
    </div>
    <div class="content">
      <label class="text filename">{{ file.name }}</label>
      <label class="text filesize">{{ file.size }} bytes</label>
    </div>
  </div>
  <div *ngIf="!readOnly" class="actions">
    <span (click)="removeFile($event)" class="material-icons">clear</span>

  </div>
</li>
