import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { RXJSDBService } from '@cmx/shared/data-access/rxdb';
import { AppVersionService } from '@cmx/shared/feature/app-version-loader';
import { createDBFactory } from './app-db.factory';
import { appVersionFactory } from './app-version.factory';

export const createDBProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: createDBFactory,
  deps: [RXJSDBService],
  multi: true,
};

export const appVersionProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: appVersionFactory,
  deps: [AppVersionService],
  multi: true,
};
