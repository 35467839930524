import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FromUser } from '@cmx/shared/feature/authentication';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { FieldType } from '@ngx-formly/core';
import { Comment } from './shared-feature-comments.model';

@Component({
  selector: 'cmx-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
})
export class CommentsComponent extends FieldType implements OnInit {
  @Input() comments: Comment[] = [];
  @Input() currentUser: FromUser;
  @Input() isFormlyField = true;

  @Output() raiseCommentEvent: EventEmitter<UiEventRaised> = new EventEmitter();

  raiseEvent(uiEventRaised: UiEventRaised): void {
    if (this.isFormlyField) {
      this.to.getFieldEvent(uiEventRaised);
    } else {
      this.raiseCommentEvent.emit(uiEventRaised);
    }
  }

  ngOnInit() {
    if (this.isFormlyField) {
      const { comments, currentUser } = this.to.data;
      this.comments = comments || this.comments || [];
      this.currentUser = currentUser || this.currentUser;
    }
  }

  getReplies(commentId: number): Comment[] {
    return this.comments.filter(comment => comment.parentId === commentId);
  }

  updateComment(updatedCommentcomment: Comment): void {
    this.raiseEvent({
      eventName: 'comment-updated',
      data: {
        updatedCommentcomment,
      },
    });

    const newData = this.comments.map(comment => {
      if (comment.id === updatedCommentcomment.id) {
        return updatedCommentcomment;
      }
      return comment;
    });

    this.comments = [...newData];
  }

  newComment(comment: Comment) {
    const maxIdComment = this.comments.reduce((previous, current) => (previous.id > current.id ? previous : current), {
      id: 0,
    } as Comment);
    comment.id = maxIdComment.id + 1;
    comment.from = { ...this.currentUser };
    this.comments.push(comment);
    this.raiseEvent({ eventName: 'comment-added', data: comment });
  }

  deleteComment(deletedComment: Comment) {
    const newComments = [...this.comments.filter(comment => comment.id !== deletedComment.id)];
    this.comments = newComments;
    this.raiseEvent({ eventName: 'comment-deleted', data: deletedComment });
  }
}
