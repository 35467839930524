import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterBarComponent } from './filter-bar.component';

import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { SimpleFormGeneratorModule } from '@cmx/shared/feature/simple-form-generator';

@NgModule({
  imports: [CommonModule, MatBadgeModule, MatChipsModule, MatIconModule, SimpleFormGeneratorModule],
  declarations: [FilterBarComponent],
  exports: [FilterBarComponent],
})
export class FilterBarModule {}
