<ng-container *ngIf="!isFormlyField">
  <input
    [type]="type || 'text'"
    [placeholder]="placeholder"
    aria-label="auto-complete"
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
  />
</ng-container>

<ng-container *ngIf="isFormlyField">
  <input
    matInput
    [id]="id"
    [type]="to.type || 'text'"
    [readonly]="to.readonly"
    [required]="to.required"
    [formControl]="control"
    [formlyAttributes]="field"
    [tabIndex]="to.tabindex"
    [placeholder]="to.placeholder"
    [matAutocomplete]="auto"
    [errorStateMatcher]="errorStateMatcher"
  />
</ng-container>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="optionSelected($event)">
  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
    {{ option[displayName] || option }}
  </mat-option>
</mat-autocomplete>
