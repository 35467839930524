import { Injectable, NgZone } from '@angular/core';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FormLoaderService } from './form-loader.service';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  stateSubscription?: Subscription = undefined;
  updateField$: Subject<any> = new ReplaySubject();
  public machineConfig: any;
  public formLoaderService: FormLoaderService;
  constructor(formLoaderService: FormLoaderService, private zone: NgZone) {
    this.formLoaderService = formLoaderService;
  }

  async getForm(formId: string) {
    return await this.getMachineFromDB(formId);
  }

  parseFormDefinitionFeatures(formDef: any) {
    const { fields } = formDef;
    for (const field of fields) {
      for (const fieldGroup of field.fieldGroup) {
        console.log(fieldGroup);
        fieldGroup.hooks = {
          onInit: (field: FormlyFieldConfig) => {
            this.updateField$
              .pipe(filter((fieldInfo: any) => fieldInfo.fieldKey === field.key))
              .subscribe((fieldInfo: any) => {
                this.zone.run(() => {
                  if (field.templateOptions) {
                    field.templateOptions.data = fieldInfo.data;
                    switch (fieldInfo.fieldType) {
                      case 'button':
                        field.templateOptions.label = fieldInfo.value;
                        break;
                      case 'input':
                        break;
                      case 'select':
                        break;
                      case 'checkbox':
                        break;
                      case 'radio':
                        break;
                    }
                  }
                });

                console.log(fieldInfo, field);
              });
          },
        };
      }
    }
  }

  isEmpty = (object: any) => Object.keys(object).length === 0;

  private async getMachineFromDB(formId: string): Promise<any> {
    let formDef = await this.formLoaderService.load(formId);

    if (!formDef.length) throw new Error('Not found');
    formDef = formDef.pop();
    return formDef;
  }
}
