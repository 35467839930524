<mat-card>
  <div class="card-header">
    <mat-icon class="card-icon" *ngIf="data?.cardContent?.leftIcon && data?.cardContent?.leftIcon?.type === 'material'">
      {{ data?.cardContent?.leftIcon?.icon }}
    </mat-icon>
    <mat-icon
      class="card-icon"
      [svgIcon]="data?.cardContent?.leftIcon?.icon"
      *ngIf="data?.cardContent?.leftIcon && data?.cardContent?.leftIcon?.type === 'svg'"
    >
    </mat-icon>
    <img
      class="card-icon"
      *ngIf="data?.cardContent?.leftIcon && data?.cardContent?.leftIcon?.type === 'custom'"
      [src]="data.cardContent.leftIcon.icon"
      [alt]="data.cardContent.leftIcon.icon"
    />

    <div class="card-content" (click)="cardClicked()">
      <div *ngIf="data?.cardContent?.title" class="card-key">
        <span *ngIf="data?.cardContent?.title?.key">{{ data?.cardContent?.title?.key | titlecase }}:</span>
        <span class="word-break">{{ data?.cardContent?.title?.value }}</span>
      </div>

      <ng-container *ngIf="data?.cardContent?.subHeader && data.cardContent?.subHeader?.length">
        <div *ngFor="let item of data.cardContent.subHeader">
          <ng-container *ngIf="item?.type === 'date' && !showProperties">
            <span class="margin-right-1" *ngIf="item?.key">{{ item?.key | titlecase }}:</span>
            <span class="word-break margin-right-1">{{ item.value | date: 'medium' }}</span>
          </ng-container>
          <ng-container *ngIf="item?.type !== 'date'">
            <span class="margin-right-1" *ngIf="item?.key">{{ item?.key | titlecase }}:</span>
            <span class="word-break margin-right-1">{{ item.value }}</span>
          </ng-container>
        </div>
      </ng-container>

      <div *ngIf="!hideTags && data?.cardContent?.tagList && data?.cardContent?.tagList.length" class="margin-top-3">
        <ng-container *ngFor="let tag of data?.cardContent?.tagList">
          <cmx-card-widget-chips
            *ngIf="tag && (tag.label || tag.icon) && tag.count"
            [showChipsLabel]="tag?.showChipsLabel"
            [label]="tag.label"
            [count]="tag.count"
            [showCount]="showBadgeCount"
            [icon]="tag.icon"
            [iconType]="tag.iconType"
            [badgeColor]="tag.badgeColor"
            [isFormlyField]="false"
            (click)="chipClicked(tag)"
          ></cmx-card-widget-chips>
        </ng-container>
      </div>
    </div>

    <button
      mat-fab
      class="button-icon"
      type="button"
      *ngIf="data?.cardContent?.rightIcon && data?.cardContent?.rightIcon?.type"
      color="primary"
      (click)="rightIconClick($event)"
    >
      <mat-icon *ngIf="data?.cardContent?.rightIcon?.type === 'material'">
        {{ data?.cardContent?.rightIcon?.icon }}
      </mat-icon>
      <mat-icon [svgIcon]="data?.cardContent?.rightIcon?.icon" *ngIf="data?.cardContent?.rightIcon?.type === 'svg'">
      </mat-icon>
    </button>
  </div>

  <mat-list role="list" *ngIf="showProperties">
    <mat-divider></mat-divider>
    <mat-list-item mat-line *ngFor="let item of data?.cardContent?.properties">
      <mat-divider></mat-divider>
      <div class="margin-right-1" *ngIf="item?.key">{{ item?.key | titlecase }}:</div>
      <div>{{ item?.type === 'date' ? (item?.value | date: 'medium') : item?.value }}</div>
    </mat-list-item>

    <ng-container *ngFor="let child of data.cardContent.shipmentsList">
      <cmx-card-widget
        [enableCollapse]="false"
        [isFormlyField]="false"
        *ngIf="child"
        [isFormlyField]="false"
        [data]="child"
      ></cmx-card-widget>
    </ng-container>

    <mat-list-item *ngIf="data?.cardContent?.assignees" class="assignee" mat-line>
      <mat-icon mat-list-icon>people</mat-icon>
      <div>{{ 'Assignees' | titlecase }}:</div>
      <mat-chip-list *ngIf="data?.cardContent?.assignees && data?.cardContent?.assignees.length">
        <mat-chip *ngFor="let assignee of data?.cardContent?.assignees">
          <mat-icon mat-list-icon *ngIf="assignee?.picture && assignee?.iconType === 'material'">{{
            assignee?.picture
          }}</mat-icon>
          <mat-icon
            mat-list-icon
            *ngIf="assignee?.picture && assignee?.iconType === 'svg'"
            [svgIcon]="assignee?.picture"
          ></mat-icon>
          <img
            *ngIf="assignee?.picture && !assignee?.iconType"
            width="28"
            [src]="assignee?.picture"
            alt="icon for {{ assignee?.picture }}"
          />
          {{ assignee.name }}
        </mat-chip>
      </mat-chip-list>
    </mat-list-item>
  </mat-list>
</mat-card>
