export function getDeviceType(): string {
  const ua = navigator.userAgent;
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
    return 'mobile';
  } else {
    return 'desktop';
  }
}

export function isDesktopDevice(): boolean {
  return getDeviceType() == 'desktop';
}

export function isIphoneDevice(): boolean {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

declare let navigator;
export async function getBatteryStatus(): Promise<BatteryStatus | undefined> {
  if (navigator?.getBattery) {
    const batteryStatusInformation = await navigator.getBattery();
    const batteryStatus: BatteryStatus = {
      charging: batteryStatusInformation?.charging,
      chargingTime: batteryStatusInformation?.chargingTime,
      dischargingTime: batteryStatusInformation?.dischargingTime,
      level: batteryStatusInformation?.level * 100,
    };
    return batteryStatus;
  } else {
    return;
  }
}

export function isOnline(): boolean {
  return navigator.onLine;
}

interface BatteryStatus {
  charging: boolean;
  chargingTime: number;
  dischargingTime: number;
  level: number;
}
