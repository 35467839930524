import { Component, Input, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-card-widget-chips',
  templateUrl: './card-widget-chips.component.html',
  styleUrls: ['./card-widget-chips.component.scss'],
})
export class CardWidgetChipsComponent extends FieldType implements OnInit {
  @Input() label = 'Chip';
  @Input() icon = '';
  @Input() iconType: 'custom' | 'svg' | 'material' = 'custom';
  @Input() color: 'primary' | 'warn' | 'accent' | 'success';
  @Input() badgeColor: 'primary' | 'warn' | 'accent';
  @Input() count: number | string = 0;
  @Input() showChipsLabel = false;
  @Input() labelOnly = false;
  @Input() showCount = true;
  @Input() isFormlyField = true;

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.label = this.to.label || this.label;
      this.icon = this.to.icon || this.icon;
      this.iconType = this.to.iconType || this.iconType;
      this.color = this.to.color || this.color;
      this.badgeColor = this.to.badgeColor || this.badgeColor;
      this.labelOnly = this.to.labelOnly || this.labelOnly;
      this.count = this.to.count || this.count;
      this.showChipsLabel = this.to.showChipsLabel || this.showChipsLabel;
    }
  }
}
