<div class="text-center">
  <button type="button" *ngIf="iconMode === 'mat-icon' && icon" [color]="color" mat-icon-button (click)="iconClicked()">
    <mat-icon>
      {{ icon }}
    </mat-icon>
  </button>
  <button
    *ngIf="iconMode === 'mat-icon' && svgIcon"
    type="button"
    [color]="color"
    mat-icon-button
    (click)="iconClicked()"
    class="svg-icon-btn"
  >
    <mat-icon [svgIcon]="svgIcon"> </mat-icon>
  </button>
  <img *ngIf="iconMode === 'image-icon' && iconSrc" [src]="iconSrc" [alt]="iconSrc" (click)="iconClicked()" />
</div>
