<div class="header" *ngIf="canAdd()">
  <button mat-icon-button type="button" (click)="add()">
    <mat-icon>add</mat-icon>
  </button>
</div>
<div class="body" [ngClass]="{ interactive: canAdd() }">
  <div
    class="section flex-container"
    *ngFor="let field of field.fieldGroup; let i = index"
    [ngClass]="{ interactive: canRemove(i) }"
  >
    <formly-group
      [model]="model[i]"
      [field]="field"
      [options]="options"
      [form]="formControl"
      [ngClass]="fieldArrayClassName"
    >
    </formly-group>
    <button mat-icon-button type="button" (click)="remove(i)" color="warn" *ngIf="canRemove(i)" class="remove-button">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>
