<div class="container">
  <div class="input-container">
    <section *ngIf="supported">
      <button mat-fab class="mic" *ngIf="listening$ | async; else mic" (click)="stop()">
        <mat-icon class="soundwave">mic</mat-icon>
      </button>
      <ng-template #mic>
        <button mat-fab title="Voice Recognition" class="mic" [disabled]="inputFocused" mat-fab (click)="start()">
          <mat-icon>mic</mat-icon>
        </button>
      </ng-template>
    </section>
    <input
      #commentInput
      [class.reply]="commentInput.value && replyTo"
      [value]="
        speechRecognizer.isListening
          ? (replyTo ? replyTo : '') + (transcript$ | async) || ''
          : (replyTo ? replyTo : '') + (totalTranscript || '')
      "
      (keydown.enter)="onEnterPressed($event)"
      [placeholder]="placeholderText"
      (focus)="inputFocused = true"
      (blur)="inputFocused = false"
    />
    <div class="actions-container">
      <cmx-audio-recorder
        #recorder
        (filesChanged)="recorderFilesChanged($event)"
        [identifier]="comment?.id"
      ></cmx-audio-recorder>
      <cmx-uploader (changed)="uploaderChanged($event)" #uploader [multiple]="true"></cmx-uploader>
    </div>
  </div>
  <div class="send-container">
    <button mat-fab class="send" (click)="sendComment(commentInput.value)">
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>

<cmx-uploader-view #viewer [files]="files" (fileRemoved)="fileRemoved($event)"></cmx-uploader-view>
