import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UiEventRaised, WorkflowConfiguration } from '@cmx/shared/feature/platform-configuration';
import { copyObject } from '@cmx/shared/util/helper-functions';
import { FormlyFieldConfig } from '@ngx-formly/core';
import jsonata from 'jsonata';
import { Subscription } from 'rxjs';
import { isArray } from 'util';
import { FirestoreQuery } from '@cmx/shared/feature/firestore';

@Component({
  selector: 'cmx-form-generator',
  templateUrl: './form-generator.component.html',
  styleUrls: ['./form-generator.component.scss'],
})
export class FormGeneratorComponent implements OnInit, OnDestroy {
  @Input() formTitle: string;
  @Input() allowReset = false;
  @Input() clearButtonColor: string;
  @Input() clearButtonText = 'Clear';
  @Input() allowSubmit: boolean;
  @Input() submitButtonColor = 'primary';
  @Input() submitButtonText = 'Submit';
  @Input() workflowConfiguration: WorkflowConfiguration = {
    dataSource: {},
    fields: [],
    model: {},
  };

  @Output() formSubmit: EventEmitter<any> = new EventEmitter();

  formFieldList: FormlyFieldConfig[];
  customForm: FormGroup = new FormGroup({});
  options: unknown;
  @Output() formEvent: EventEmitter<UiEventRaised> = new EventEmitter();
  @Output() modelChangeEvent: EventEmitter<UiEventRaised> = new EventEmitter();

  replicationSubscription: Subscription;
  scannerSubscription: Subscription;
  controlSubscription: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.options = {
      formState: {
        mainModel: this.workflowConfiguration.model,
        disabled: true,
      },
    };

    this.initializeFormFields();
    // this.controlSubscription = this.stateMachinesService.showHideControl.subscribe(type => {
    //   this.showHideControl(type);
    // });
  }

  async initializeFormFields(): Promise<void> {
    if (this.workflowConfiguration.fields) {
      this.formFieldList = await Promise.all(
        this.workflowConfiguration.fields.map(async formField => {
          if (this.workflowConfiguration.dataSource) {
            formField = await this.mapTemplateOptions(formField);
          }
          return formField;
        }),
      );
    }
  }

  async mapTemplateOptions(formField) {
    if (formField.fieldGroup) {
      for (let innerField of formField.fieldGroup) {
        innerField = await this.mapTemplateOptions(innerField);
      }
    } else if (formField.templateOptions) {
      formField.templateOptions.getFieldEvent = $event => this.getFormEvent($event);
      if (formField.templateOptions.changeEvent) {
        formField.templateOptions.change = (field, $event) => this.modelChange(field, $event);
      }
      if (formField.type == 'input') {
        formField.templateOptions.keydown = (field, event) => {
          console.log(event, field.templateOptions.restrictKeys);
          if (field.templateOptions?.restrictKeys && field.templateOptions.restrictKeys.includes(event.key)) {
            event.preventDefault();
          }
        };
      }
      const extraData = await this.getDataSource(formField.key as string);
      if (extraData) {
        for (const dataPart of extraData) {
          formField.templateOptions[dataPart.key] = dataPart.value;
        }
      }
    } else if (formField.fieldArray && formField.type == 'list') {
      formField.fieldArray.templateOptions.getFieldEvent = $event => this.getFormEvent($event);
      if (formField.fieldArray.templateOptions.change) {
        formField.fieldArray.templateOptions.change = (field, $event) => this.modelChange(field, $event);
      }
      const extraData = await this.getDataSource(formField.key as string);
      if (extraData) {
        for (const dataPart of extraData) {
          formField.defaultValue = dataPart.value;
          this.workflowConfiguration.model[formField.key as string] = dataPart.value;
        }
      }
    }
    return formField;
  }

  getFormEvent(formEvent: UiEventRaised): void {
    formEvent.data =
      formEvent.data && Object.keys(formEvent.data).length ? formEvent.data : this.workflowConfiguration.model;
    this.formEvent.emit(formEvent);
  }

  modelChange(field, event) {
    console.log(field.templateOptions.changeEvent, ' => ', event);
    const xstateEvent = {
      eventName: field.templateOptions.changeEvent,
      data: event.value,
      field: field,
    };
    this.modelChangeEvent.emit(xstateEvent);
  }

  async getDataSource(fieldKey: string): Promise<{ key: string; value: unknown }[]> {
    if (this.workflowConfiguration.dataSource[fieldKey]) {
      const data: { key: string; value: unknown }[] = [];
      const dataSourceProperties = copyObject(this.workflowConfiguration.dataSource[fieldKey]);
      const properties = Object.entries(dataSourceProperties);
      for (const [pKey, pValue] of properties) {
        const propertyKey = pKey as string;
        const propertyValue = pValue as any;

        switch (propertyValue.dataType) {
          case 'context': {
            const path = propertyValue.path ? propertyValue.path : fieldKey;
            let value = propertyValue.path
              ? jsonata(path).evaluate(this.workflowConfiguration.model)
              : (this.workflowConfiguration.model as any)[path];

            if (propertyValue?.template && value) {
              if (Array.isArray(value)) {
                value = value.map(item => this.mapDataWithTemplate(item, propertyValue.template, item));
              } else {
                value = this.mapDataWithTemplate(value, propertyValue.template, value);
              }
            }

            data.push({
              key: propertyKey,
              value: copyObject(value),
            });

            break;
          }
          default:
            break;
        }
      }

      return data;
    }
  }

  onSubmit(formToSubmit: FormGroup): void {
    const { value } = formToSubmit;
    console.log(value);
    if (formToSubmit.valid) {
      this.formSubmit.emit(value);
    }
  }

  onResetForm(): void {
    setTimeout(() => {
      console.log('Model: ', this.workflowConfiguration.model);
      this.formSubmit.emit(null);
    }, 100);
  }

  // TODO: use ids
  showHideControl(controlType) {
    this.workflowConfiguration.fields?.forEach((f, index) => {
      f.fieldGroup.forEach((control, cIndex) => {
        if (control.type == controlType) {
          this.workflowConfiguration.fields[index].fieldGroup[cIndex].hideExpression =
            !this.workflowConfiguration.fields[index].fieldGroup[cIndex].hideExpression;
        }
      });
    });
  }

  disableControls(controlType) {
    this.workflowConfiguration.fields.forEach((f, index) => {
      f.fieldGroup.forEach((control, cIndex) => {
        if (control.type == controlType) {
          if (!control.expressionProperties) {
            control.expressionProperties = {
              'templateOptions.disabled': 'false',
            };
          }
          control.expressionProperties['templateOptions.disabled'] = 'formState.disabled';
        }
      });
    });
  }

  mapDataWithTemplate(result: unknown, template: unknown, data: unknown) {
    for (const [key, value] of Object.entries(template)) {
      if (Array.isArray(value)) {
        result[key] = [];
        value.forEach(v => {
          if (v.type && v.type == 'array' && v.key) {
            if (data[v.key])
              data[v.key].forEach(childData => {
                const nestedTemplate = this.mapDataWithTemplate({}, v.value, childData);
                result[key].push(nestedTemplate);
              });
          } else {
            const nestedTemplate = this.mapDataWithTemplate({}, v, data);
            result[key].push(nestedTemplate);
          }
        });
      } else if (value) {
        if (value === null || value === undefined) {
          result[key] = null;
        } else if (typeof value === 'boolean' || typeof value === 'number') {
          result[key] = value;
        } else if (typeof value === 'object') {
          result[key] = this.mapDataWithTemplate({}, value, data);
        } else {
          result[key] = jsonata(value).evaluate(data) === undefined ? null : jsonata(value).evaluate(data);
        }
      } else if (value === null || value === undefined) {
        result[key] = {};
      }
    }
    return result;
  }

  formatToFirestoreQuery(query): FirestoreQuery[] {
    const firestoreQuery: FirestoreQuery[] = [];
    query.forEach(q => {
      if (q.key === 'collectionName') {
        firestoreQuery.push({
          property: 'collectionName',
          operator: '==',
          value: q.value,
        });
      } else {
        if (isArray(q.value)) {
          if (q.value.length) {
            firestoreQuery.push({
              property: q.key,
              operator: 'in',
              value: q.value,
            });
          }
        } else {
          if (q.value) {
            firestoreQuery.push({
              property: q.key,
              operator: '==',
              value: q.value,
            });
          }
        }
      }
    });

    return firestoreQuery;
  }

  unsubscribeFromEvents() {
    if (this.scannerSubscription) {
      this.scannerSubscription.unsubscribe();
    }

    if (this.replicationSubscription) {
      this.replicationSubscription.unsubscribe();
    }

    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribeFromEvents();
    this.formFieldList = null;
  }
}
