import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-formly-list',
  templateUrl: './formly-list.component.html',
  styleUrls: ['./formly-list.component.scss'],
})
export class FormlyListComponent extends FieldArrayType implements OnInit {
  //don't remove this
  defaultOptions = {
    defaultValue: {},
  };
  //******/
  ngOnInit() {
    console.log('formly-list');
  }
}
