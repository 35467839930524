<cmx-sidenav #nav>
  <div class="project">
    <cmx-header
      *ngIf="headerConfiguration?.visible"
      class="site-header"
      [headerConfiguration]="headerConfiguration"
      [isFormlyField]="false"
      (raiseHeaderEvent)="
        $event.eventName == headerConfiguration?.leftIcon?.event?.eventName ? nav.toggle() : handleEvent($event)
      "
    ></cmx-header>
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</cmx-sidenav>
