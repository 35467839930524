import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
})
export class CounterComponent extends FieldType implements OnInit {
  @Output() raiseEvent = new EventEmitter<UiEventRaised>();
  @Input() minCount: any = 0;
  @Input() itemCount: any = 0;
  @Input() maxCount: any = 2147483647;
  @Input() decrementLabel = '';
  @Input() incrementLabel = '';
  @Input() buttonColor = '';
  @Input() isFormlyField = true;
  @Input() label = '';
  @Input() restrictKeys: any[] = [];

  ngOnInit(): void {
    if (!this.itemCount) this.itemCount = 0;
    if (this.isFormlyField) {
      this.itemCount = this.to.itemCount || this.itemCount;
      this.maxCount = this.to.maxCount || this.maxCount;
      this.minCount = this.to.minCount || this.minCount;
      this.buttonColor = this.to.buttonColor || this.buttonColor;
      this.incrementLabel = this.to.incrementLabel || this.incrementLabel;
      this.decrementLabel = this.to.decrementLabel || this.decrementLabel;
      this.label = this.to.label || this.label;
      this.restrictKeys = this.to.restrictKeys || this.restrictKeys;
    }
  }

  keypressEvent(event: any) {
    if (this.restrictKeys && this.restrictKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  raiseCounterEvent(CounterEvent: UiEventRaised): void {
    if(CounterEvent.data == null || CounterEvent.data == undefined || CounterEvent.data == ''){
      CounterEvent.data = 0;
    }
    if (this.isFormlyField) {
      this.field.formControl.setValue(CounterEvent.data);
      console.log(this.field);
      this.to.getFieldEvent(CounterEvent);
    } else {
      this.raiseEvent.emit(CounterEvent);
    }
  }

  updateCounter() {
    const eventToRaise: UiEventRaised = {
      eventName: 'UPDATE_COUNTER',
      data: this.itemCount,
      isNavigation: false,
    };
    this.raiseCounterEvent(eventToRaise);
  }

  counterIncrement(): void {
    const eventToRaise: UiEventRaised = {
      eventName: 'COUNTER_INCREMENT',
      data: ++this.itemCount,
      isNavigation: false,
    };
    this.raiseCounterEvent(eventToRaise);
  }

  counterDecrement(): void {
    const eventToRaise: UiEventRaised = {
      eventName: 'COUNTER_DECREMENT',
      data: --this.itemCount,
      isNavigation: false,
    };
    this.raiseCounterEvent(eventToRaise);
  }
}
