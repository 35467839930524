<mat-card class="panel">
  <mat-card-header style="width: 100%">
    <div class="row align-items-center" style="width: 100%; margin: 0">
      <div class="col-1 align-items-center heading-color">
        <img *ngIf="to?.icon" [src]="to.icon" alt="to.label" class="header-logo" />
        <mat-card-title title="{{ to.label }}"> {{ to.label }}</mat-card-title>
      </div>
      <div class="justify-content-flexend align-items-center">
        <button
          title="Share"
          type="button"
          mat-icon-button
          *ngIf="to?.enableSharing"
          (click)="share()"
          name="share-social-outline"
        >
          <mat-icon>share</mat-icon>
        </button>
        <button
          title="Expand/Collapse"
          type="button"
          mat-icon-button
          *ngIf="to?.enableMaximize"
          (click)="maximizeMinimize()"
          name="trash"
        >
          <mat-icon>{{ maximized ? 'close_fullscreen' : 'open_in_full' }}</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="panel-content-wrapper">
    <ng-container #fieldComponent></ng-container>
  </mat-card-content>
</mat-card>
