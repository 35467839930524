<h1 mat-dialog-title>
  {{ data.title }}
</h1>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]="'cancel'">Cancel</button>
  <button mat-button color="primary" [mat-dialog-close]="'confirm'" cdkFocusInitial>
    {{ data.actionText }}
  </button>
</div>
