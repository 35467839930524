<mat-form-field [appearance]="appearance">
  <mat-label>{{ label || 'Select Box' }}</mat-label>
  <mat-select [formControl]="control" [multiple]="multiple" *ngIf="isFormlyField">
    <mat-option #allSelected *ngIf="multiple" (click)="toggleSelectAll()" value="selectAll"> Select All</mat-option>
    <mat-option *ngFor="let option of data" [value]="option" (click)="checkUncheck()">{{
      typeOf(option) === 'object' ? option[displayName] : option
    }}</mat-option>
  </mat-select>

  <mat-select
    [multiple]="multiple"
    [(ngModel)]="selectedValue"
    (selectionChange)="raiseSelectBoxEvent($event.value)"
    *ngIf="!isFormlyField"
  >
    <mat-option #allSelected *ngIf="multiple" (click)="toggleSelectAll()" value="selectAll"> Select All</mat-option>
    <mat-option *ngFor="let option of data" [value]="option" (click)="checkUncheck()">{{
      typeOf(option) === 'object' ? option[displayName] : option
    }}</mat-option>
  </mat-select>
</mat-form-field>
