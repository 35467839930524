export function removeDuplicatesFromArray(arrayWithDuplicates: any[], idProperty: string): any[] {
  if (!Array.isArray(arrayWithDuplicates)) {
    throw `Parameter sent is not an array`;
  }

  if (!arrayWithDuplicates[0][idProperty]) {
    throw `Property ${idProperty} doesn't exist in the objects inside the array`;
  }

  const uniqueTemporalObject: any = {};
  const newUniqueArray: any[] = [];

  for (const i in arrayWithDuplicates) {
    uniqueTemporalObject[arrayWithDuplicates[i][idProperty]] = arrayWithDuplicates[i];
  }

  for (const j in uniqueTemporalObject) {
    newUniqueArray.push(uniqueTemporalObject[j]);
  }

  return newUniqueArray;
}

export function copyObject(sourceObject: unknown): any {
  if (Array.isArray(sourceObject)) {
    return [...sourceObject];
  }

  if (sourceObject && !(sourceObject instanceof Date) && typeof sourceObject === 'object') {
    return { ...sourceObject };
  } else {
    return sourceObject;
  }
}

export function sortArray(array: any[], key = '') {
  return array.sort((a, b) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  });
}
