import { Injectable } from '@angular/core';
import { getItemFromLocalStorage } from '@cmx/shared/data-access/local-storage';
import { Observable, of } from 'rxjs';
import { FirestoreQuery, FirestoreService, FirestoreSort } from '@cmx/shared/feature/firestore';
import { SyncStatus } from '@cmx/shared/util/interfaces';

@Injectable({
  providedIn: 'root',
})
export class VideoStatusService {
  public currentWorkflow: string | null = null;

  constructor(private readonly firestoreService: FirestoreService) {}

  getInProgressVideoList(): Observable<any[]> {
    const currentUser: any = getItemFromLocalStorage('loggedInUser');
    if (!currentUser) {
      return of([]);
    }
    const userId: string = currentUser.id;
    const queryToPerform: FirestoreQuery[] = [
      {
        property: 'user',
        operator: '==',
        value: userId,
      },
      {
        property: 'collectionName',
        operator: '==',
        value: 'assets',
      },
      {
        property: 'type',
        operator: '==',
        value: 'video',
      },
      {
        property: 'syncStatus',
        operator: '==',
        value: SyncStatus.default,
      },
    ];

    const sortBy: FirestoreSort = {
      property: 'timestamp',
      order: 'desc',
    };
    return this.firestoreService.getRealTimeUpdatesFromQuery(queryToPerform, undefined, sortBy);
  }

  getCompletedVideoList(): Observable<any[]> {
    const currentUser: any = getItemFromLocalStorage('loggedInUser');
    if (!currentUser) {
      return of([]);
    }
    const userId: string = currentUser.id;
    const last24Hours: number = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).getTime();
    const queryToPerform: FirestoreQuery[] = [
      {
        property: 'user',
        operator: '==',
        value: userId,
      },
      {
        property: 'collectionName',
        operator: '==',
        value: 'assets',
      },
      {
        property: 'type',
        operator: '==',
        value: 'video',
      },
      {
        property: 'syncStatus',
        operator: '==',
        value: SyncStatus.synced,
      },
      {
        property: 'completedTime',
        operator: '>',
        value: last24Hours,
      },
    ];

    const sortBy: FirestoreSort = {
      property: 'completedTime',
      order: 'desc',
    };
    return this.firestoreService.getRealTimeUpdatesFromQuery(queryToPerform, undefined, sortBy);
  }
}
