<div class="card-container" *ngFor="let data of cardDataList; let i = index">
  <cmx-card
    [showLeftIcon]="showLeftIcon"
    [data]="data"
    [showRightIcon]="showRightIcon"
    [showBadge]="showBadge"
    [isFormlyField]="false"
    [dataIndex]="i"
    [clickEventName]="clickEventName"
    (raiseCardEvent)="cardListEvent($event)"
    [cardTemplateId]="to.cardTemplateId"
    [cardTemplate]="to.template"
  ></cmx-card>
  <ng-container *ngIf="data?.children && enableChildList">
    <cmx-card
      class="children"
      *ngFor="let item of data.children"
      [showLeftIcon]="true"
      [data]="item"
      [showCounter]="true"
      [isFormlyField]="false"
      [cardTemplateId]="to.cardTemplateId"
      [cardTemplateId]="to.template"
    ></cmx-card>
  </ng-container>
</div>
<div class="card-container text-center" *ngIf="!cardDataList.length">No Data</div>
