<ngx-charts-advanced-pie-chart
  *ngIf="data.length"
  [view]="view"
  [scheme]="colorScheme"
  [results]="data"
  [gradient]="enableGradient"
  [animations]="enableAnimations"
  [label]="label"
  [tooltipDisabled]="tooltipDisabled"
  (select)="onSelect($event)"
>
</ngx-charts-advanced-pie-chart>
