import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppVersionLoaderComponent } from './app-version-loader.component';
@Injectable({ providedIn: 'root' })
export class AppVersionLoaderService {
  constructor(public dialog: MatDialog) {}

  showLoader(): MatDialogRef<AppVersionLoaderComponent, any> {
    return this.dialog.open(AppVersionLoaderComponent, {
      minWidth: '100vw',
      height: '100vh',
      hasBackdrop: false,
      disableClose: true,
    });
  }
}
