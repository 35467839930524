import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';

import videojs, { VideoJsPlayerOptions } from 'video.js';
import 'webrtc-adapter';
import * as RecordRTC from 'recordrtc';
import * as Record from 'videojs-record/';
import { FieldType } from '@ngx-formly/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { isIphoneDevice } from '@cmx/shared/util/helper-functions';
import { VideoService } from './video.service';
import { loadingButton, resumeButton, stopButton } from './video.timer-buttons';
@Component({
  selector: 'cmx-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent extends FieldType implements OnInit, AfterViewInit, OnDestroy {
  idx = 'clip1';
  @Input() videoComponentState = 'record';
  @Input() videoUrl: string;
  @Input() isFormlyField = true;
  @Input() showPlaybackRates = true;
  @Input() videoDevice: string;
  @Input() showDevicePicker = false;
  @Input() autoStartRecorder = false;
  @Input() config: VideoJsPlayerOptions = {
    controls: true,
    autoplay: false,
    fluid: false,
    loop: false,
    width: 320,
    height: 240,
    bigPlayButton: false,
    controlBar: {
      volumePanel: false,
    },
    plugins: {
      record: {
        audio: false,
        video: true,
        debug: true,
      },
    },
  };

  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  player: any;
  private plugin = Record;
  private deviceIsReady = false;
  private isDeviceAlreadyPreselected = false;
  uploadUrl = '';
  devices: any = [];

  currentButton = { ...loadingButton };

  countUp = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  timer: number;
  timeInterval = 1000;

  constructor(private videoService: VideoService) {
    super();
  }

  ngOnInit() {
    this.plugin = Record;
    this.config.width = this.isFormlyField ? this.to.width : this.config.width;
    this.config.height = this.isFormlyField ? this.to.height : this.config.height;
    this.config.autoplay = this.isFormlyField ? this.to.autoplay : this.config.autoplay;
    this.videoDevice = this.isFormlyField ? this.to.videoDevice : this.videoDevice;
    this.autoStartRecorder = this.isFormlyField ? this.to.autoStartRecorder : this.autoStartRecorder;
    this.showDevicePicker = this.isFormlyField ? this.to.showDevicePicker : this.showDevicePicker;
    this.showPlaybackRates = this.isFormlyField ? this.to.showPlaybackRates : this.showPlaybackRates;
    this.videoComponentState = this.isFormlyField ? this.to.videoComponentState : this.videoComponentState;

    if (this.videoComponentState === 'player') {
      this.videoUrl = this.to.url;
      if (this.videoUrl) {
        if (this.showPlaybackRates) {
          this.config.playbackRates = [1, 5, 10, 15];
        }
        delete this.config.plugins;
        this.config.bigPlayButton = true;
        this.config.sources = [{ src: this.videoUrl, type: 'video/mp4' }];
      }
    }

    if (this.videoComponentState === 'record' || this.videoComponentState === 'minirecorder') {
      this.videoService.toggleVideoRecorder$.subscribe(res => {
        if (res === 'VIDEO_RECORDER_STOP') {
          if (this.player) {
            this.stopRecording();
          }
        } else if (res == 'VIDEO_RECORDER_PAUSE') {
          if (this.player) {
            this.stopTimer();
          }
        } else if (res == 'VIDEO_RECORDER_PLAY') {
          if (this.player) {
            this.startTimer();
          }
        }
      });
    }

    if (this.videoComponentState === 'minirecorder') {
      this.config = {
        controls: false,
        autoplay: false,
        fluid: false,
        loop: false,
        width: 320,
        height: 60,
        bigPlayButton: false,
        controlBar: {
          volumePanel: false,
        },
        plugins: {
          record: {
            maxLength: 60 * 60,
            audio: true,
            video: true,
            debug: false,
          },
        },
      };
    }
  }

  async ngAfterViewInit() {
    const el = 'video_' + this.idx;

    try {
      await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
    } catch (error) {
      if (this.config && this.config.plugins && this.config.plugins.record) {
        this.config.plugins.record.audio = false;
      }
    }

    this.player = videojs(document.getElementById(el), this.config, async () => {
      if (this.player && this.videoComponentState !== 'player') {
        this.player.record().getDevice();
      }
    });

    this.player.on('deviceReady', () => {
      this.player.record().enumerateDevices();
    });

    this.player.on('enumerateReady', () => {
      this.devices = this.player.record().devices.filter(device => device.kind == 'videoinput');
      if (!this.isDeviceAlreadyPreselected) {
        this.setPreselectedDevice();
      }

      if (this.autoStartRecorder) {
        this.startTimer();
      }
    });

    this.player.on('startRecord', () => {
      // this.raiseVideoEvent('START_RECORDING', '', false);
    });

    this.player.on('finishRecord', () => {
      if (this.videoComponentState === 'minirecorder' && !isIphoneDevice()) {
        let videoName: string = new Date().toISOString();
        videoName = videoName.split('.')[0].replace(/-/g, '').replace(/:/g, '');
        this.player.record().saveAs({ video: `${this.to.videoName ? this.to.videoName : videoName}.mp4` });
      }
      this.raiseVideoEvent('VIDEO_RECORDER_FINISHED', this.player.recordedData, false);
    });

    this.player.on('error', (element: any, error: any) => {
      console.warn(error);
    });

    this.player.on('deviceError', () => {
      console.dir('device error:', this.player.deviceErrorCode);
    });

    this.player.on('ended', () => {
      this.raiseVideoEvent('VIDEO_PLAYER_ENDED', this.player.recordedData, false);
    });

    this.player.on('play', () => {
      this.raiseVideoEvent('VIDEO_PLAYER_PLAY', {}, false);
    });

    /* if (this.videoComponentState === 'minirecorder') {
      this.resetTimer();
    } */
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
      this.player = false;
    }
  }

  setPreselectedDevice(): void {
    const deviceIdList: string[] = this.devices.map(device => device.deviceId);
    if (this.videoDevice && deviceIdList.includes(this.videoDevice)) {
      this.changeVideoInput(this.videoDevice);
    } else {
      this.changeVideoInput(deviceIdList[0]);
    }
    this.isDeviceAlreadyPreselected = true;
  }

  stopDevice() {
    this.player.record().stopDevice();
  }

  startRecording() {
    try {
      this.player?.record()?.start();
      this.timer = window.setInterval(() => {
        this.updateCount();
      }, this.timeInterval);
    } catch (error) {
      console.warn(error);
    }
  }

  stopRecording() {
    try {
      this.player.record().stop();
    } catch (error) {
      console.warn(error);
    }
  }

  pauseRecording() {
    try {
      this.player.record().pause();
    } catch (error) {
      console.warn(error);
    }
  }

  resumeRecording() {
    try {
      this.player.record().resume();
    } catch (error) {
      console.warn(error);
    }
  }

  raiseVideoEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseEvent.emit(eventToRaise);
    }
  }

  changeVideoInput(deviceId: string): void {
    try {
      this.videoDevice = deviceId;
      this.player.record().setVideoInput(this.videoDevice);
      if (this.showDevicePicker) {
        this.raiseVideoEvent('VIDEO_DEVICE_CHANGED', this.videoDevice, false);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  get timerData() {
    return (
      `${this.countUp.hours < 10 ? '0' + this.countUp.hours : this.countUp.hours}` +
      ':' +
      `${this.countUp.minutes < 10 ? '0' + this.countUp.minutes : this.countUp.minutes}` +
      ':' +
      `${this.countUp.seconds < 10 ? '0' + this.countUp.seconds : this.countUp.seconds}`
    );
  }

  updateCount() {
    this.countUp.seconds++;
    if (this.countUp.seconds == 60) {
      this.countUp.minutes++;
      this.countUp.seconds = 0;
    }
    if (this.countUp.minutes == 60) {
      this.countUp.hours++;
      this.countUp.minutes = 0;
      this.countUp.seconds = 0;
    }
  }

  resetTimer() {
    clearInterval(this.timer);
    this.countUp = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  toggleTimer(event: string): void {
    switch (event) {
      case 'start':
        this.startTimer();
        break;
      case 'resume':
        this.resumeTimer();
        break;
      case 'pause':
        this.stopTimer();
        break;
      case 'none':
        break;
    }
  }

  startTimer() {
    this.currentButton = { ...stopButton };
    this.resetTimer();
    clearInterval(this.timer);
    this.startRecording();
  }

  resumeTimer() {
    this.currentButton = { ...stopButton };
    this.resumeRecording();
    clearInterval(this.timer);
    this.timer = window.setInterval(() => {
      this.updateCount();
    }, this.timeInterval);
  }

  stopTimer() {
    this.currentButton = { ...resumeButton };
    this.pauseRecording();
    clearInterval(this.timer);
  }

  restartTimer() {
    this.resetTimer();
    this.timer = window.setInterval(() => {
      this.updateCount();
    }, this.timeInterval);
  }
}
