import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { Dimensions, SeriesData } from '../charts.model';
import { FieldType } from '@ngx-formly/core';
import { seriesData } from '../chart.data';
@Component({
  selector: 'cmx-number-card-chart',
  templateUrl: './number-card-chart.component.html',
  styleUrls: ['./number-card-chart.component.scss'],
})
export class NumberCardChartComponent extends FieldType implements OnInit {
  @Input() data: SeriesData[] = seriesData || [];

  @Input() dimensions: Dimensions = { width: '', height: '' };
  @Input() allowResize = false;
  @Input() enableAnimations = true;
  @Input() itemColor: string[] = [];
  @Input() cardColor = '';
  @Input() bandColor = '';
  @Input() textColor = '';
  @Input() emptyColor = '';
  @Input() isFormlyField = true;

  @Output() raiseNumberChartEvent = new EventEmitter<UiEventRaised>();

  view: [number, number] = [500, 300];

  constructor(private element: ElementRef) {
    super();
  }

  ngOnInit() {
    if (this.isFormlyField) {
      this.data = this.to.data || this.data;
      this.allowResize = this.to.allowResize || this.allowResize;

      this.dimensions = this.to.dimensions || this.dimensions;
      this.enableAnimations = this.to.enableAnimations || this.enableAnimations;
      this.itemColor = this.to.itemColor || this.itemColor;
      this.cardColor = this.to.cardColor || this.cardColor;
      this.bandColor = this.to.bandColor || this.bandColor;
      this.textColor = this.to.textColor || this.textColor;
      this.emptyColor = this.to.emptyColor || this.emptyColor;
    }
    this.resize(this.dimensions.width, this.dimensions.height);
  }

  onSelect(data: unknown): void {
    this.raiseEvent('number-card-chart-clicked', data, false);
  }

  raiseEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseNumberChartEvent.emit(eventToRaise);
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.resize(undefined, this.dimensions.height);
  }

  resize(width?: string | number, height?: string | number, timeout = 0) {
    setTimeout(() => {
      const parentElement = this.element.nativeElement.parentElement.parentElement;
      if (parentElement) {
        this.view = [+(width || parentElement.offsetWidth - 30), +(height || parentElement.offsetHeight - 50)];
      }
    }, timeout);
  }
}
