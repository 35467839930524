import { Injectable } from '@angular/core';
import { Functions, httpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import { ProdeoHttpResponse } from '@cmx/shared/feature/platform-configuration';

@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  constructor(private readonly functions: Functions) {}

  async callFirebaseFunction(functionName: string, data: unknown): Promise<ProdeoHttpResponse> {
    const functionToCall = httpsCallable(this.functions, functionName);

    return functionToCall(data).then((response: HttpsCallableResult<any>) => {
      const prodeoResponse: ProdeoHttpResponse = response.data;
      return prodeoResponse;
    });
  }
}
