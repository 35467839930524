import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
  deleteItemFromLocalStorage,
} from '@cmx/shared/data-access/local-storage';
import { FirestoreService } from '@cmx/shared/feature/firestore';
import {
  Auth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
  user,
} from '@angular/fire/auth';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  toggleMenu$ = new BehaviorSubject<string>('');
  currentUser: any;
  constructor(private readonly firestore: FirestoreService, private readonly auth: Auth) {
    this.setUserToken();
  }

  public isAuthenticated(): boolean {
    this.currentUser = getItemFromLocalStorage('loggedInUser') ? getItemFromLocalStorage('loggedInUser') : null;
    return this.currentUser && this.currentUser.id;
  }

  private setUserToken(): void {
    user(this.auth).subscribe(async user => {
      if (user) {
        const token: string = await user.getIdToken();
        setItemToLocalStorage('prodeoToken', token);
      }
    });
  }

  async signInUser(loginCredential: { email: string; password: string }): Promise<unknown> {
    const { email, password } = loginCredential;
    const userCredential: UserCredential = await signInWithEmailAndPassword(this.auth, email, password);
    const allData: any[] = await this.firestore.loadInitialData(userCredential.user.uid);

    const userProfile = await this.firestore.getDocumentById(userCredential.user.uid);

    this.currentUser = userProfile;

    if (this.currentUser) {
      setItemToLocalStorage('loggedInUser', userProfile);

      const token: string = await userCredential.user.getIdToken();
      setItemToLocalStorage('prodeoToken', token);
    }

    return userProfile;
  }

  resetPasswordMail(email: string): Promise<void> {
    return sendPasswordResetEmail(this.auth, email);
  }

  async signOutUser(navigate = false) {
    await signOut(this.auth);
    deleteItemFromLocalStorage('loggedInUser');
    deleteItemFromLocalStorage('prodeoToken');
    if (navigate) {
      location.replace('');
    }
  }
}
