import { Injectable } from '@angular/core';
import { NetworkStatusAngularService } from 'network-status-angular';
import { AssetsManagerService } from '@cmx/shared/feature/asset-manager';

@Injectable({
  providedIn: 'root',
})
export class SyncManagerService {
  constructor(
    private networkStatusAngularService: NetworkStatusAngularService,
    private assetsManagerService: AssetsManagerService,
  ) {}

  public startListening(usedId: string) {
    this.assetsManagerService.processAssets(usedId);
    this.networkStatusAngularService.status.subscribe(async isOnline => {
      if (isOnline) {
        await this.assetsManagerService.processAssets(usedId);
      }
    });
  }
}
