<mat-carousel
  class="flex-center"
  timings="250ms ease-in"
  [color]="color"
  [maxWidth]="maxWidth"
  [maintainAspectRatio]="maintainAspectRatio"
  [slideHeight]="slideHeight"
  [autoplay]="autoplay"
  [loop]="loop"
  [hideArrows]="hideArrows"
  [hideIndicators]="hideIndicators"
  [useKeyboard]="useKeyboard"
  [useMouseWheel]="useMouseWheel"
  [proportion]="proportion"
  orientation="ltr"
>
  <mat-carousel-slide
    #matCarouselSlide
    *ngFor="let slide of files$ | async; let i = index"
    [image]="getSource(slide)"
    [overlayColor]="overlayColor"
    [hideOverlay]="hideOverlay"
  ></mat-carousel-slide>
</mat-carousel>
<div *ngIf="!(files$ | async)?.length" class="flex-center">No Data</div>
