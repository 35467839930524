<mat-form-field appearance="outline" *ngIf="showDevicePicker && devices" class="col-1">
  <mat-label>Video Device</mat-label>
  <mat-select name="cameraInput" (valueChange)="changeVideoInput($event)" [(ngModel)]="videoDevice">
    <mat-option *ngFor="let device of devices" [value]="device.deviceId">
      {{ device.label }}
    </mat-option>
  </mat-select>
</mat-form-field>

<div class="big-player" *ngIf="videoComponentState !== 'minirecorder'">
  <video id="video_{{ idx }}" class="video-js vjs-default-skin vjs-big-play-centered" playsinline></video>
</div>

<div class="small-player" *ngIf="videoComponentState === 'minirecorder'">
  <video id="video_{{ idx }}" class="video-js vjs-default-skin vjs-big-play-centered" playsinline></video>

  <div class="red-dot">
    {{ timerData }}
  </div>
  <div>
    <button
      class="record-button"
      type="button"
      (click)="toggleTimer(currentButton.event)"
      mat-raised-button
      [color]="currentButton.color"
    >
      <mat-icon>{{ currentButton.icon }}</mat-icon>
      {{ currentButton.label }}
    </button>
  </div>
</div>
