import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-card-counter-list',
  templateUrl: './card-counter-list.component.html',
  styleUrls: ['./card-counter-list.component.scss'],
})
export class CardCounterListComponent extends FieldType implements OnInit {
  @Input() isFormlyField = true;
  @Input() showBadge = false;
  @Input() showRightIcon = false;
  @Input() showLeftIcon = true;
  @Input() enableChildList = false;
  @Input() leftIconColor = '';
  @Input() rightIconColor = '';
  @Input() enableCollapse = true;
  @Input() allowCommenting = false;
  @Input() restrictKeys: any[] = [];
  @Input() cardList: any[];

  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  childProperties = true;

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.showBadge = this.to.showBadge || this.showLeftIcon;
      this.showRightIcon = this.to.showRightIcon || this.showRightIcon;
      this.showLeftIcon = this.to.showLeftIcon || this.showLeftIcon;
      this.allowCommenting = this.to.allowCommenting || this.allowCommenting;
      this.enableCollapse = this.to.enableCollapse || this.enableCollapse;
      this.restrictKeys = this.to.restrictKeys || this.restrictKeys;
      this.cardList = [...this.to.cardList] || [...this.cardList];
    }
  }

  raiseEventFromCard(cardEvent) {
    if (this.isFormlyField) {
      this.to.getFieldEvent(cardEvent);
    } else {
      this.raiseEvent.emit(cardEvent);
    }
  }
}
