<div [hidden]="backgroundScanner">
  <div class="barcode-container">
    <mat-form-field *ngIf="isFormlyField" appearance="outline">
      <mat-label class="blink">{{ label }}</mat-label>
      <mat-icon matPrefix class="suffixIcon">qr_code_scanner</mat-icon>
      <input
        #barcodeInput
        type="text"
        matInput
        [readonly]="isBarcodeScannerDisabled"
        [placeholder]="label"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        [maxLength]="maxLength"
        (keydown)="keypressEvent($event)"
      />
      <button
        mat-icon-button
        class="suffixIcon-button"
        matSuffix
        (click)="toggleInput(true)"
        *ngIf="isBarcodeScannerDisabled"
      >
        <mat-icon svgIcon="touch-keypad" class="suffixIcon"></mat-icon>
      </button>

      <button
        mat-icon-button
        matSuffix
        class="suffixIcon-button"
        [color]="submitButtonColor"
        (click)="submitBarcode()"
        *ngIf="!isBarcodeScannerDisabled"
      >
        <mat-icon class="suffixIcon"> send</mat-icon>
      </button>

      <button
        mat-icon-button
        matSuffix
        class="suffixIcon-button"
        (click)="toggleInput(false)"
        *ngIf="!isBarcodeScannerDisabled"
      >
        <mat-icon svgIcon="scan-barcode" class="suffixIcon"></mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field *ngIf="!isFormlyField" appearance="outline">
      <mat-label class="blink">{{ label }}</mat-label>
      <mat-icon matPrefix class="suffixIcon">qr_code_scanner</mat-icon>
      <input
        #barcodeInput
        type="text"
        matInput
        [readonly]="isBarcodeScannerDisabled"
        [placeholder]="label"
        [class.is-invalid]="showError"
        [maxLength]="maxLength"
        (keydown)="keypressEvent($event)"
      />
      <button
        mat-icon-button
        class="suffixIcon-button"
        matSuffix
        (click)="toggleInput(true)"
        *ngIf="isBarcodeScannerDisabled"
      >
        <mat-icon svgIcon="touch-keypad" class="suffixIcon"></mat-icon>
      </button>

      <button
        mat-icon-button
        matSuffix
        class="suffixIcon-button"
        [color]="submitButtonColor"
        (click)="submitBarcode()"
        *ngIf="!isBarcodeScannerDisabled"
      >
        <mat-icon class="suffixIcon"> send</mat-icon>
      </button>

      <button
        mat-icon-button
        matSuffix
        class="suffixIcon-button"
        (click)="toggleInput(false)"
        *ngIf="!isBarcodeScannerDisabled"
      >
        <mat-icon svgIcon="scan-barcode" class="suffixIcon"></mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>
