import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { FirestoreService, FunctionsService } from '@cmx/shared/feature/firestore';
import { HttpServiceSettings } from '@cmx/shared/feature/platform-configuration';
import { HTTPSERVICESETTINGS } from '@cmx/shared/util/environment-config';
import { BehaviorSubject, EMPTY, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, take, tap } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';
import { CoreMachine } from '@cmx/state-machines/core';

export interface UploadResult {
  data: string;
  message: string;
}

export interface UploadProgress {
  fileId: string;
  progress: number;
}

export interface UploadError {
  fileId: string;
  errorMessage: string;
  timestamp: number;
}

export interface UploadStatus {
  fileId: string;
  fileName: string;
  started?: number;
  completed?: number;
  requested?: number;
  sessionURL?: string;
  fileURL?: string;
  progress?: number;
}

export const INIT_INTERVAL_MS = 100; // 100 ms
export const MAX_INTERVAL_MS = 20 * 1000; // 20 sec

@Injectable({
  providedIn: 'root',
})
export class FileUploaderService implements OnDestroy {
  progress$ = new BehaviorSubject<UploadProgress>(null);
  started$ = new BehaviorSubject<UploadStatus>(null);
  completed$ = new BehaviorSubject<UploadStatus>(null);
  error$ = new BehaviorSubject<UploadError>(null);

  defaultPath = 'files/videos/';
  defaultBucketName = `bucket-${this.httpServiceSettings.bucketName}`;

  private isTaskActive = false;

  isTaskActiveSubscription: Subscription;

  constructor(
    private readonly http: HttpClient,
    @Inject(HTTPSERVICESETTINGS) private httpServiceSettings: HttpServiceSettings,
    private readonly functions: FunctionsService,
    private readonly firestore: FirestoreService,
    private readonly coreService: CoreMachine,
  ) {
    this.isTaskActiveSubscription = this.coreService.isTaskCurrentlyActive$.subscribe(
      isTaskActive => (this.isTaskActive = isTaskActive),
    );
  }

  ngOnDestroy(): void {
    this.isTaskActiveSubscription.unsubscribe();
  }

  public async upload(
    file: Blob,
    fileName: string,
    fileId: string,
    filePath?: string,
    bucketName?: string,
    gcpSessionUri?: string,
    type?: string,
  ) {
    const currentFilePath = filePath ? filePath : this.defaultPath;
    const currentBucket = bucketName ? bucketName : this.defaultBucketName;
    let sessionURL = '';
    if (!gcpSessionUri) {
      sessionURL = (await this.getSessionURL(file, fileName, currentFilePath, currentBucket, fileId)) as string;
    } else {
      sessionURL = gcpSessionUri;
    }

    if (sessionURL) {
      this.postResumable(file, fileId, sessionURL, fileName, type)
        .pipe(
          take(1),
          catchError(async error => {
            this.error$.next({ fileId, timestamp: Date.now(), errorMessage: error.message });
          }),
        )
        .subscribe();
    }
  }

  private getSessionURL(file: Blob, fileName: any, filePath: string, bucketName: string, fileId: string) {
    const port = window.location.port ? ':' + window.location.port : '';
    const origin = window.location.protocol + '//' + window.location.hostname + port;
    const body = {
      'Content-Type': file.type,
      filePath,
      bucketName,
      fileName,
      origin: origin,
    };

    return this.functions
      .callFirebaseFunction('generateResumableUploadURI', body)
      .then((result: any) => result.data[0])
      .catch(error => {
        this.firestore.storeDocument({
          referenceDocId: `${fileId}`,
          bucketName,
          fileName,
          error: JSON.stringify(error),
          ignoreSync: true,
          collectionName: 'log',
          timestamp: Date.now(),
        });
      });
  }

  private postResumable(
    file: Blob,
    fileId: string,
    sessionURL: string,
    fileName: string,
    type: string,
  ): Observable<unknown> {
    return this.getCurrentAsssetProgress(sessionURL).pipe(
      concatMap((result: any) => {
        if (result.mediaLink) {
          this.completed$.next({
            fileId,
            completed: Date.now(),
            fileURL: result.mediaLink,
            fileName,
            progress: 100,
          });

          return EMPTY;
        }
        return this.chunkUpload(file, fileId, sessionURL, fileName, result, type);
      }),
    );
  }

  private getCurrentAsssetProgress(sessionURL: string) {
    const range = `bytes */*`;
    const headers = new HttpHeaders({
      'Content-Range': range,
    });
    const options = { headers: headers };

    return this.http.put(sessionURL, null, options).pipe(
      take(1),
      catchError(error => {
        if (error.status === 308) {
          const rangeHeader = error.headers.get('Range') ? error.headers.get('Range') : '';
          return of(rangeHeader);
        }
        const err = new Error(`Unexpected error trying to get current asset progress: ${error.message}`);
        return throwError(() => err);
      }),
      retryBackoff({
        initialInterval: INIT_INTERVAL_MS,
        maxInterval: MAX_INTERVAL_MS,
        maxRetries: 5,
        resetOnSuccess: true,
      }),
    );
  }

  chunkUpload(
    file: Blob,
    fileId: string,
    sessionURL: string,
    fileName: string,
    range?: string,
    type?: string,
  ): Observable<unknown> {
    return new Observable(observer => {
      console.log(file.size);
      let index = 0,
        offset = 0;
      const CHUNK_SIZE = 262144; //1024*1024;
      const fileSize = file.size;
      const reader = new FileReader();
      const newIndex = range.split('-').pop();
      index = range && index === 0 ? parseInt(newIndex) + 1 : index;
      let blob = file.slice(index, index + CHUNK_SIZE); //a single chunk in starting of step size
      console.log(blob.size);
      reader.readAsArrayBuffer(blob); // reading that chunk. when it read it, onload will be invoked
      this.started$.next({ started: Date.now(), sessionURL, fileId, fileName });
      this.progress$.next({ progress: 1, fileId });

      this.started$.next(null);
      this.progress$.next(null);

      reader.onload = e => {
        if (!this.isTaskActive || type !== 'video') {
          offset = index + blob.size;

          const newRange = `bytes ${index}-${offset - 1}/${fileSize}`;

          const headers = new HttpHeaders({
            'Content-Type': file.type,
            'Content-Range': newRange,
          });
          const options = { headers: headers };

          console.log(reader.result);
          this.http
            .put(sessionURL, reader.result, options)
            .pipe(
              take(1),
              tap(result => {
                console.log(result);
              }),
              catchError(error => {
                if (error.status === 308) {
                  index = offset;
                  const progress = Math.floor((index / fileSize) * 100);
                  this.progress$.next({ progress, fileId });
                  this.progress$.next(null);
                  if (offset <= fileSize) {
                    blob = file.slice(index, index + CHUNK_SIZE); // getting next chunk
                    reader.readAsArrayBuffer(blob); //reading it through file reader which will call onload again. So it will happen recursively until file is completely uploaded.
                    return EMPTY;
                  }
                } else {
                  console.error(error);
                  this.firestore.storeDocument({
                    referenceDocId: `${fileId}`,
                    fileName,
                    error: JSON.stringify(error),
                    ignoreSync: true,
                    collectionName: 'log',
                    timestamp: Date.now(),
                  });

                  const err = new Error(
                    `Unexpected error trying to get upload current chunk(${newRange}): ${error.message}`,
                  );
                  return throwError(() => err);
                }
              }),
              retryBackoff({
                initialInterval: INIT_INTERVAL_MS,
                maxInterval: MAX_INTERVAL_MS,
                maxRetries: 10,
                resetOnSuccess: true,
              }),
            )
            .subscribe(async (result: any) => {
              const timeStamp = Date.now();
              this.completed$.next({
                fileId,
                completed: timeStamp,
                fileURL: result.mediaLink,
                fileName,
                progress: 100,
              });

              this.completed$.next(null);
              return observer.next(result);
            });
        }
      };
    });
  }
}
