import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { Dimensions, Legend, SeriesData } from '../charts.model';
import { FieldType } from '@ngx-formly/core';
import { seriesData } from '../chart.data';

@Component({
  selector: 'cmx-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss'],
})
export class GaugeChartComponent extends FieldType implements OnInit {
  @Input() data: SeriesData[] = seriesData || [];

  @Input() dimensions: Dimensions = { width: '', height: '' };
  @Input() allowResize = false;
  @Input() legend: Legend = {
    visible: true,
    title: 'Legend',
    position: 'right' || 'below',
  };
  @Input() enableAnimations = true;
  @Input() showText = true;
  @Input() showAxis = true;
  @Input() angleSpan = 240;
  @Input() minScale = 0;
  @Input() maxScale = 100;
  @Input() valueUnit = '';
  @Input() tooltipDisabled = false;
  @Input() isFormlyField = true;

  @Output() raiseGaugeChartEvent = new EventEmitter<UiEventRaised>();

  view: [number, number] = [500, 300];

  constructor(private element: ElementRef) {
    super();
  }

  ngOnInit() {
    if (this.isFormlyField) {
      this.data = this.to.data || this.data;
      this.allowResize = this.to.allowResize || this.allowResize;

      this.dimensions = this.to.dimensions || this.dimensions;
      this.legend = this.to.legend || this.legend;
      this.showAxis = this.to.showAxis || this.showAxis;
      this.showText = this.to.showText || this.showText;
      this.enableAnimations = this.to.enableAnimations || this.enableAnimations;
      this.tooltipDisabled = this.to.tooltipDisabled || this.tooltipDisabled;
      this.valueUnit = this.to.valueUnit || this.valueUnit;
      this.angleSpan = this.to.angleSpan || this.angleSpan;
      this.minScale = this.to.minRadius || this.minScale;
      this.maxScale = this.to.maxScale || this.maxScale;
    }
    this.resize(this.dimensions.width, this.dimensions.height);
  }

  onSelect(data: unknown): void {
    this.raiseEvent('gauge-chart-clicked', data, false);
  }

  raiseEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseGaugeChartEvent.emit(eventToRaise);
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.resize(undefined, this.dimensions.height);
  }

  resize(width?: string | number, height?: string | number, timeout = 0) {
    setTimeout(() => {
      const parentElement = this.element.nativeElement.parentElement.parentElement;
      if (parentElement) {
        this.view = [+(width || parentElement.offsetWidth - 30), +(height || parentElement.offsetHeight)];
      }
    }, timeout);
  }
}
