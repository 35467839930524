import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge } from 'rxjs';

export interface GridColumns {
  dataField: string;
  displayName?: string;
  type?: string;
  allowSorting?: boolean;
}

export class GridDataSource extends DataSource<unknown> {
  data: unknown[] = [];
  paginator!: MatPaginator;
  sort!: MatSort;

  constructor(data: unknown[]) {
    super();
    this.data = data;
  }

  connect(): Observable<unknown[]> {
    const dataMutations: any = [observableOf(this.data), this.paginator.page, this.sort.sortChange];

    return merge(...(dataMutations as any)).pipe(
      map(() => {
        return this.getPagedData(this.getSortedData([...this.data]));
      }),
    );
  }

  private getPagedData(data: unknown[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  private getSortedData(data: unknown[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a: any, b: any) => {
      const isAsc = this.sort.direction === 'asc';
      const sortBy: string | number = this.sort.active;
      if (sortBy) {
        return compare(a[sortBy], b[sortBy], isAsc);
      } else return 0;
    });
  }

  disconnect() {
    console.log('Method not implemented.');
  }
}

function compare(a: string | number, b: string | number, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
