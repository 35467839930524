import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoStatusComponent } from './video-status.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CardWidgetModule } from '@cmx/shared/ui/card-widget';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SharedUiHeaderModule } from '@cmx/shared/ui/header';
import { FileStatusComponent } from './file-status/file-status.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    CardWidgetModule,
    MatButtonToggleModule,
    SharedUiHeaderModule,
  ],
  declarations: [VideoStatusComponent, FileStatusComponent],
  exports: [VideoStatusComponent, FileStatusComponent],
})
export class VideoStatusModule {}
