<ngx-charts-bar-vertical-normalized
  [view]="view"
  [results]="data"
  [xAxis]="axis?.showXAxis"
  [yAxis]="axis?.showYAxis"
  [xAxisLabel]="axis?.xAxisLabel"
  [yAxisLabel]="axis?.yAxisLabel"
  [showXAxisLabel]="axis?.xAxisLabel"
  [showYAxisLabel]="axis?.yAxisLabel"
  [gradient]="enableGradient"
  [animations]="enableAnimations"
  [legend]="legend?.visible || true"
  [legendTitle]="legend?.title || 'Legend'"
  [legendPosition]="legend?.position || 'right'"
  [showGridLines]="showGridLines"
  [tooltipDisabled]="tooltipDisabled"
  [barPadding]="barPadding"
  (select)="onSelect($event)"
>
</ngx-charts-bar-vertical-normalized>
