import { lastValueFrom, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AppVersionService } from '@cmx/shared/feature/app-version-loader';

export function appVersionFactory(appVersionService: AppVersionService) {
  return async () => {
    return await lastValueFrom(
      appVersionService.getLatestAppVersion().pipe(
        concatMap((version: number) => {
          console.dir(version);
          console.log(appVersionService.getAppVersion());

          return of(version);
        }),
      ),
    );
  };
}
