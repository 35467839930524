import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { PieChartComponent } from './pie-chart/pie-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { HeatMapChartComponent } from './heat-map-chart/heat-map-chart.component';
import { TreeMapChartComponent } from './tree-map-chart/tree-map-chart.component';
import { AreaChartComponent } from './area-chart/area-chart.component';
import { StackedAreaChartComponent } from './stacked-area-chart/stacked-area-chart.component';
import { NormalizedAreaChartComponent } from './normalized-area-chart/normalized-area-chart.component';
import { AdvancePieChartComponent } from './advance-pie-chart/advance-pie-chart.component';
import { GridPieChartComponent } from './grid-pie-chart/grid-pie-chart.component';
import { BubbleChartComponent } from './bubble-chart/bubble-chart.component';
import { NumberCardChartComponent } from './number-card-chart/number-card-chart.component';
import { PolarChartComponent } from './polar-chart/polar-chart.component';
import { GaugeChartComponent } from './gauge-chart/gauge-chart.component';
import { BarHorizontalChartComponent } from './bar-horizontal-chart/bar-horizontal-chart.component';
import { BarHorizontalNormalizedChartComponent } from './bar-horizontal-normalized-chart/bar-horizontal-normalized-chart.component';
import { BarHorizontalStackedChartComponent } from './bar-horizontal-stacked-chart/bar-horizontal-stacked-chart.component';
import { BarHorizontalGroupedChartComponent } from './bar-horizontal-grouped-chart/bar-horizontal-grouped-chart.component';
import { BarVerticalGroupedChartComponent } from './bar-vertical-grouped-chart/bar-vertical-grouped-chart.component';
import { BarVerticalStackedChartComponent } from './bar-vertical-stacked-chart/bar-vertical-stacked-chart.component';
import { BarVerticalNormalizedChartComponent } from './bar-vertical-normalized-chart/bar-vertical-normalized-chart.component';
import { BarVerticalChartComponent } from './bar-vertical-chart/bar-vertical-chart.component';

@NgModule({
  imports: [CommonModule, NgxChartsModule],
  declarations: [
    PieChartComponent,
    LineChartComponent,
    HeatMapChartComponent,
    TreeMapChartComponent,
    AreaChartComponent,
    StackedAreaChartComponent,
    NormalizedAreaChartComponent,
    AdvancePieChartComponent,
    GridPieChartComponent,
    BubbleChartComponent,
    NumberCardChartComponent,
    PolarChartComponent,
    GaugeChartComponent,
    BarHorizontalChartComponent,
    BarHorizontalNormalizedChartComponent,
    BarHorizontalStackedChartComponent,
    BarHorizontalGroupedChartComponent,
    BarVerticalGroupedChartComponent,
    BarVerticalStackedChartComponent,
    BarVerticalNormalizedChartComponent,
    BarVerticalChartComponent,
  ],
  exports: [
    PieChartComponent,
    LineChartComponent,
    HeatMapChartComponent,
    TreeMapChartComponent,
    AreaChartComponent,
    StackedAreaChartComponent,
    NormalizedAreaChartComponent,
    AdvancePieChartComponent,
    GridPieChartComponent,
    BubbleChartComponent,
    NumberCardChartComponent,
    PolarChartComponent,
    GaugeChartComponent,
    BarHorizontalChartComponent,
    BarHorizontalNormalizedChartComponent,
    BarHorizontalStackedChartComponent,
    BarHorizontalGroupedChartComponent,
    BarVerticalGroupedChartComponent,
    BarVerticalStackedChartComponent,
    BarVerticalNormalizedChartComponent,
    BarVerticalChartComponent,
  ],
})
export class ChartsModule {}
