import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UiEventRaised, File } from '@cmx/shared/feature/platform-configuration';
import { ImageViewerService } from './image-viewer.service';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'cmx-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent extends FieldType implements OnInit {
  @Input() isFormlyField = true;
  @Input() taskId = '';
  @Input() slideHeight = '400px';
  @Input() overlayColor = '#000';
  @Input() color = 'accent' as ThemePalette;
  @Input() autoplay = false;
  @Input() interval = 5000;
  @Input() proportion = 25;
  @Input() maxWidth = '600px';
  @Input() loop = false;
  @Input() hideArrows = false;
  @Input() hideOverlay = false;
  @Input() hideIndicators = false;
  @Input() maintainAspectRatio = false;
  @Input() useMouseWheel = false;
  @Input() useKeyboard = false;
  @Input() defaultImage =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAM8AAAD0CAMAAAAL4oIDAAAAOVBMVEXAwMD///+7u7vJycnu7u7Pz8/7+/v19fXS0tK+vr7x8fG6urrd3d34+PjCwsLGxsbl5eXY2Njo6OhvnUvPAAABN0lEQVR4nO3Yy46CMABAUeRd3vj/HzuIksxMlMTNmHbO2SB00xvSBptlAAAAAAAAAAAAAAAAAAAAAAAAAAAAwN8J+blPz+9NYShPddOnZ/ieUF7OxdczVK/VEfa0L5dQ6KPsya/NU+Ocx9nTvFg7Rcw99dAuCfXUcwh5m07PELa7fEymZ917umR6yv3L5t4xJtBzWUI/3Te6bqoT6Nm2hMdrWfMliZ7DHOY6oZ4lz8KSUM+8PS7qZHr2jTsrk+hpyqHYc7Iq/p5xKLIQjoEu9p7rFL4PrLeeSP//bD31Gn4OTF3MPdc5/B5Z61h7QlU9GZmqdopy/VT9cVzwuNzcfvR5lO9nbJpu03RluV2Oc7f7EUKM51XnIuvJ5uLcp+cHAAAAAAAAAAAAAAAAAAAAAAAAAAAA/8cXFZgNlyZfDNsAAAAASUVORK5CYII=';
  @Input()
  files$!: Observable<any[]>;
  @Output() fileViewerEvent = new EventEmitter<UiEventRaised>();
  slides = [];
  inprogressVideoSubscription: Subscription | undefined;

  constructor(private readonly imageViewerService: ImageViewerService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    if (this.isFormlyField) {
      // this.files = this.to.files || this.files;
      this.taskId = this.to.taskId || this.taskId;
      this.color = this.to.color || this.color;
      this.slideHeight = this.to.slideHeight || this.slideHeight;
      this.overlayColor = this.to.overlayColor || this.overlayColor;
      this.autoplay = this.to.autoplay || this.autoplay;
      this.interval = this.to.interval || this.interval;
      this.proportion = this.to.proportion || this.proportion;
      this.maxWidth = this.to.maxWidth || this.maxWidth;
      this.loop = this.to.loop || this.loop;
      this.hideArrows = this.to.hideArrows || this.hideArrows;
      this.hideOverlay = this.to.hideOverlay || this.hideOverlay;
      this.useMouseWheel = this.to.useMouseWheel || this.useMouseWheel;
      this.useKeyboard = this.to.useKeyboard || this.useKeyboard;
      this.maintainAspectRatio = this.to.maintainAspectRatio || this.maintainAspectRatio;
      this.hideIndicators = this.to.hideIndicators || this.hideIndicators;
      this.files$ = this.imageViewerService.getImages(this.taskId).pipe(tap(images => console.log(images)));
    }
  }

  raiseFileViewerEvent(eventToRaise: UiEventRaised): void {
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.fileViewerEvent.emit(eventToRaise);
    }
  }

  getSource(fileObj: any) {
    if (!fileObj) return this.defaultImage;
    if (fileObj.mimeType.match(/image\/*/)) {
      return fileObj.src;
    }
  }
}
