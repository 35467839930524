import { FirestoreService } from '@cmx/shared/feature/firestore';
import { MachineLoaderService } from './machine-loader.service';
import jsonata from 'jsonata';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { createMachine, interpret, Interpreter, State } from 'xstate';
import { MachineEvent } from '@cmx/shared/util/interfaces';

const MACHINE_ID = 'confirmationDialogMachine';

interface MachineConfig {
  machineId: string;
  context?: any;
}

export abstract class BaseMachine {
  public selectedWorkflow: any;
  events$ = new Subject();
  eventsSubscription?: Subscription = undefined;
  state$ = new Subject();
  stateChanges: Observable<any>[] = [];
  formEvents: Observable<any>[] = [];

  public machineConfig: any;
  public machineLoaderService: MachineLoaderService;

  public childInterpreter!: Interpreter<any, any, any>;
  public interpreter: any = {};
  constructor(machineLoaderService: MachineLoaderService, private firestore: FirestoreService) {
    this.machineLoaderService = machineLoaderService;
  }

  async getMachineFromDB(machineId: string): Promise<any> {
    let machineDef = await this.machineLoaderService.load(machineId);

    if (!machineDef.length) throw new Error('Not found');
    machineDef = machineDef.pop();
    return machineDef;
  }

  async initMachine(machineConfig: MachineConfig, loadState: boolean = true) {
    if (!machineConfig) throw new Error('Need  machine config param');
    const machineDef = await this.getMachineFromDB(machineConfig.machineId);
    this.parseMachineDefinitionFeatures(machineDef.features);

    const context = {
      ...machineDef.payload.context,
      ...{ model: { ...machineDef.payload.context.model, ...machineConfig.context.model } },
      ...{ workflow: this.selectedWorkflow },
    };

    const machine = createMachine(machineDef.payload, machineDef.features).withContext(context);

    this.interpreter[machineConfig.machineId] = interpret(machine, { devTools: true });

    this.eventsSubscription = this.events$.pipe(distinctUntilChanged()).subscribe(context => {
      this.interpreter[machineConfig.machineId].send(context);
    });

    const machineStateId = context?.model?.parentTask?.id ? `machineState:${context?.model?.parentTask?.id}` : null;
    const savedStateObject = machineStateId ? await this.firestore.getDocumentById(machineStateId) : null;
    const savedState = savedStateObject && savedStateObject.savedState ? JSON.parse(savedStateObject.savedState) : null;

    const previousState = savedState ? (State.create(savedState ?? machine.initialState) as any) : null;

    const stateMachine = this.interpreter[machineConfig.machineId].onTransition((state, event) => {
      if (previousState && event.type === 'LOAD_FORM') {
        setTimeout(() => this.state$.next({ context: state.context, event }), 100);
      } else {
        this.state$.next({ context: state.context, event });
      }
    });

    if (loadState && previousState) {
      stateMachine.start(previousState);
    } else {
      stateMachine.start();
    }

    //return this.interpreter[machineConfig.machineId];

    this.interpreter[machineConfig.machineId].onTransition((state: any, event: any) => {
      if (event.type === 'LOAD_FORM' && loadState) {
        if (!event.skipState && state.context.model.parentTask.id) {
          const completedStatus = this.firestore.initialWorkflowData.statuses.find(
            (status: any) => status.name === 'Completed',
          );
          if (state.context.model.parentTask.status !== completedStatus.id) {
            this.saveState(state);
          }
        }
      }
      if (state.changed) {
        console.log(`The state did change to ${state.value}, so ${event.type} caused a valid transition`);
      } else {
        console.log(`${event.type} did not cause a state change`);
      }
    });
    return machine;
  }

  private saveState(state: any) {
    delete state.context.model.parentTask.images;
    state = this.evaluate(`$ ~> |context|{ 'tmp':{}}|`, state);
    const savedState = {
      ignoreSync: true,
      id: `machineState:${state.context.model.parentTask.id}`,
      savedState: JSON.stringify(state),
      timestamp: new Date().getTime(),
    };
    this.firestore.storeDocument(savedState);
  }

  initMachineWithDef(machineConfig: MachineConfig, machineDef: any) {
    if (!machineConfig) throw new Error('Need  machine config param');
  }

  parseMachineDefinitionFeatures(features: any) {
    if (features?.guards) {
      for (const [key, value] of Object.entries<string>(features.guards)) {
        if (typeof value !== 'function') {
          const myObj = value as any;
          const params = myObj.arguments || 'context, event, options';
          if (typeof value === 'object' && params && myObj.body) {
            try {
              features.guards[key] = new Function(params, myObj.body).bind(this);
            } catch (e) {
              console.error(
                `Error parsing guard ${key} with content: ${JSON.stringify(features.guards[key])} and error:${
                  (e as any).message
                }`,
              );
              throw e;
            }
          } else if (typeof value === 'string') {
            features.guards[key] = this[value];
          }
        }
      }
    }
    if (features?.services) {
      for (const [key, value] of Object.entries<string>(features.services)) {
        if (typeof value !== 'function') {
          const myObj = value as any;
          const params = myObj.arguments || 'context, event, options';
          if (typeof value === 'object' && params && myObj.body) {
            try {
              features.services[key] = new Function(params, myObj.body).bind(this);
            } catch (e) {
              console.error(
                `Error parsing service ${key} with content: ${JSON.stringify(features.services[key])} and error:${
                  (e as any).message
                }`,
              );
              throw e;
            }
          } else if (typeof value === 'string') {
            features.services[key] = this[value];
          }
        }
      }
    }
    if (features?.actions) {
      for (const [key, value] of Object.entries<string>(features.actions)) {
        if (typeof value !== 'function') {
          const myObj = value as any;
          const params = myObj.arguments || 'context, event, options';
          if (typeof value === 'object' && params && myObj.body) {
            try {
              features.actions[key] = new Function(params, myObj.body).bind(this);
            } catch (e) {
              console.error(
                `Error parsing action ${key} with content: ${JSON.stringify(
                  features.actions[key],
                )} and error:${JSON.stringify(features.actions[key])} and error:${(e as any).message}`,
              );
              throw e;
            }
          } else if (typeof value === 'string') {
            features.actions[key] = this[value];
          }
        }
      }
    }
  }

  sendEvent = (machineEvent: MachineEvent, machineId?: string) => {
    // if (machineId) {
    //   this.interpreter[machineId].send(machineEvent);
    // } else {
    //   this.interpreter[this.selectedWorkflow.machineId].send(machineEvent);
    // }
    this.broadCastEvent(machineEvent);
  };

  evaluate(expresion: string, data: any): any {
    const result = jsonata(expresion).evaluate(data);
    return result;
  }

  broadCastEvent = (machineEvent: MachineEvent) => {
    for (const [_, interpreter] of Object.entries(this.interpreter)) {
      (interpreter as any).send(machineEvent.type, machineEvent.data);
    }
  };

  stopMachine = () => {
    for (const [_, interpreter] of Object.entries(this.interpreter)) {
      (interpreter as any).stop();
    }
  };

  restartMachine = () => {
    this.interpreter['coreMachine'].start();
  };

  isEmpty = (object: any) => Object.keys(object).length === 0;

  unsubscribeFromEvents = () => {
    this.eventsSubscription?.unsubscribe();
  };
}
