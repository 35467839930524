<div class="form-container">
  <h2 *ngIf="titleReq" class="form-title">
    {{ formTitle }}
  </h2>

  <form [formGroup]="customForm" (ngSubmit)="onSubmit(customForm)">
    <formly-form [model]="formModel" [fields]="formFields" [form]="customForm"></formly-form>

    <div *ngIf="clrBtnReq || submitBtnReq" class="form-action-buttons">
      <button *ngIf="clrBtnReq" type="reset" mat-raised-button [color]="clearButtoncolor" (click)="onResetForm()">
        Clear
      </button>
      <button *ngIf="submitBtnReq" type="submit" mat-raised-button [color]="submitButtoncolor">Submit</button>
    </div>
  </form>
</div>
