import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'cmx-uploader-tile',
  templateUrl: './uploader-tile.component.html',
  styleUrls: ['./uploader-tile.component.scss'],
})
export class UploaderTileComponent implements OnInit {
  @Input() file: File;
  @Input() readOnly: boolean;
  @Output() remove = new EventEmitter<File>();

  private reader: FileReader;
  public src$ = new BehaviorSubject<string>(null);

  public ngOnInit(): void {
    this.reader = new FileReader();
    this.reader.onload = e => {
      this.src$.next(e.target.result as string);
    };

    this.reader.readAsDataURL(this.file);
  }

  public getBackgroundImage(src: unknown): { [key: string]: string } {
    if (/image/gi.test(this.file.type)) {
      return { backgroundImage: `url(${src})` };
    }


    return {};
  }

  public download(src: unknown, filename: string) {
    if (typeof src === 'string') {
      saveAs(src, filename);
    }
  }

  public removeFile(event): void {
    event.stopPropagation();
    this.remove.emit(this.file);
  }
}
