import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssetsManagerService } from '@cmx/shared/feature/asset-manager';
import { IconService } from '@cmx/shared/feature/icon-manager';
import { mapDataWithTemplate } from '@cmx/shared/util/helper-functions';
import { FieldType } from '@ngx-formly/core';
import { Observable, Subscription } from 'rxjs';
import { VideoStatusService } from '../video-status.service';

@Component({
  selector: 'cmx-file-status',
  templateUrl: './file-status.component.html',
  styleUrls: ['./file-status.component.scss'],
})
export class FileStatusComponent extends FieldType implements OnInit, OnDestroy {
  @Input() isFormlyField = true;
  @Input() selected = 'inProgress';
  @Input() fileId = '';

  inprogressVideoList: any[] = [];

  inprogressVideoSubscription: Subscription | undefined;

  template = {
    cardContent: {
      title: { key: '', value: 'asset.displayName ? asset.displayName : displayName' },
      subHeader: [
        { key: "'Progress'", value: "asset.uploadProgress ? asset.uploadProgress : uploadProgress & '%'" },
        { key: "'Start'", value: 'asset.timestamp ? asset.timestamp: timestamp', type: "'date'" },
        { key: "'End'", value: 'asset.completedTime ? asset.completedTime: completedTime', type: "'date'" },
      ],
      leftIcon: {
        type: "'svg'",
        icon: "'video-upload'",
      },
    },
  };

  constructor(
    private readonly videoService: VideoStatusService,
    private readonly iconService: IconService,
    private readonly router: Router,
    private readonly assetManagerService: AssetsManagerService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.fileId = this.to.fileId;
    }
    this.iconService.registerIcon('video-upload', `assets/icons/video-upload.svg`);
    this.getVideoList();
  }

  ngOnDestroy(): void {
    this.inprogressVideoSubscription?.unsubscribe();
  }

  getVideoList() {
    const inProgressVideos$: Observable<any[]> = this.videoService.getInProgressVideoList();
    this.inprogressVideoSubscription = inProgressVideos$.subscribe(videos => {
      const videoList = videos.filter(video => {
        const result = video.uploadProgress < 100 && video.taskId === this.fileId;
        return result;
      });
      this.inprogressVideoList = this.mapData(videoList);
    });
  }

  mapData(data: unknown[]): any[] {
    if (data.length === 0) {
      return [];
    }
    return data.map((video: unknown) => mapDataWithTemplate(video, this.template, video));
  }
}
