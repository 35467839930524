import { Injectable } from '@angular/core';
import { UserProfile } from '@cmx/shared/feature/authentication';
import { NavigationsItems } from '@cmx/shared/feature/platform-configuration';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  constructor() {}

  getMenuItems(): NavigationsItems[] {
    return [
      {
        name: 'Task Manager',
        iconSrc: 'assets/icons/tasks.svg',
        iconColor: '',
        url: 'workflow',
      },
      {
        name: 'Reports',
        iconSrc: 'assets/icons/tasks.svg',
        iconColor: '',
        url: 'reports',
      },
    ];
  }

  getLoggedInUser(): UserProfile {
    return {
      fullName: 'Abhinav Ranjan ',
      firstName: 'Abhinav',
      lastName: 'Ranjan',
      email: 'abhinav@cargomatrix.com',
      picture: '',
      status: 'online',
      id: '',
      language: '',
      phone: '',
      stateMachineConfig: '',
      theme: '',
      permissions: [],
      roles: [],
      settings: [],
    };
  }
}
