import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { FieldType } from '@ngx-formly/material/form-field';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';

@Component({
  selector: 'cmx-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
})
export class SelectBoxComponent extends FieldType implements OnInit {
  @Input() data: unknown[] = [];
  @Input() displayName = 'value';
  @Input() label = 'Select Box';
  @Input() multiple = false;
  @Input() appearance: MatFormFieldAppearance = 'outline';
  @Input() isFormlyField = true;
  @Input() changeEvent = 'SELECTION_CHANGE';
  @Input() selectedValue: unknown;
  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  @ViewChild('allSelected') allSelected: MatOption;
  control = new FormControl();

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.displayName = this.to.displayName || this.displayName;
      this.label = this.to.label || this.label;
      this.multiple = this.to.multiple || this.multiple;
      this.appearance = this.to.appearance || this.appearance;
      this.data = this.to.data || (this.data as unknown);
      this.changeEvent = this.to.changeEvent || this.changeEvent;
      this.valueChanges();
    }
  }

  valueChanges() {
    this.control.valueChanges.subscribe(selectedValue => {
      if (this.multiple) {
        selectedValue = selectedValue.filter((item: unknown) => item && item !== 'selectAll');
      }
      this.raiseSelectBoxEvent(selectedValue);
    });
  }

  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log('change detected');
  }

  toggleSelectAll() {
    if (this.allSelected.selected) {
      this.control.setValue([...this.data.map(item => item), 'selectAll']);
    } else {
      this.control.setValue([]);
    }
  }

  checkUncheck() {
    if (this.allSelected) {
      if (this.allSelected.selected) {
        this.allSelected.deselect();
        return false;
      }
      if (this.control.value.length == this.data.length) this.allSelected.select();
    }
  }

  typeOf(value) {
    return typeof value;
  }

  raiseSelectBoxEvent(data: unknown) {
    const eventToRaise: UiEventRaised = {
      eventName: this.changeEvent,
      data: data,
      isNavigation: false,
    };
    if (this.isFormlyField) {
      this.formControl.setValue(eventToRaise.data);
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseEvent.emit(eventToRaise);
    }
  }
}
