<div class="zxing-scanner-container">
  <button class="scanner-button" mat-raised-button color="primary" type="button" (click)="showScanner = !showScanner">
    {{ showScanner ? 'Hide' : 'Show' }} Scanner
  </button>
  <ng-container *ngIf="showScanner">
    <mat-form-field appearance="outline">
      <mat-label>Select Camera</mat-label>

      <mat-select (selectionChange)="onDeviceSelectChange($event)">
        <mat-option *ngFor="let item of availableDevices" [value]="item.deviceId">
          {{ item?.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <div *ngIf="currentDevice" [ngClass]="{ 'scan-line': qrResponse === false }"></div> -->

    <zxing-scanner
      #scanner
      (scanSuccess)="onCodeResult($event)"
      (camerasFound)="onCamerasFound($event)"
      [(device)]="currentDevice"
      [formats]="formatsEnabled"
      (scanComplete)="onScanComplete($event)"
      previewFitMode="fill"
    >
    </zxing-scanner>
  </ng-container>
</div>
