import { Component, EventEmitter, HostBinding, Output, ViewEncapsulation } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { isDesktopDevice } from '@cmx/shared/util/helper-functions';

@Component({
  selector: 'cmx-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PanelWrapperComponent extends FieldWrapper {
  @HostBinding('class.maximized-panel') maximized = false;
  @HostBinding('class.maximized-panel-mobile') maximizedMobile = false;

  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  maximizeMinimize() {
    if (isDesktopDevice()) {
      this.maximized = !this.maximized;
    } else {
      this.maximizedMobile = !this.maximizedMobile;
    }
    this.raiseEvent.emit({
      eventName: 'maxmise-panel',
      data: isDesktopDevice() ? this.maximized : this.maximizedMobile,
      isNavigation: false,
    });
  }

  share() {
    console.log('share');
    this.raiseEvent.emit({ eventName: 'share-panel', data: '', isNavigation: false });
  }
}
