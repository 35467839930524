import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised, Badge } from '@cmx/shared/feature/platform-configuration';
import { IconService } from '@cmx/shared/feature/icon-manager';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent extends FieldType implements OnInit {
  @Input() badge: Badge = {
    text: '',
    icon: '',
    iconUrl: '',
    count: 0,
    machineId: '',
  };
  @Input() color = undefined;
  @Input() isFormlyField = true;
  @Input() eventName = 'BADGE_CLICK';
  @Output() clickedBadge = new EventEmitter<UiEventRaised>();

  constructor(private iconService: IconService) {
    super();
  }

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.color = this.to.color || this.color;
    }
    this.iconService.registerIcon(
      this.badge.icon,
      this.badge.iconUrl ? this.badge.iconUrl : `assets/icons/${this.badge.icon}.svg`,
    );
  }

  badgeClick(): void {
    this.raiseEvent(this.eventName, this.badge, false);
  }

  raiseEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.clickedBadge.emit(eventToRaise);
    }
  }
}
