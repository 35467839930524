import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-fab-button',
  templateUrl: './fab-button.component.html',
  styleUrls: ['./fab-button.component.scss'],
})
export class FabButtonComponent extends FieldType implements OnInit {
  @Output() raiseEvent = new EventEmitter<UiEventRaised>();
  @Input() isFormlyField = true;
  @Input() color = '';
  @Input() position: 'bottom-end' | 'top-end' = 'bottom-end';
  @Input() icon = '';
  @Input() eventName = '';
  @Input() disabled = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.position = this.to.position || this.position;
      this.icon = this.to.icon || this.icon;
      this.color = this.to.color || this.color;
      this.disabled = this.to.disabled || this.disabled;
      this.eventName = this.to.eventName || this.eventName;
    }
  }

  buttonClicked(): void {
    const eventToRaise: UiEventRaised = {
      eventName: this.eventName,
      isNavigation: false,
      data: {},
    };
    if (!this.isFormlyField) {
      this.raiseEvent.emit(eventToRaise);
    } else {
      this.to.getFieldEvent(eventToRaise);
    }
  }
}
