<ngx-charts-area-chart
  [view]="view"
  [scheme]="colorScheme"
  [schemeType]="schemeType"
  [legend]="legend?.visible"
  [animations]="enableAnimations"
  [legendTitle]="legend?.title || 'Legend'"
  [legendPosition]="legend?.position || 'right'"
  [showGridLines]="showGridLines"
  [xAxis]="axis?.showXAxis"
  [yAxis]="axis?.showYAxis"
  [xAxisLabel]="axis?.xAxisLabel"
  [yAxisLabel]="axis?.yAxisLabel"
  [showXAxisLabel]="axis?.xAxisLabel"
  [showYAxisLabel]="axis?.yAxisLabel"
  [curve]="curve"
  [timeline]="timeline"
  [results]="data"
  [tooltipDisabled]="tooltipDisabled"
  (select)="onSelect($event)"
>
</ngx-charts-area-chart>
