<input
  class="abs-file-input"
  type="file"
  [accept]="extensions"
  (change)="onChange(input)"
  (click)="input.value = ''"
  [attr.multiple]="multiple ? '' : null"
  #input
/>
<button title="Attach files" mat-fab *ngIf="iconName; else buttonTemplate" (click)="input.click()">
  <mat-icon>{{ iconName }}</mat-icon>
</button>
<ng-template #buttonTemplate>
  <button>{{ label }}</button>
</ng-template>
