<div class="wrapper">
  <webcam
    [height]="height"
    [width]="width"
    [trigger]="triggerObservable"
    (imageCapture)="handleImage($event)"
    *ngIf="showWebcam"
    [allowCameraSwitch]="allowCameraSwitch"
    [switchCamera]="nextWebcamObservable"
    [imageQuality]="1"
    (cameraSwitched)="cameraWasSwitched($event)"
    (initError)="handleInitError($event)"
  ></webcam>
  <div class="snapshot" *ngIf="webcamImage && !showWebcam">
    <img class="preview" [src]="webcamImage.imageAsDataUrl" />
  </div>
</div>
<div class="controls row">
  <button mat-fab color="primary" *ngIf="showWebcam" (click)="triggerSnapshot()">
    <mat-icon>camera</mat-icon>
  </button>

  <button mat-fab color="primary" *ngIf="!showWebcam" (click)="toggleWebcam()">
    <mat-icon>restart_alt</mat-icon>
  </button>

  <button mat-fab color="primary" (click)="close()" type="button">
    <mat-icon>{{ webcamImage && !showWebcam ? 'check_circle_outline' : 'close' }}</mat-icon>
  </button>
</div>
