<div class="filter-bar-container">
  <mat-chip-list aria-label="Select filters">
    <div class="scrollable">
      <mat-chip
        class="filter-chip"
        *ngFor="let filter of data"
        [selectable]="selectable"
        [removable]="removable || (filteredData[filter.property] && filteredData[filter.property].length)"
        (removed)="removeFilter(filter)"
        (click)="selectFilter(filter)"
      >
        <mat-icon class="chip-icon" *ngIf="filter.icon">{{ filter.icon }}</mat-icon>
        <span class="filter-name">{{ filter.name }} </span>
        <mat-icon
          matChipRemove
          *ngIf="removable || (filteredData[filter.property] && filteredData[filter.property].length)"
          class="cancel-chip"
          >cancel</mat-icon
        >
      </mat-chip>
    </div>
  </mat-chip-list>
</div>

<div *ngIf="selectedFilterValue?.hideExpression">
  <cmx-simple-form-generator
    [formFields]="selectedFilterValue.form.fields"
    [formModel]="selectedFilterValue.form.model || {}"
    (raiseFormValue)="raiseFormValue($event)"
  ></cmx-simple-form-generator>
</div>
