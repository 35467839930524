import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { delay, map, startWith, tap } from 'rxjs/operators';
import { AudioRecorderComponent } from '../audio-recorder/audio-recorder.component';
import { CommentsService } from '../services/comments.service';
import { Comment } from '../shared-feature-comments.model';
import { UploaderViewComponent } from './../uploader/uploader-view/uploader-view.component';
import { UploaderComponent } from './../uploader/uploader.component';
import {
  defaultLanguage,
  SpeechError,
  SpeechEvent,
  SpeechNotification,
  SpeechRecognitionService
} from './speech-recognition.service';

@Component({
  selector: 'cmx-comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.scss'],
  providers: [SpeechRecognitionService],
})
export class CommentInputComponent implements OnInit, AfterViewInit {
  @Input() comment: Comment;
  @Input() placeholderText: string;
  @Output() doneEditing = new EventEmitter<Comment>();
  files: File[] = [];
  @ViewChild('commentInput') inputRef: ElementRef<HTMLInputElement>;
  @ViewChild('uploader') uploader: UploaderComponent;
  @ViewChild('recorder') recorder: AudioRecorderComponent;
  @ViewChild('viewer') viewer: UploaderViewComponent;
  @Input() editing = false;

  @Input() replyTo = '';
  totalTranscript?: string;
  transcript$?: Observable<string>;
  listening$?: Observable<boolean>;
  errorMessage$?: Observable<string>;
  supported: boolean;
  currentLanguage: string = defaultLanguage;
  inputFocused = false;
  defaultError$ = new Subject<string | undefined>();
  constructor(private commentsService: CommentsService, public speechRecognizer: SpeechRecognitionService) {}

  ngAfterViewInit(): void {
    if (this.comment?.message && this.editing) {
      this.inputRef.nativeElement.value = this.comment.message;
      this.inputRef.nativeElement.select();
    }

    if (this.replyTo) {
      this.commentsService.replyTo.pipe(delay(100)).subscribe(reply => {
        this.inputRef.nativeElement.focus();
        this.inputRef.nativeElement.value = reply + ' ';
      });
    }
  }

  ngOnInit(): void {
    const webSpeechReady = this.speechRecognizer.initialize(this.currentLanguage);

    this.supported = webSpeechReady;
    if (webSpeechReady) {
      this.initRecognition();
    }
  }

  private initRecognition(): void {
    this.transcript$ = this.speechRecognizer.onResult().pipe(
      tap(notification => {
        this.processNotification(notification);
      }),
      map(notification => (notification as any).content || ''),
      startWith(''),
    );

    this.listening$ = merge(this.speechRecognizer.onStart(), this.speechRecognizer.onEnd()).pipe(
      map(notification => (notification as any).event === SpeechEvent.Start),
    );

    this.errorMessage$ = merge(this.speechRecognizer.onError(), this.defaultError$).pipe(
      map(data => {
        if (data === undefined) {
          return '';
        }
        if (typeof data === 'string') {
          return data;
        }
        let message;
        switch ((data as any).error) {
          case SpeechError.NotAllowed:
            message = `Cannot run the demo.
            Your browser is not authorized to access your microphone.
            Verify that your browser has access to your microphone and try again.`;
            break;
          case SpeechError.NoSpeech:
            message = `No speech has been detected. Please try again.`;
            break;
          case SpeechError.AudioCapture:
            message = `Microphone is not available. Plese verify the connection of your microphone and try again.`;
            break;
          default:
            message = '';
            break;
        }
        return message;
      }),
    );
  }

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  start(): void {
    if (this.speechRecognizer.isListening) {
      this.totalTranscript = '';
      this.stop();
      this.inputRef.nativeElement.focus();
      return;
    }

    this.defaultError$.next(undefined);
    this.speechRecognizer.start();
  }

  stop(): void {
    this.speechRecognizer.stop();
    this.inputRef.nativeElement.focus();
  }

  private processNotification(notification: SpeechNotification<string>): void {
    if (notification.event === SpeechEvent.FinalContent) {
      const message = notification.content?.trim() || '';
      this.totalTranscript = this.totalTranscript ? `${this.totalTranscript}\n${message}` : notification.content;
    }
  }

  onEnterPressed(event) {
    this.sendComment(event.target.value);
    event.target.value = null;
  }

  public sendComment(message: string) {
    if (message) {
      const milliseconds = Date.now();
      const comment =
        this.comment ||
        ({
          message,
          createdAt: milliseconds,
          files: [...this.viewer.files],
        } as Comment);
      this.viewer.clear();

      this.doneEditing.emit({ ...comment, message });
      this.files.splice(0, this.files.length);
    }
  }

  uploaderChanged(files: File[]) {
    this.files = this.mergeArrayNoDuplicates([...this.files, ...files]);
  }

  recorderFilesChanged(files: File[]) {
    this.files = this.mergeArrayNoDuplicates([...this.files, ...files]);
  }

  mergeArrayNoDuplicates(array) {
    return [...new Set([].concat(...array))];
  }

  fileRemoved(file: File) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
      this.uploader.removeFile(file);
      this.recorder.removeFile(file);
    }
  }
}
