import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleFormGeneratorComponent } from './simple-form-generator.component';

import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    FormlyModule.forRoot({
      extras: { lazyRender: true },
      types: [],
      validationMessages: [{ name: 'required', message: 'This field is required' }],
    }),
    FormlyMaterialModule,
  ],
  declarations: [SimpleFormGeneratorComponent],
  exports: [SimpleFormGeneratorComponent],
})
export class SimpleFormGeneratorModule {}
