import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom, Subject } from 'rxjs';
import { DialogData, ModalSimpleFormGeneratorComponent } from '..';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public formEvent = new Subject<string>();
  constructor(private dialog: MatDialog) {}

  openModal(data: DialogData, height = '200px', width = null, panelClass = '') {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ModalSimpleFormGeneratorComponent, {
        data: data,
        height: height,
        width: width || '600px',
        hasBackdrop: true,
        disableClose: true,
        panelClass: panelClass,
      });

      lastValueFrom(dialogRef.afterClosed()).then(result => {
        console.log(result);
        if (result) {
          resolve(result);
        } else {
          result = { eventName: 'CLOSE', data: null, isNavigation: false };
          this.formEvent.next(result);
          resolve(result);
        }
      });
    });
  }
}
