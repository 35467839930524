import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { UiEventRaised, AppDetail, NavigationsItems } from '@cmx/shared/feature/platform-configuration';
import { AuthenticationService } from '@cmx/shared/feature/authentication';
import { Router } from '@angular/router';
import { SideNavService } from './sidenav.service';
import { APP_VERSION } from '@cmx/shared/util/environment-config';

@Component({
  selector: 'cmx-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  statusItems: { label: string; value: string }[] = [
    { label: 'Online', value: 'online' },
    { label: 'Offline', value: 'offline' },
    { label: 'Busy', value: 'busy' },
  ];

  defaultUserAvatar = `https://firebasestorage.googleapis.com/v0/b/prodeo-admin-app.appspot.com/o/icons%2Fcargo%2FCM_profile_icon.svg?alt=media&token=441f2687-35ca-4007-b471-b98873a95d1f`;

  @ViewChild('drawer', { static: false }) drawer: MatDrawer;

  @Input() hasBackdrop = true;
  @Input() isOpened = false;
  @Input() logoutText = 'Logout';
  @Input() logoutButtonColor = 'warn';
  @Input() mode: MatDrawerMode = 'over' || 'side' || 'push';
  @Input() position: MatDrawer['position'] = 'start' || 'end';

  @Input() app: AppDetail = {
    version: '',
    logo:
      'https://firebasestorage.googleapis.com/v0/b/prodeo-admin-app.appspot.com/o/icons%2Flogos%2Fprodeo-full-logo.svg?alt=media&token=3e11dbfb-91bc-4f1d-9f6a-6b6a480df666',
  };
  @Input() menuItems: NavigationsItems[] = [];
  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  get userStatus(): { label: string; value: string } {
    return (
      this.currentUser &&
      this.currentUser.status &&
      this.statusItems.find(item => item.value == this.currentUser?.status)
    );
  }

  get currentUser() {
    return this.authService.currentUser || null;
  }

  constructor(
    private router: Router,
    private menuService: SideNavService,
    private authService: AuthenticationService,
    @Inject(APP_VERSION) private appVersion: string,
  ) {
    this.app.version = this.appVersion;
  }

  ngOnInit(): void {
    this.menuItems = this.menuService.getMenuItems();
  }

  toggle() {
    this.drawer.toggle();
  }

  changeStatus() {
    const eventToRaise: UiEventRaised = {
      eventName: 'change-user-status',
      isNavigation: false,
      data: this.currentUser,
    };
    this.raiseEvent.emit(eventToRaise);
  }

  navigate(item) {
    this.router.navigateByUrl(item.url);
    this.toggle();
  }

  logout() {
    this.authService.signOutUser(true);
  }
}
