import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { Dimensions, SeriesData } from '../charts.model';
import { getRandomColor } from '../charts.utils';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-grid-pie-chart',
  templateUrl: './grid-pie-chart.component.html',
  styleUrls: ['./grid-pie-chart.component.scss'],
})
export class GridPieChartComponent extends FieldType implements OnInit {
  @Input() data: SeriesData[] = [];
  @Input() dimensions: Dimensions = { width: '', height: '' };
  @Input() allowResize = false;
  @Input() label = 'Total';
  @Input() tooltipDisabled = false;
  @Input() enableAnimations = true;
  @Input() itemColor: string[] = [];
  @Input() isFormlyField = true;

  @Output() raisePieChartEvent = new EventEmitter<UiEventRaised>();

  colorScheme: { domain: string[] } = { domain: [] };
  view: [number, number] = [500, 300];

  constructor(private element: ElementRef) {
    super();
  }

  ngOnInit() {
    if (this.isFormlyField) {
      this.data = this.to.data || this.data;
      this.allowResize = this.to.allowResize || this.allowResize;

      this.dimensions = this.to.dimensions || this.dimensions;
      this.label = this.to.label || this.label;
      this.enableAnimations = this.to.enableAnimations || this.enableAnimations;
      this.tooltipDisabled = this.to.tooltipDisabled || this.tooltipDisabled;
      this.itemColor = this.to.itemColor || this.itemColor;
    }
    this.resize(this.dimensions.width, this.dimensions.height);
    this.applyColors();
  }

  applyColors() {
    if (this.itemColor && !this.itemColor.length) {
      this.data.forEach(() => {
        this.colorScheme.domain.push(getRandomColor());
      });
    } else this.colorScheme.domain = this.itemColor;
  }

  onSelect(data: unknown): void {
    this.raiseEvent('grid-pie-chart-clicked', data, false);
  }

  raiseEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raisePieChartEvent.emit(eventToRaise);
    }
  }
  @HostListener('window:resize')
  onWindowResize() {
    if (this.allowResize) if (this.allowResize) this.resize();
  }

  resize(width?: string | number, height?: string | number, timeout = 0) {
    setTimeout(() => {
      const parentElement = this.element.nativeElement.parentElement.parentElement;
      if (parentElement) {
        this.view = [+(width || parentElement.offsetWidth - 30), +(height || parentElement.offsetHeight - 60)];
      }
    }, timeout);
  }
}
