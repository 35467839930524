import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { AppVersionLoaderService, AppVersionService } from '@cmx/shared/feature/app-version-loader';
import { HeaderConfiguration } from '@cmx/shared/feature/platform-configuration';
import { PushNotificationService } from '@cmx/shared/feature/push-notifications';
import { CoreMachine } from '@cmx/state-machines/core';
import { first } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { SyncManagerService } from '@cmx/shared/feature/sync-manager';
import { Auth, onAuthStateChanged, Unsubscribe } from '@angular/fire/auth';
import { FirestoreService } from '@cmx/shared/feature/firestore';
import { Subscription } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { inspect } from '@xstate/inspect';
@Component({
  selector: 'cmx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  headerConfiguration: HeaderConfiguration;
  dbReady = false;
  @ViewChild('nav') nav: MatSidenav;
  authSubscriber: Unsubscribe;
  routerSubscriber: Subscription;
  menuSubscriber: Subscription;
  appVersionSubscriber: Subscription;
  appUpdateSubscriber: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private appVersionService: AppVersionService,
    private appVersionLoaderService: AppVersionLoaderService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly coreMachineRef: CoreMachine,
    private syncManagerService: SyncManagerService,
    private readonly auth: Auth,
    private readonly firestore: FirestoreService,
    private readonly swUpdate: SwUpdate,
  ) {
    if (this.swUpdate.available) {
      this.appUpdateSubscriber = this.swUpdate.available.subscribe(() => {
        if (confirm('A new version is available, click to download it.')) window.location.reload();
      });
    }

    if (!environment.production) {
      inspect({
        // options
        // url: 'https://statecharts.io/inspect', // (default)
        iframe: false, // open in new window
      });
    }
  }

  ngOnInit() {
    this.coreMachineRef.isTaskCurrentlyActive$.next(false);
    window.addEventListener('load', function () {
      window.history.pushState({}, '');
    });

    this.routerSubscriber = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const header: any = this.getTitle(this.activatedRoute.snapshot.root);
        this.titleService.setTitle(header?.title);
        this.headerConfiguration = header;
      }
    });

    this.appVersionSubscriber = this.appVersionService.versionMissMatch$.pipe(first()).subscribe(versionMissMatch => {
      if (versionMissMatch) {
        this.appVersionLoaderService.showLoader();
      }
    });

    this.menuSubscriber = this.coreMachineRef.toggleMenu$.subscribe(ev => this.nav?.toggle());
    this.authSubscriber = onAuthStateChanged(this.auth, async user => {
      if (user) {
        this.syncManagerService.startListening(user.uid);
        this.firestore.loadWorkflowIcons();
        this.pushNotificationService.initializeFirebaseMessaging(environment.vapidKey);
        const currentUser = await this.firestore.getDocumentById(user.uid);
        this.coreMachineRef.initialWorkflowData = await this.firestore.loadInitialWorkflowData(currentUser);
      }
    });
  }

  private getTitle(routeSnapshot: ActivatedRouteSnapshot, header = {}) {
    header = routeSnapshot.data ? routeSnapshot.data : {};
    if (routeSnapshot.firstChild) {
      header = this.getTitle(routeSnapshot.firstChild, header);
    }
    return header;
  }

  handleEvent(e) {
    console.log(e);
  }

  ngOnDestroy(): void {
    this.authSubscriber();
    this.routerSubscriber?.unsubscribe();
    this.menuSubscriber?.unsubscribe();
    this.appVersionSubscriber?.unsubscribe();
    this.appUpdateSubscriber?.unsubscribe();
  }
}
