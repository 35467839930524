import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { UserAuthenticatedGuard } from '@cmx/shared/feature/guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'workflow',
  },
  {
    path: 'workflow',
    loadChildren: () => import('@cmx/shared/feature/workflow-list').then(m => m.WorkflowListModule),
    canLoad: [UserAuthenticatedGuard],
    data: { title: 'Workflow List', visible: false },
  },
  {
    path: 'reports',
    loadChildren: () => import('@cmx/prodeo/feature/reports').then(m => m.ReportsModule),
    canLoad: [UserAuthenticatedGuard],
    data: { title: 'Reports', visible: false },
  },
  {
    path: 'login',
    loadChildren: () => import('@cmx/shared/feature/authentication').then(m => m.SharedFeatureAuthenticationModule),
  },
  {
    path: 'password-reset',
    loadChildren: () => import('@cmx/shared/feature/authentication').then(m => m.SharedFeatureAuthenticationModule),
  },
  {
    path: 'reset/:id',
    loadChildren: () => import('@cmx/shared/feature/authentication').then(m => m.SharedFeatureAuthenticationModule),
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
