import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  toggleVideoRecorder$ = new BehaviorSubject<string>('');

  toggleVideoRecorder(videoRecorderState: string) {
    this.toggleVideoRecorder$.next(videoRecorderState);
  }
}
