import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';

@Component({
  selector: 'cmx-simple-form-generator',
  templateUrl: './simple-form-generator.component.html',
  styleUrls: ['./simple-form-generator.component.scss'],
})
export class SimpleFormGeneratorComponent {
  @Input() titleReq = false;
  @Input() clrBtnReq = true;
  @Input() submitBtnReq = true;
  @Input() formTitle = 'Test Form';
  @Input() submitButtoncolor = '';
  @Input() clearButtoncolor = '';
  @Input() formModel: unknown = {};
  @Input() formFields: FormlyFieldConfig[] = [];
  customForm: FormGroup = new FormGroup({});
  @Output() raiseFormValue = new EventEmitter<UiEventRaised>();

  onSubmit(formToSubmit: FormGroup): void {
    const { value } = formToSubmit;
    this.raiseFormValue.emit({ eventName: 'form-value', data: formToSubmit });
  }

  onResetForm(): void {
    setTimeout(() => {
      console.log('Model: ', this.formModel);
    }, 100);
  }
}
