<mat-card [ngClass]="data?.cardContent?.cardColor" class="parent-card">
  <div class="card">
    <img
      class="card-image"
      *ngIf="showLeftIcon && data?.cardContent?.imageSrc"
      [src]="data?.cardContent?.imageSrc"
      alt="Image for {{ (data && data?.cardContent?.imageSrc) || data?.cardContent?.displayName }}"
    />
    <mat-icon class="card-icon" *ngIf="showLeftIcon && data?.cardContent?.icon" [color]="leftIconColor">
      {{ data?.cardContent?.icon }}
    </mat-icon>
    <mat-icon
      class="card-icon"
      *ngIf="showLeftIcon && data?.cardContent?.svgIcon"
      [color]="leftIconColor"
      [svgIcon]="data?.cardContent?.svgIcon"
    >
    </mat-icon>

    <div class="card-content" *ngIf="data?.cardContent" (click)="cardClicked()">
      <span class="card-key">
        <span *ngIf="data?.cardContent?.displayKey">:</span>
      </span>
      <span class="word-break">{{ data?.cardContent?.displayName }}</span>
    </div>

    <mat-chip-list class="card-badge" *ngIf="showBadge" aria-label="Task Counter">
      <mat-chip color="primary" selected>{{ data?.cardContent?.count || 0 }}</mat-chip>
    </mat-chip-list>

    <button
      class="close-icon"
      mat-button
      *ngIf="showRightIcon && data?.cardContent?.rightIcon"
      [color]="rightIconColor"
      (click)="rightIconClicked()"
      type="button"
    >
      <mat-icon *ngIf="showRightIcon">
        {{
          this.enableCollapse ? (this.childProperties ? 'expand_less' : 'expand_more') : data?.cardContent?.rightIcon
        }}</mat-icon
      >
    </button>
  </div>
  <ng-container *ngIf="childProperties && data?.cardContent?.childCard">
    <mat-card *ngFor="let item of data.cardContent.childCard" [ngClass]="item?.cardColor">
      <div class="card">
        <mat-icon class="card-icon" *ngIf="showLeftIcon && item?.icon" [color]="leftIconColor">
          {{ item?.icon }}
        </mat-icon>
        <mat-icon
          class="card-icon"
          *ngIf="showLeftIcon && item?.svgIcon"
          [color]="leftIconColor"
          [svgIcon]="item?.svgIcon"
        >
        </mat-icon>
        <div class="card-content">
          {{ item?.displayName }}
        </div>
        <cmx-counter
          [itemCount]="item?.count?.value"
          [maxCount]="item?.count?.maxCount"
          [restrictKeys]="restrictKeys"
          [isFormlyField]="false"
          (raiseEvent)="raiseCouterEvent($event, item)"
        ></cmx-counter>
      </div>
      <mat-form-field *ngIf="allowCommenting" class="full-width bg-white">
        <mat-label>Leave a comment</mat-label>
        <textarea matInput [(ngModel)]="item.comment.value" (ngModelChange)="commentChange($event, item)"></textarea>
      </mat-form-field>
    </mat-card>
  </ng-container>
</mat-card>
