import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorAlertComponent } from './error-alert/error-alert.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(public dialog: MatDialog) {}

  openSimpleErrorAlert(
    errorAlertTitle: string | null,
    errorAlertContent: string,
    errorBodyClass?: string,
  ): MatDialogRef<ErrorAlertComponent, any> {
    return this.dialog.open(ErrorAlertComponent, {
      data: { errorAlertTitle, errorAlertContent, errorBodyClass },
    });
  }
}
