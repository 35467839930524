import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsComponent } from './chips.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [CommonModule, MatChipsModule, MatBadgeModule, MatTooltipModule, MatIconModule],
  declarations: [ChipsComponent],
  exports: [ChipsComponent],
})
export class ChipsModule {}
