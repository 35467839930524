import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppVersionLoaderComponent } from './app-version-loader.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressBarModule, MatDialogModule],
  declarations: [AppVersionLoaderComponent],
  exports: [AppVersionLoaderComponent],
})
export class AppVersionLoaderModule {}
