import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UiEventRaised, File } from '@cmx/shared/feature/platform-configuration';

@Component({
  selector: 'cmx-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent extends FieldType implements OnInit {
  @Input() files: File[] = [];
  @Input() multiple = false;
  @Input() fileTypes: string[] = [];
  @Input() isFormlyField = true;
  allowedTypes = '';
  @Input() uploadButtonColor = '';
  @Input() deleteIconColor = '';
  @Input() onlyUploadButton = true;
  @Input() label = '';
  @Input() icon = '';
  @Input() buttonType = '';
  @Input() fileAddedEventName = 'file-added';
  @Input() removeFileEventName = 'remove-file';

  @Output() fileUploadEvent = new EventEmitter<UiEventRaised>();

  ngOnInit() {
    if (this.isFormlyField) {
      this.label = this.to.label || this.label;
      this.icon = this.to.icon || this.icon;
      this.fileTypes = this.to.fileTypes || this.fileTypes;
      this.multiple = this.to.multiple || this.multiple;
      this.uploadButtonColor = this.to.uploadButtonColor || this.uploadButtonColor;
      this.deleteIconColor = this.to.deleteIconColor || this.deleteIconColor;
      this.fileAddedEventName = this.to.fileAddedEventName || this.fileAddedEventName;
      this.removeFileEventName = this.to.removeFileEventName || this.removeFileEventName;
      this.buttonType = this.to.buttonType || this.buttonType;
    }
    this.allowedTypes = this.fileTypes?.toString();
  }

  onFileChange(event: any) {
    if (event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async e => {
      this.raiseFileUploadEvent({
        eventName: this.fileAddedEventName,
        data: {
          src: reader.result,
          displayName: file.name,
          type: file.type,
        },
        isNavigation: false,
      });
      this.files.push({
        src: reader.result,
        displayName: file.name,
        type: file.type,
      });
    };
  }

  getSource(fileObj: File) {
    if (!fileObj) return '';
    if (fileObj.type.match(/image\/*/)) {
      return fileObj.src;
    } else if (fileObj.type.match(/pdf\/*/)) {
      return 'assets/icon/pdf.png';
    } else if (fileObj.type.match(/mp4\/*/) || fileObj.type.match(/video\/*/)) {
      return 'assets/icon/video.png';
    } else {
      return 'assets/icon/file.png';
    }
  }

  raiseFileUploadEvent(eventToRaise: UiEventRaised): void {
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.fileUploadEvent.emit(eventToRaise);
    }
  }

  deleteFile(file: File, index: number) {
    this.files.splice(index, 1);
    this.raiseFileUploadEvent({
      eventName: this.removeFileEventName,
      data: file,
    });
  }
}
