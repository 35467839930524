<ng-container>
  <label>{{ signaturePadConfiguration?.label }} </label>
  <button
    class="right-align"
    *ngIf="signaturePadConfiguration?.showClearButton"
    mat-raised-button
    (click)="clearDraw()"
    type="button"
    [color]="signaturePadConfiguration?.clearButtonColor"
  >
    {{ signaturePadConfiguration?.clearButtonText || 'Clear' }}
  </button>
  <div>
    <signature-pad
      id="signature-pad-canvas"
      #signaturePad
      [options]="signaturePadOptions"
      (onEndEvent)="drawComplete()"
    ></signature-pad>
  </div>
  <div class="button-align">
    <button
      *ngIf="signaturePadConfiguration?.showConfirmButton"
      mat-raised-button
      (click)="submitDraw()"
      type="button"
      [color]="signaturePadConfiguration?.confirmButtonColor"
    >
      {{ signaturePadConfiguration?.confirmButtonText || 'Confirm' }}
    </button>
  </div>
</ng-container>
