import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { BehaviorSubject, Observable } from 'rxjs';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import 'videojs-record/dist/videojs.record.js';
import 'videojs-wavesurfer/dist/videojs.wavesurfer.js';
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
import 'webrtc-adapter';

WaveSurfer.microphone = MicrophonePlugin;

@Component({
  selector: 'cmx-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss'],
})
export class AudioRecorderComponent implements OnDestroy, AfterViewInit {
  @ViewChild('myAudio') audioElement: ElementRef;

  private filesSubject$ = new BehaviorSubject([]);

  public files$: Observable<File[]> = this.filesSubject$.asObservable();
  // index to create unique ID for component
  idx = 'clip1';
  @Input() identifier: number;

  @Output() filesChanged = new EventEmitter<File[]>();
  @Output() fileRemoved = new EventEmitter<File[]>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private player: any;
  public deviceIsReady = false;
  public recording = false;
  uploadUrl = '';
  files: File[] = [];
  constructor(private ngZone: NgZone) {}

  ngAfterViewInit() {
    const config: VideoJsPlayerOptions = {
      controls: false,
      width: 100,
      height: 40,
      fluid: false,
      plugins: {
        wavesurfer: {
          backend: 'WebAudio',
          backgroundColor: '#9fd6ba',
          waveColor: '#36393b',
          progressColor: 'black',
          debug: true,
          cursorWidth: 1,
          displayMilliseconds: true,
          hideScrollbar: true,
          plugins: [
            // enable microphone plugin
            WaveSurfer.microphone.create({
              bufferSize: 4096,
              numberOfInputChannels: 1,
              numberOfOutputChannels: 1,
              constraints: {
                video: false,
                audio: true,
              },
            }),
          ],
        },
        record: {
          maxLength: 60 * 60,
          audio: true,
          video: false,
          debug: false,
        },
      },
    };

    const el = `myAudio${this.identifier ? this.identifier : 'NewComment'}`;

    this.player = videojs(el, config, () => {
      const msg =
        'Using video.js ' +
        videojs.VERSION +
        ' with videojs-record ' +
        ', videojs-wavesurfer ' +
        videojs.getPluginVersion('wavesurfer') +
        ', wavesurfer.js ' +
        WaveSurfer.VERSION +
        videojs.getPluginVersion('record') +
        ' and recordrtc ' +
        RecordRTC.version;
      videojs.log(msg);
    });

    this.player.on('deviceReady', () => {
      this.deviceIsReady = true;
    });

    this.player.on('startRecord', () => {
      this.clearFiles();
      this.player.show();
      this.recording = true;
      this.deviceIsReady = true;
    });

    this.player.on('finishRecord', () => {
      const { recordedData } = this.player;
      this.ngZone.run(() => {
        this.files.push(this.blobToFile(this.player.recordedData, `recording ${recordedData.lastModified}.webm`));
        this.recording = false;
        this.filesChanged.emit(this.files);
      });
    });
  }

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  public removeFile(file: File): void {
    const index = this.files.indexOf(file);

    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  public clearFiles() {
    this.ngZone.run(() => {
      this.files.splice(0, this.files.length);
    });
  }

  getDeviceReady() {
    this.player.record().getDevice();
  }

  stopDevice() {
    this.player.record().stopDevice();
  }

  async startRecording() {
    if (!this.deviceIsReady) await this.getDeviceReady();
    setTimeout(() => {
      this.player.record().start();
    }, 100);
  }

  stopRecording() {
    this.player.record().stop();
  }

  pauseRecording() {
    this.player.record().pause();
  }

  resumeRecording() {
    this.player.record().resume();
  }

  play() {
    if (this.player.wavesurfer().waveReady) {
      this.player.wavesurfer().play();
    }
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
      this.player = false;
    }
  }
}
