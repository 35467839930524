export const environment = {
  production: true,
  httpSettings: {
    apiUrl: 'https://prodeo-dev-exp-beta.prodeo-live.com', // https://prodeo-dev-exp-beta.prodeo-live.com
    bucketName: 'cmx_dev',
    uiBaseUrl: 'https://cargomatrix-dev.prodeo-live.com',
    syncGatewayUrl: 'https://prodeo-dev-sync.prodeo-live.com:4984/cargomatrix_dev?ngsw-bypass=true',
    cookieSetUrl: 'prodeo-live.com',
  },
  appVersion: '20220407.1',
  headerColor: 'accent',
  vapidKey: `BOJY206izhFP7eIzELTLVfVtiIaLr4nqr2s1wIUMUJId-gVrfM9YeP_8nNyDGsFOYIwlwRZKfby-6oNZ6NsJ08s`,
  firebase: {
    apiKey: 'AIzaSyA76clHuoZDqsQbuuI3f966zNwpfKH82h4',
    authDomain: 'prodeo-dev-252a8.firebaseapp.com',
    projectId: 'prodeo-dev-252a8',
    storageBucket: `bucket-cmx_dev`,
    messagingSenderId: '505514043597',
    appId: '1:505514043597:web:b40e6a355e8a613a056184',
    measurementId: 'G-8Y8RLPJSE1',
  },
  useEmulators: false,
};
