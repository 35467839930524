<mat-toolbar [color]="headerColor">
  <button
    mat-icon-button
    (click)="raiseEvent(headerConfiguration?.leftIcon?.event)"
    *ngIf="headerConfiguration?.leftIcon?.visible"
    class="header-icon menu-button"
    type="button"
    [color]="headerConfiguration?.leftIcon?.color"
  >
    <mat-icon>{{ headerConfiguration?.leftIcon?.icon }}</mat-icon>
  </button>

  <span class="title">{{ headerConfiguration?.title }}</span>
  <span class="spacer"></span>

  <ng-container *ngFor="let item of headerConfiguration?.rightIconList">
    <button
      mat-icon-button
      class="header-icon"
      (click)="raiseEvent(item?.event)"
      *ngIf="item?.showInBar"
      [color]="item?.color"
      type="button"
    >
      <mat-icon
        [matBadge]="item?.count"
        matBadgeColor="warn"
        title="{{ item?.icon }}"
        *ngIf="item?.icon == 'filter_alt'"
        >{{ item?.icon }}</mat-icon
      >
      <mat-icon title="{{ item?.icon }}" *ngIf="item?.icon != 'filter_alt'">{{ item?.icon }}</mat-icon>
    </button>
  </ng-container>

  <button mat-icon-button [matMenuTriggerFor]="menu" type="button" class="header-icon" *ngIf="showContextMenuButton">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let item of headerConfiguration?.rightIconList">
      <button mat-menu-item type="button" (click)="raiseEvent(item?.event)" *ngIf="!item.showInBar">
        <mat-icon>{{ item?.icon }}</mat-icon>
        <span>{{ item?.text }}</span>
      </button>
    </ng-container>
  </mat-menu>
</mat-toolbar>
