import { Inject, Injectable } from '@angular/core';
import { FileInfo } from '@cmx/shared/util/interfaces';
import { RXJSDBService } from '@cmx/shared/data-access/rxdb';
import { CollectionNames } from '@cmx/shared/util/interfaces';
import { AuthenticationService } from '@cmx/shared/feature/authentication';
import { FirestoreQuery, FirestoreService } from '@cmx/shared/feature/firestore';
import { CoreMachine } from '@cmx/state-machines/core';
import { FileUploaderMachineService, FileUploaderService } from '@cmx/state-machines/video-recorder';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { HttpServiceSettings } from '@cmx/shared/feature/platform-configuration';
import { HTTPSERVICESETTINGS } from '@cmx/shared/util/environment-config';
import { ApiAccessService } from '@cmx/prodeo/data-access/api-access';
import { SyncStatus } from '@cmx/shared/util/interfaces';
import { getItemFromLocalStorage } from '@cmx/shared/data-access/local-storage';

export interface UploadResult {
  data: string;
  message: string;
}

export interface UploadProgress {
  asset: any;
  progress: number;
}

export interface UploadStatus {
  asset: any;
  started?: number;
  completed?: number;
  requested?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AssetsManagerService {
  currentUploaderMachine: any = null;

  progress$ = new BehaviorSubject<UploadProgress>(null);
  started$ = new BehaviorSubject<UploadStatus>(null);
  completed$ = new BehaviorSubject<UploadStatus>(null);

  progressUploaderSubscription: Subscription;
  startedUploaderSubscription: Subscription;
  completedUploaderSubscription: Subscription;
  failedUploaderSubscription: Subscription;
  assetFileCreatedSubscription: Subscription;
  isTaskActiveSubscription: Subscription;

  requested$ = new BehaviorSubject<UploadStatus>(null);

  constructor(
    private rxdb: RXJSDBService,
    private firestore: FirestoreService,
    private uploaderMachine: FileUploaderMachineService,
    public coreMachineService: CoreMachine,
    private auth: AuthenticationService,
    private readonly apiService: ApiAccessService,
    @Inject(HTTPSERVICESETTINGS) private httpServiceSettings: HttpServiceSettings,
    private readonly fileUploader: FileUploaderService,
  ) {
    this.assetFileCreatedSubscription = this.rxdb.assetFileCreated$
      .pipe(filter(fileInfo => !!fileInfo))
      .subscribe(async (fileInfo: FileInfo) => {
        await this.uploadFile(fileInfo);
      });

    this.isTaskActiveSubscription = this.coreMachineService.isTaskCurrentlyActive$.subscribe(isActive => {
      if (!isActive) {
        const currentUser: any = getItemFromLocalStorage('loggedInUser');
        if (!currentUser) {
          return;
        }
        this.processAssets(currentUser.id);
      }
    });

    this.initListeners();
  }

  initListeners() {
    this.startedUploaderSubscription = this.uploaderMachine.started$
      .pipe(
        distinctUntilChanged(),
        filter(event => !!event),
        debounceTime(5),
        tap(async event => {
          const documentUpdated: any = await this.firestore.storeDocument({
            id: event.fileId,
            gcpSessionUri: event.sessionURL,
          });

          const masterTaskDocument = await this.firestore.getDocumentById(`${documentUpdated.taskId}`);

          const { transaction, milestone } = this.coreMachineService.createTransaction(
            { ...masterTaskDocument },
            'VID1.1',
            this.auth.currentUser.id,
          );

          const taskPropertiesToUpdate: any = {
            id: masterTaskDocument.id,
          };

          if (transaction) {
            taskPropertiesToUpdate.transactions = [...masterTaskDocument.transactions, transaction];
          }

          if (milestone) {
            taskPropertiesToUpdate.milestones = [...masterTaskDocument.milestones, milestone];
          }

          await this.firestore.storeDocument(taskPropertiesToUpdate);
        }),
      )
      .subscribe();

    this.progressUploaderSubscription = this.uploaderMachine.progress$
      .pipe(
        distinctUntilChanged(),
        filter(event => !!event),
        tap(async event => {
          await this.firestore.storeDocument({ id: event.fileId, uploadProgress: event.progress });
        }),
      )
      .subscribe();

    this.completedUploaderSubscription = this.uploaderMachine.completed$
      .pipe(
        distinctUntilChanged(),
        filter(event => !!event),
        debounceTime(5),
        tap(async event => {
          let documentUpdated: any = await this.firestore.getDocumentById(event.fileId);
          const url = event.fileURL;
          this.apiService.sendGetForImage(event.fileURL).subscribe();
          const completedVideoDocument = {
            id: event.fileId,
            src: url,
            uploadProgress: event.progress,
            syncStatus: SyncStatus.synced,
            completedTime: Date.now(),
          };

          documentUpdated = { ...documentUpdated, ...completedVideoDocument };

          const masterTaskDocument = await this.firestore.getDocumentById(`${documentUpdated.taskId}`);

          const taskPropertiesToUpdate: any = {
            id: masterTaskDocument.id,
          };

          let previousVideos: any[] = [];
          let previousImages: any[] = [];
          if (documentUpdated.type === 'video') {
            previousVideos = masterTaskDocument.videos ? [...masterTaskDocument.videos] : [];
            taskPropertiesToUpdate.videos = [...previousVideos, documentUpdated];

            const { transaction, milestone } = this.coreMachineService.createTransaction(
              { ...masterTaskDocument },
              'VID1.2',
              this.auth.currentUser.id,
            );

            if (transaction) {
              taskPropertiesToUpdate.transactions = [...masterTaskDocument.transactions, transaction];
            }

            if (milestone) {
              taskPropertiesToUpdate.milestones = [...masterTaskDocument.milestones, milestone];
            }
          } else if (documentUpdated.type === 'images') {
            previousImages = masterTaskDocument.images ? [...masterTaskDocument.images] : [];
            taskPropertiesToUpdate.images = [...previousImages, documentUpdated];
          } else if (documentUpdated.type === 'signatures') {
            taskPropertiesToUpdate.authorizedRepresentativeSignature = url;
          }

          await this.firestore.storeDocument(documentUpdated);

          try {
            await this.firestore.storeDocument(taskPropertiesToUpdate);
          } catch (error) {
            if (documentUpdated.type === 'video') {
              await this.firestore.storeDocument({
                id: masterTaskDocument.id,
                videos: [...previousVideos, documentUpdated],
              });
            } else if (documentUpdated.type === 'images') {
              await this.firestore.storeDocument({
                id: masterTaskDocument.id,
                images: [...previousImages, documentUpdated],
              });
            }

            await this.firestore.storeDocument({
              referenceDocId: `${masterTaskDocument.id}`,
              collectionName: 'log',
              error: JSON.stringify(error),
              ignoreSync: true,
              timestamp: Date.now(),
            });
          }

          const collectionName = `a${event.fileId.replace(/-/g, '_')}`;
          await this.rxdb.removeAttachmentCollection(collectionName);

          this.coreMachineService.events$.next({ type: 'upload-completed', payload: { fileId: event.fileId } });
          console.log('completed', event);
        }),
      )
      .subscribe();

    this.failedUploaderSubscription = this.uploaderMachine.error$
      .pipe(
        distinctUntilChanged(),
        filter(event => !!event),
        tap(async event => {
          console.log('error' + JSON.stringify(event));
        }),
      )
      .subscribe();
  }

  private async uploadFile(fileInfo: FileInfo) {
    if (fileInfo) {
      this.currentUploaderMachine = this.uploaderMachine.init({
        fileId: fileInfo.asset.id,
        file: fileInfo.file,
        bucketName: fileInfo.asset.storageBucketPath,
        fileName: fileInfo.asset.name,
        sessionURL: fileInfo.asset.gcpSessionUri,
        type: fileInfo.asset.type,
      });
    }
  }

  public async processAssets(userId: string) {
    const assets = await this.getIncompleteAssets(userId);
    const promises = assets?.map(async (asset: any) => {
      const result = await this.processAsset(asset);
      return result;
    });
    if (promises) {
      await Promise.all(promises);
    }
  }

  async clearLogs() {
    const query: FirestoreQuery[] = [
      {
        property: 'collectionName',
        operator: '==',
        value: CollectionNames.log,
      },
    ];

    const logs = await this.firestore.queryDocument(query);
    const promises = logs?.map(async (asset: any) => {
      const result = this.firestore.deleteDocumentById(asset.id);
      return result;
    });
    if (promises) {
      await Promise.all(promises);
    }
  }

  async getIncompleteAssets(userId: string) {
    const query: FirestoreQuery[] = [
      {
        property: 'collectionName',
        operator: '==',
        value: CollectionNames.assets,
      },
      {
        property: 'syncStatus',
        operator: '==',
        value: SyncStatus.default,
      },
      {
        property: 'user',
        operator: '==',
        value: userId,
      },
    ];

    const incompleteAssets = await this.firestore.queryDocument(query);
    return incompleteAssets;
  }

  private async processAsset(asset: any) {
    try {
      if (asset) {
        const collectionName = `a${asset.id.replace(/-/g, '_')}`;
        await this.rxdb.createAttachmentCollection(collectionName);
        const attachmentDocument = await this.rxdb.getDocumentById(asset.id, collectionName);
        const attachment = attachmentDocument?.getAttachment(asset.id);
        if (attachment) {
          const data = (await attachment.getData()) as Blob;
          this.uploadFile({ asset, file: data });
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  unsubscribeFromEvents() {
    this.progressUploaderSubscription.unsubscribe();
    this.startedUploaderSubscription.unsubscribe();
    this.completedUploaderSubscription.unsubscribe();
    this.failedUploaderSubscription.unsubscribe();
    this.assetFileCreatedSubscription.unsubscribe();
    this.isTaskActiveSubscription.unsubscribe();
    this.currentUploaderMachine = null;
  }
}
