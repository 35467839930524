import {
  curveBasis,
  curveBasisClosed,
  curveCardinal,
  curveCardinalClosed,
  curveCatmullRom,
  curveCatmullRomClosed,
  curveLinear,
  curveLinearClosed,
  curveMonotoneX,
  curveStep,
  curveMonotoneY,
  curveNatural,
  curveStepAfter,
  curveStepBefore,
} from 'd3-shape';

export const Curves: { [key: string]: unknown } = {
  basis: curveBasis,
  basisClosed: curveBasisClosed,
  linear: curveLinear,
  linearClosed: curveLinearClosed,
  monotoneX: curveMonotoneX,
  monotoneY: curveMonotoneY,
  natural: curveNatural,
  step: curveStep,
  stepAfter: curveStepAfter,
  stepBefore: curveStepBefore,
  default: curveLinear,
  cardinal: curveCardinal,
  cardinalClosed: curveCardinalClosed,
  catmullRom: curveCatmullRom,
  catmullRomClosed: curveCatmullRomClosed,
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
