import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardData, CardList, UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
})
export class CardListComponent extends FieldType implements OnInit {
  @Input() isFormlyField = true;
  @Input() showBadge = false;
  @Input() showRightIcon = false;
  @Input() showLeftIcon = false;
  @Input() enableChildList = false;
  @Input() clickEventName = '';
  @Input() cardDataList: CardData[] = [];

  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.showBadge = this.to.showBadge || this.showLeftIcon;
      this.showRightIcon = this.to.showRightIcon || this.showRightIcon;
      this.showLeftIcon = this.to.showLeftIcon || this.showLeftIcon;
      this.enableChildList = this.to.enableChildList || this.enableChildList;
      this.cardDataList = this.to.data && this.to.data.length ? this.to.data : this.cardDataList;
      this.clickEventName = this.to.clickEventName || this.clickEventName;
    }
  }

  cardListEvent(data: UiEventRaised) {
    if (this.isFormlyField) {
      this.to.getFieldEvent(data);
    } else {
      this.raiseEvent.emit(data);
    }
  }
}
