import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable, of, throwError } from 'rxjs';
import { getStringFromLocalStorage, deleteItemFromLocalStorage } from '@cmx/shared/data-access/local-storage';
import { ProdeoHttpResponse } from '@cmx/shared/feature/platform-configuration';
import { concatMap } from 'rxjs/operators';
import { ErrorHandlerService } from '@cmx/shared/feature/error-handler';

@Injectable({
  providedIn: 'root',
})
export class ApiAccessService {
  constructor(private readonly http: HttpClient, private errorHandlerService: ErrorHandlerService) {}

  getAuthHeaders(): HttpHeaders {
    return new HttpHeaders({
      Authorization: `${getStringFromLocalStorage('prodeoToken')}`,
    });
  }

  getSWParams(): HttpParams {
    let params = new HttpParams();
    params = params.append('ngsw-bypass', true);
    return params;
  }

  sendSecurePOST(url: string, body: unknown): Observable<ProdeoHttpResponse | undefined> {
    return this.http
      .post<ProdeoHttpResponse>(url, body, {
        headers: this.getAuthHeaders(),
        params: this.getSWParams(),
      })
      .pipe(
        concatMap(result => {
          if (!result.status) {
            if (result.httpCode === 401) {
              this.promptAndLogout();
            }
            return throwError(() => new Error(result.message || result.data));
          }

          console.log(result);
          return of(result);
        }),
      );
  }

  sendSecureGET(url: string): Observable<ProdeoHttpResponse> {
    return this.http
      .get<ProdeoHttpResponse>(url, {
        headers: this.getAuthHeaders(),
        params: this.getSWParams(),
      })
      .pipe(
        concatMap(result => {
          if (!result.status) {
            if (result.httpCode === 401) {
              this.promptAndLogout();
            }
            return throwError(() => new Error(result.message || result.data));
          }
          return of(result);
        }),
      );
  }

  async promptAndLogout() {
    const sessionExpiredAlert = this.errorHandlerService.openSimpleErrorAlert(
      ``,
      `Your session has expired, you need to log in again`,
    );
    await lastValueFrom(sessionExpiredAlert.afterClosed());

    deleteItemFromLocalStorage('loggedInUser');
    deleteItemFromLocalStorage('prodeoToken');
    deleteItemFromLocalStorage('tokenValidityDate');
    location.replace('login');
  }

  sendPOST(url: string, body: unknown): Observable<unknown> {
    return this.http.post(url, body);
  }

  sendGetForImage(url: string): Observable<any> {
    const httpOptions: any = {
      headers: new HttpHeaders({
        Accept: 'image/jpeg',
      }),
      responseType: 'blob',
    };
    return this.http.get<any>(url, httpOptions);
  }
}
