import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { GridColumns, GridDataSource } from './grid-datasource';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';

import { FieldType } from '@ngx-formly/core';
@Component({
  selector: 'cmx-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent extends FieldType implements AfterViewInit, OnInit {
  @Input() isFormlyField = true;

  @Input() allowSelection = false;
  @Input() allowMultiSelect = false;
  @Input() initialSelectedColumns = [];
  @Input() pageSize = 15;
  @Input() pageSizeOptions = [15, 25, 50, 100];
  @Input() sortBy = '';
  @Input() sortOrder: SortDirection = 'asc';
  @Input() noDataText = 'No data Found';
  @Input() columns: GridColumns[] = [];
  @Input() data: unknown[] = [];

  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<unknown>;

  dataSource!: GridDataSource;
  displayedHeaderColumns: string[] = [];
  selection!: SelectionModel<unknown>;

  ngOnInit() {
    if (this.isFormlyField) {
      this.allowSelection = this.to.allowSelection || this.allowSelection;
      this.allowMultiSelect = this.to.allowMultiSelect || this.allowMultiSelect;
      this.initialSelectedColumns = this.to.initialSelectedColumns || this.initialSelectedColumns || [];
      this.sortBy = this.to.sortBy || this.sortBy;
      this.sortOrder = this.to.sortOrder || this.sortOrder;
      this.pageSizeOptions =
        this.to.pageSizeOptions && this.to.pageSizeOptions.length ? this.to.pageSizeOptions : this.pageSizeOptions;
      this.pageSize = this.to.pageSize || this.pageSize;
      this.noDataText = this.to.noDataText || this.noDataText;
      this.columns = this.to.columns || this.columns;
      this.data = this.to.data || this.data || [];
    }
    this.initializeGrid();
  }

  initializeGrid() {
    this.displayedHeaderColumns = this.columns.map(column => column.dataField);
    if (this.allowSelection) this.displayedHeaderColumns.unshift('select');
    this.selection = new SelectionModel<unknown>(this.allowMultiSelect, this.initialSelectedColumns);
    this.dataSource = new GridDataSource(this.data);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: unknown, index: number = 0): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${index + 1}`;
  }

  selectionChange(row: unknown) {
    console.log(row);
    this.selection.toggle(row);
    this.raiseTabEvent(this.selection.selected, 'GRID_ROW_SELECTION-CHANGE');
  }

  onCellButtonClick(row: unknown, column: GridColumns) {
    console.log(row, column);
    this.raiseTabEvent({ row, column }, 'GRID_CELL_BUTTON-CLICKED');
  }

  onCellIconClick(row: unknown, column: GridColumns) {
    console.log(row, column);
    this.raiseTabEvent({ row, column }, 'GRID_CELL_ICON-CLICKED');
  }

  raiseTabEvent(data: unknown, eventName: string, isNavigation = false): void {
    const eventToRaise: UiEventRaised = {
      eventName,
      isNavigation,
      data,
    };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseEvent.emit(eventToRaise);
    }
  }
}
