<div fxFlexFill fxLayout="column" class="task-manager">
  <cmx-header
    [headerColor]="headerColor"
    class="sticky height-48px"
    [isFormlyField]="false"
    [headerConfiguration]="header"
    (raiseHeaderEvent)="handleEvent($event)"
  ></cmx-header>
  <div>
    <mat-button-toggle-group
      class="row"
      name="videStatus"
      [value]="selected"
      aria-label="video Status"
      (change)="toggleButton($event)"
    >
      <mat-button-toggle *ngFor="let item of buttonList" [value]="item.value" class="col">
        <mat-icon class="button-icon">{{ item?.icon }}</mat-icon>
        <span class="button-label">{{ item?.label }}</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div
    class="col-lg-12 col-md-12 col-sm-12 video-status-scroll padding-horizontal-1"
    *ngIf="selected === 'completed' && completedVideoList.length > 0"
  >
    <ng-container *ngFor="let video of completedVideoList">
      <cmx-card-widget [isFormlyField]="false" [data]="video" *ngIf="video"></cmx-card-widget>
    </ng-container>
  </div>

  <div
    class="col-lg-12 col-md-12 col-sm-12 video-status-scroll padding-horizontal-1"
    *ngIf="selected === 'inProgress' && inprogressVideoList.length > 0"
  >
    <ng-container *ngFor="let video of inprogressVideoList">
      <cmx-card-widget [isFormlyField]="false" [data]="video" *ngIf="video"></cmx-card-widget>
    </ng-container>
  </div>
  <p *ngIf="completedVideoList.length < 0 && inprogressVideoList.length < 0" class="margin-top-5 text-center">
    No data
  </p>
</div>
