import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { IconService } from '@cmx/shared/feature/icon-manager';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { stripRestrictedKeys } from '@cmx/shared/util/helper-functions';

@Component({
  selector: 'cmx-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss'],
})
export class BarcodeScannerComponent extends FieldType implements OnInit {
  formControl: FormControl;
  enableManualModeEventName = 'MANUAL_MODE_ENABLED';
  disableManualModeEventName = 'MANUAL_MODE_DISABLED';
  @Input() label = 'Scan Tracking Barcode';
  @Input() maxLength = 50;
  @Input() submitButtonColor = 'primary';
  @Input() backgroundScanner = false;
  @Input() isFormlyField = true;
  @Input() eventName = 'BARCODE_EVENT';
  @Input() restrictKeys: any[] = [];

  @ViewChild('barcodeInput') barcodeInput: ElementRef;
  @Output() scannedBarcode = new EventEmitter<UiEventRaised>();

  isBarcodeScannerDisabled = true;

  constructor(private iconService: IconService) {
    super();
  }

  ngOnInit() {
    if (this.isFormlyField) {
      this.label = this.to.label || this.label;
      this.maxLength = this.to.maxLength || this.maxLength;
      this.submitButtonColor = this.to.submitButtonColor || this.submitButtonColor;
      this.backgroundScanner = this.to.backgroundScanner || this.backgroundScanner;
      this.eventName = this.to.eventName || this.eventName;
      this.restrictKeys = this.to.restrictKeys || this.restrictKeys;
    }

    this.registerBarcodeIcons();
  }

  registerBarcodeIcons(): void {
    this.iconService.registerIcon('scan-barcode', 'assets/icons/scan-barcode.svg');
    this.iconService.registerIcon('touch-keypad', 'assets/icons/touch-keypad.svg');
    this.iconService.registerIcon('barcode', 'assets/icons/barcode.svg');
  }

  keypressEvent(event: any) {
    if (this.restrictKeys && this.restrictKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  toggleInput(enableInput: boolean) {
    if (enableInput) {
      this.isBarcodeScannerDisabled = false;
      this.raiseEvent(this.enableManualModeEventName, {}, false);
      setTimeout(() => {
        if (this.barcodeInput) {
          this.barcodeInput.nativeElement.focus();
        }
      }, 10);
    } else {
      this.raiseEvent(this.disableManualModeEventName, {}, false);
      this.isBarcodeScannerDisabled = true;
    }
  }

  raiseEvent(eventName: string, data: unknown, isNavigation: boolean): void {
    const eventToRaise: UiEventRaised = { eventName, data, isNavigation };
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.scannedBarcode.emit(eventToRaise);
    }
  }

  submitBarcode() {
    if (this.barcodeInput) {
      const barcode = this.barcodeInput.nativeElement.value;
      this.raiseEvent(
        this.eventName,
        { barcode: stripRestrictedKeys(barcode.trim(), this.restrictKeys), manual: true },
        false,
      );
    }
  }
}
