<div class="message-wrapper">
  <div class="message-container">
    <cmx-user-avatar [image]="comment.from?.picture" [readonly]="true"></cmx-user-avatar>
    <div class="bubble-container" *ngIf="!editing; else editingTemplate">
      <span class="name">{{ comment.from?.fullName }}</span
      ><span>{{ comment.message }}</span>
    </div>
  </div>

  <div class="actions-container">
    <span class="link-button" (click)="reply()">Reply</span>
    <span *ngIf="!editing" class="link-button" (click)="toggleEditing()">Edit</span>
    <span class="link-button" (click)="delete()">Delete</span>

    <span class="date-container">
      {{ comment.createdAt | date: 'medium' }}
    </span>
    <cmx-uploader-view #viewer [files]="comment.files" [readOnly]="true"></cmx-uploader-view>
    <cmx-uploader hidden #uploader [multiple]="true"></cmx-uploader>
    <cmx-uploader-view [input]="uploader"></cmx-uploader-view>
  </div>
  <div class="replies" *ngFor="let reply of replies">
    <cmx-comment (deleteComment)="deleteReply($event)" [comment]="reply"></cmx-comment>
  </div>
  <div class="comment-input-container" *ngIf="replying">
    <cmx-user-avatar [image]="comment.from?.picture" [readonly]="true"></cmx-user-avatar>
    <cmx-comment-input
      #input
      (doneEditing)="doneReplying($event)"
      [replyTo]="comment.from?.fullName"
      [comment]="comment"
      placeholderText="Write a reply..."
    ></cmx-comment-input>
  </div>
</div>

<ng-template #editingTemplate>
  <cmx-comment-input (doneEditing)="doneEditing($event)" [editing]="editing" [comment]="comment"></cmx-comment-input>
</ng-template>
