import jsonata from 'jsonata';
import Ajv, { JSONSchemaType } from 'ajv';

export function sharedUtilHelperFunctions(): string {
  return 'shared-util-helper-functions';
}

export function formatOnHandNumber(facility: string): string {
  const rightNow: Date = new Date();
  const onHandNumber = `${rightNow.getFullYear()}${
    rightNow.getMonth() + 1
  }${rightNow.getDate()}${rightNow.getHours()}${rightNow.getMinutes()}${rightNow.getMilliseconds()}`;
  return onHandNumber;
}

export function padNumber(pieceNumber: string | number, width = 5) {
  pieceNumber = pieceNumber + '';
  return pieceNumber.length >= width ? pieceNumber : new Array(width - pieceNumber.length + 1).join('0') + pieceNumber;
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function validateObjectAgainstTemplate(template: JSONSchemaType<unknown>, data: unknown) {
  const ajv = new Ajv();
  const validate = ajv.compile(template);
  const valid = validate(data);

  return {
    isValid: valid,
    error: validate.errors,
  };
}

export function mapDataWithTemplate(result: any, template: any, data: any) {
  try {
    for (const [key, value] of Object.entries(template)) {
      if (Array.isArray(value)) {
        result[key] = [];
        value.forEach(v => {
          if (v.type && v.type == 'array' && v.key) {
            if (data[v.key])
              data[v.key].forEach((childData: any) => {
                const nestedTemplate = mapDataWithTemplate({}, v.value, childData);
                result[key].push(nestedTemplate);
              });
          } else {
            const nestedTemplate = mapDataWithTemplate({}, v, data);
            result[key].push(nestedTemplate);
          }
        });
      } else if (value) {
        if (key === 'id') {
          result[key] = value;
        } else if (value === null || value === undefined) {
          result[key] = null;
        } else if (typeof value === 'boolean' || typeof value === 'number') {
          result[key] = value;
        } else if (typeof value === 'object') {
          result[key] = mapDataWithTemplate({}, value, data);
        } else {
          result[key] =
            jsonata(value as string).evaluate(data) === undefined ? null : jsonata(value as string).evaluate(data);
        }
      } else if (value === null || value === undefined) {
        result[key] = {};
      }
    }
    return result;
  } catch (error) {
    console.log(error);
    console.log(result);
    console.log(template);
    console.log(data);
  }
}

export function stripRestrictedKeys(barcode: string, restrictKeys: string[]): string {
  let barcodeResult = barcode;
  restrictKeys.map(key => {
    key = key === '\\' ? '\\\\' : key;
    const regExp = new RegExp(`${key}`, 'g');
    barcodeResult = barcodeResult.replace(regExp, '');
  });
  return barcodeResult;
}
