import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarcodeListnerService } from './barcode-listner.service';

@NgModule({
  imports: [CommonModule],
  providers: [BarcodeListnerService],
  exports: []
})
export class BarcodeListnerModule {}
