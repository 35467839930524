import { Component, Input, OnInit } from '@angular/core';
import { ShipmentsList } from '@cmx/shared/feature/platform-configuration';
import { mapDataWithTemplate } from '@cmx/shared/util/helper-functions';
import { FieldType } from '@ngx-formly/core';
@Component({
  selector: 'cmx-chips-list-with-props',
  templateUrl: './chips-list-with-props.component.html',
  styleUrls: ['./chips-list-with-props.component.scss'],
})
export class ChipsListWithPropsComponent extends FieldType implements OnInit {
  @Input() isFormlyField = true;
  @Input() data: ShipmentsList[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.data = this.to.data && this.to.data.length ? this.to.data : this.data || [];
      this.parseDataWithTemplate(this.data, this.to.template);
    }
  }

  parseDataWithTemplate(data: ShipmentsList[], cardTemplate: any) {
    for (let i = 0; i < data.length; i++) {
      data[i] = mapDataWithTemplate(data[i], cardTemplate, data[i]);
    }
  }
}
