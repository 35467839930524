<div class="counter-section">
  <div class="counter-container">
    <div>
      <button
        class="margin-right-1"
        mat-raised-button
        type="button"
        [disabled]="itemCount <= minCount"
        (click)="counterDecrement()"
        [color]="buttonColor"
      >
        <mat-icon>remove</mat-icon>
        {{ decrementLabel }}
      </button>

      <span class="counter"
        ><input
          [(ngModel)]="itemCount"
          type="number"
          matInput
          [min]="minCount"
          (keydown)="keypressEvent($event)"
          [max]="maxCount"
          (ngModelChange)="updateCounter()"
      /></span>

      <button
        class="margin-left-1"
        mat-raised-button
        type="button"
        [disabled]="itemCount >= maxCount"
        (click)="counterIncrement()"
        [color]="buttonColor"
      >
        <mat-icon>add</mat-icon> {{ incrementLabel }}
      </button>
    </div>
  </div>
</div>
