import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'cmx-form-tabs',
  templateUrl: './form-tabs.component.html',
  styleUrls: ['./form-tabs.component.scss'],
})
export class FormTabsComponent extends FieldType {
  isValid(field: FormlyFieldConfig): boolean {
    let valid = false;
    if (field.formControl && (field.template || field.key)) {
      valid = field.formControl.valid;
    }
    if (field.fieldGroup) valid = field.fieldGroup.every(childField => this.isValid(childField));
    return valid;
  }
}
