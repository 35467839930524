import { Injectable } from '@angular/core';
import { CollectionNames } from '@cmx/shared/util/interfaces';
import { FirestoreQuery, FirestoreService } from '@cmx/shared/feature/firestore';
import { Observable } from 'rxjs';
import { RXJSDBService } from '@cmx/shared/data-access/rxdb';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImageViewerService {
  constructor(private readonly database: FirestoreService, private readonly rxdatabase: RXJSDBService) {}

  getImages(taskId: string): Observable<any> {
    const query: FirestoreQuery[] = [
      {
        property: 'collectionName',
        operator: '==',
        value: CollectionNames.assets,
      },
      {
        property: 'type',
        operator: '==',
        value: 'images',
      },
      {
        property: 'taskId',
        operator: '==',
        value: taskId,
      },
    ];

    return this.database.getRealTimeUpdatesFromQuery(query).pipe(
      map((assets: any) => {
        assets.forEach(async (asset: any) => {
          if (!asset.src) {
            asset.src = await this.getAsset(asset);
          }
        });
        return assets;
      }),
    );
  }

  async createObjectURL(blob: Blob): Promise<string> {
    return await this.blobUrlToBase64(URL.createObjectURL(blob));
  }

  async getAsset(asset: any): Promise<any> {
    let data = null;
    try {
      if (asset) {
        const collectionName = `a${asset.id.replace(/-/g, '_')}`;
        await this.rxdatabase.createAttachmentCollection(collectionName);
        const attachmentDocument = await this.rxdatabase.getDocumentById(asset.id, collectionName);
        const attachment = attachmentDocument?.getAttachment(asset.id);

        if (attachment) {
          data = await this.createObjectURL((await attachment.getData()) as Blob);
        }

        return data;
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  private blobUrlToBase64(blobUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';

      xhr.onload = function () {
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          resolve(reader.result as string);
        };
        reader.onerror = function (ev) {
          reject(`Exception reading Blob > xhr.response: ${xhr.response}\nLast Progress Event: ${ev}`);
        };
      };
      xhr.onerror = function (ev) {
        reject(`Exception fetching Blob > BlobUrl: ${blobUrl}\nLast Progress Event: ${ev}`);
      };

      xhr.open('GET', blobUrl);
      xhr.send();
    });
  }
}
