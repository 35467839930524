import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EditorConfiguration, UiEventRaised } from '@cmx/shared/feature/platform-configuration';
@Component({
  selector: 'cmx-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent extends FieldType implements OnInit {
  editorDefaultConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    translate: 'yes',
    height: 'auto',
    width: 'auto',
    maxHeight: '20rem',
    defaultFontName: 'Arial',
    placeholder: 'Enter Text Here...',
    defaultParagraphSeparator: 'p',
    outline: false,
    customClasses: [
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    fonts: [
      { name: 'Arial', class: 'Arial' },
      { name: 'Serif', class: 'Serif' },
      { name: 'Calibri', class: 'Calibri' },
      { name: 'Garamond', class: 'Garamond' },
      { name: 'Arial Narrow', class: 'Arial Narrow' },
    ],
  };

  htmlContent = '';

  @Input() isFormlyField = true;
  @Input() editorConfiguration: EditorConfiguration = {};
  @Output() textEditorChangeEvent = new EventEmitter<UiEventRaised>();

  ngOnInit(): void {
    this.editorConfiguration = this.isFormlyField ? this.to.editorConfiguration : this.editorConfiguration || {};

    this.htmlContent = this.isFormlyField ? this.to.htmlContent : this.htmlContent || '';

    this.configureEditor();
  }

  configureEditor() {
    this.editorDefaultConfig = {
      ...this.editorDefaultConfig,
      ...this.editorConfiguration,
    };
  }

  onChangeEvent() {
    const editorEventToRaise: UiEventRaised = {
      eventName: 'text-editor-onBlurEvent',
      isNavigation: false,
      data: this.htmlContent,
    };
    this.emitTextEditorEvent(editorEventToRaise);
  }

  emitTextEditorEvent(editorEventToRaise: UiEventRaised): void {
    if (!this.isFormlyField) {
      this.textEditorChangeEvent.emit(editorEventToRaise);
    } else {
      this.to.getFieldEvent(editorEventToRaise);
    }
  }
}
