<ngx-charts-pie-chart
  [view]="view"
  [scheme]="colorScheme"
  [results]="data"
  [gradient]="enableGradient"
  [legend]="legend?.visible || true"
  [legendTitle]="legend?.title || 'Legend'"
  [legendPosition]="legend?.position || 'right'"
  [labels]="showLabels"
  [doughnut]="isDoughnut"
  [animations]="enableAnimations"
  [tooltipDisabled]="tooltipDisabled"
  [explodeSlices]="enableExplodeSlices"
  (select)="onSelect($event)"
>
</ngx-charts-pie-chart>
