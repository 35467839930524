import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { FieldType } from '@ngx-formly/core';
import { UiEventRaised, SignaturePadConfig } from '@cmx/shared/feature/platform-configuration';

@Component({
  selector: 'cmx-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent extends FieldType implements OnInit {
  signaturePadOptions: {
    minWidth: number | undefined;
    canvasWidth: number | undefined;
    canvasHeight: number | undefined;
  } = {
    minWidth: undefined,
    canvasWidth: undefined,
    canvasHeight: undefined,
  };
  @Input() isFormlyField = true;
  @Input() signaturePadConfiguration: SignaturePadConfig = {
    markerWidth: 2,
    height: 200,
    showConfirmButton: false,
    showClearButton: true,
    clearButtonColor: '',
    confirmButtonColor: '',
  };
  @Output() raiseSignatureEvent = new EventEmitter<UiEventRaised>();

  @ViewChild('signaturePad', { static: false }) signaturePad: any;

  constructor(private element: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.signaturePadConfiguration = this.isFormlyField
      ? {
          ...this.signaturePadConfiguration,
          ...this.to.signaturePadConfiguration,
        }
      : this.signaturePadConfiguration || {};

    this.signaturePadOptions = {
      minWidth: this.signaturePadConfiguration.markerWidth,
      canvasWidth: this.signaturePadConfiguration.width,
      canvasHeight: this.signaturePadConfiguration.height,
    };
  }

  resizeCanvas(width?: number | string, height?: number | string, timeout = 0) {
    setTimeout(() => {
      const canvasElement = this.signaturePad.elementRef.nativeElement;
      const canvas = canvasElement.childNodes[0];
      const context = canvas.getContext('2d');

      canvas.width = width || this.element.nativeElement.parentElement.offsetWidth - 11;
      canvas.height = height;
    }, timeout);
  }

  ngAfterViewInit() {
    this.resizeCanvas(this.signaturePadConfiguration.width, this.signaturePadConfiguration.height);
    this.signaturePad.clear();
  }

  @HostListener('window:resize')
  onWindowResize() {
    // this.resizeCanvas(undefined, this.signaturePadConfiguration.height);
  }

  drawComplete() {
    const editorEventToRaise: UiEventRaised = {
      eventName: 'signature-draw-completed',
      isNavigation: false,
      data: this.signaturePad.toDataURL(),
    };
    this.emitSignaturePadEvent(editorEventToRaise);
  }

  clearDraw() {
    this.signaturePad.clear();
    const editorEventToRaise: UiEventRaised = {
      eventName: 'clear-signature',
      isNavigation: false,
      data: '',
    };
    this.emitSignaturePadEvent(editorEventToRaise);
  }

  submitDraw() {
    const editorEventToRaise: UiEventRaised = {
      eventName: 'submit-signature',
      isNavigation: false,
      data: this.signaturePad.toDataURL(),
    };
    this.emitSignaturePadEvent(editorEventToRaise);
  }

  emitSignaturePadEvent(editorEventToRaise: UiEventRaised): void {
    if (!this.isFormlyField) {
      this.raiseSignatureEvent.emit(editorEventToRaise);
    } else {
      this.to.getFieldEvent(editorEventToRaise);
    }
  }
}
