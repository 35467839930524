import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CardWidget, UiEventRaised } from '@cmx/shared/feature/platform-configuration';

import { CoreMachine } from '@cmx/state-machines/core';

@Component({
  selector: 'cmx-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class DynamicCardComponent extends FieldType implements OnInit {
  @Input() isFormlyField = true;
  @Input() enableCollapse = true;
  @Input() showChipsLabel = true;
  @Input() taskTemplate: any = null;
  @Input() cardTemplate: any;
  @Input() data: { id?: string; cardContent: CardWidget } = null;

  @Output() raiseCardEvent = new EventEmitter<UiEventRaised>();
  showProperties = false;
  hideTagsOnExpand = true;
  hideTags: boolean;
  showBadgeCount = true;

  constructor(private coreMachineDef: CoreMachine) {
    super();
  }

  async ngOnInit(): Promise<void> {
    if (this.isFormlyField) {
      this.enableCollapse = this.to.enableCollapse;
      this.hideTagsOnExpand = this.to.hideTagsOnExpand;
      this.showBadgeCount = this.to.showBadgeCount;
      this.data = this.to.data || this?.formControl?.value || this.data;
    }
  }

  cardClicked() {
    const eventData: UiEventRaised = {
      isNavigation: false,
      data: this.data,
      eventName: 'CARD_CLICKED',
    };
    this.raiseEvent(eventData);
  }

  async rightIconClick(e) {
    if (this.enableCollapse) {
      this.showProperties = !this.showProperties;
      if (this.hideTagsOnExpand) {
        this.hideTags = !this.hideTags;
      }
    } else {
      const eventData: UiEventRaised = {
        isNavigation: false,
        data: this.data,
        eventName: 'Card_Icon_Clicked',
      };
      this.raiseEvent(eventData);
    }
  }

  chipClicked(e) {
    console.log(e);
  }

  raiseEvent(data: UiEventRaised) {
    if (this.isFormlyField) {
      this.to.getFieldEvent(data);
    } else {
      this.raiseCardEvent.emit(data);
    }
  }
}
