import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AudioRecorderComponent } from '../../audio-recorder/audio-recorder.component';
import { UploaderComponent } from '../uploader.component';

@Component({
  selector: 'cmx-uploader-view',
  templateUrl: './uploader-view.component.html',
  styleUrls: ['./uploader-view.component.scss'],
})
export class UploaderViewComponent {
  @Input() readOnly = false;
  @Input() files: File[] = [];

  @Input() input: UploaderComponent;
  @Input() recorder: AudioRecorderComponent;

  @Output() fileRemoved = new EventEmitter<File>();

  @Output() filesCleared = new EventEmitter<void>();

  public removeFile(fileToRemove: File): void {
    if (!this.readOnly) {
      this.files = [...this.files.filter(file => file.name !== fileToRemove.name)];

      this.fileRemoved.emit(fileToRemove);
    }
  }

  public clear() {
    this.files.splice(0, this.files.length);
  }
}
