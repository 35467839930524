import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from './modal-simple-form-generator.service';
import { WorkflowConfiguration } from '@cmx/shared/feature/platform-configuration';

export interface DialogData {
  workflowConfiguration: WorkflowConfiguration;
  formTitle: string;
  allowSubmit: boolean;
  submitButtonColor?: string;
  allowReset?: boolean;
}

@Component({
  selector: 'cmx-modal-simple-form-generator',
  templateUrl: './modal-simple-form-generator.component.html',
  styleUrls: ['./modal-simple-form-generator.component.scss'],
})
export class ModalSimpleFormGeneratorComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ModalSimpleFormGeneratorComponent>,
    private modalService: ModalService,
  ) {}

  onSubmit(data): void {
    this.dialogRef.close(data);
  }

  formEvent(event): void {
    this.modalService.formEvent.next(event);
    if (event.eventName == 'CLOSE') this.dialogRef.close(event);
  }
}
