import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'cmx-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutoCompleteComponent extends FieldType implements OnInit {
  @Input() displayName = '';
  @Input() data: any[] = [];
  @Input() type = 'text';
  @Input() isFormlyField = true;

  filteredOptions: Observable<any[]>;
  control = new FormControl();

  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.displayName = this.to.displayName || this.displayName;
      this.to.options.forEach(item => {
        this.data.push(item);
      });
    }
    if (this.displayName) {
      this.filteredOptions = this.control.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value[this.displayName])),
        map(label => (label ? this._filter(label as string) : this.data.slice())),
      );
    }
  }

  displayFn = (option: unknown): string => {
    return option && typeof option == 'object' ? option[this.displayName] : option || '';
  };

  private _filter(label: string): any[] {
    const filterValue = label.toLowerCase();

    return this.data.filter(option => {
      if (typeof option == 'string') return option.toLowerCase().indexOf(filterValue) === 0;
      else if (typeof option == 'object') {
        if (typeof option[this.displayName] == 'string') {
          return option[this.displayName].toLowerCase().indexOf(filterValue) === 0;
        } else {
          return option[this.displayName].indexOf(filterValue) === 0;
        }
      } else return option.indexOf(filterValue) === 0;
    });
  }

  optionSelected(event) {
    const eventToRaise: UiEventRaised = {
      eventName: 'AUTOCOMPLETE',
      data: event.option.value,
      isNavigation: false,
    };
    if (this.isFormlyField) {
      this.formControl.setValue(event.option.value);
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseEvent.emit(eventToRaise);
    }
  }
}
