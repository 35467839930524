<div class="tablepanel">
  <table
    mat-table
    class="full-width"
    *ngIf="dataSource && columns.length"
    matSort
    aria-label="Elements"
    [matSortActive]="sortBy"
    [matSortDirection]="sortOrder"
  >
    <!-- selection -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selectionChange(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row, i)"
        >
        </mat-checkbox>
      </td>
      <td mat-footer-cell *matFooterCellDef>Summary</td>
    </ng-container>

    <!-- Column -->
    <ng-container [matColumnDef]="column.dataField" *ngFor="let column of columns">
      <th class="bold" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column?.allowSorting === false">
        {{ column.displayName || column.dataField | titlecase }}
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="column.type === 'button'">
          <button mat-button type="button" (click)="onCellButtonClick(row, column)">
            {{ row[column.dataField] }}
          </button>
        </ng-container>

        <ng-container *ngIf="column.type === 'icon'">
          <button mat-mini-fab color="" type="button" (click)="onCellIconClick(row, column)">
            <mat-icon>{{ row[column.dataField] }}</mat-icon>
          </button>
        </ng-container>

        <ng-container *ngIf="column.type === 'date'">
          {{ row[column.dataField] | date: 'medium' }}
        </ng-container>

        <ng-container *ngIf="!column.type">
          {{ row[column.dataField] }}
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedHeaderColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedHeaderColumns; let i = index"></tr>
  </table>

  <tr class="mat-row row" *ngIf="!dataSource?.data?.length">
    <td class="mat-cell text-center full-width">{{ noDataText || 'No data Found' }}</td>
  </tr>
  <mat-paginator
    class="sticky-bottom"
    #paginator
    [length]="dataSource?.data?.length || 0"
    [pageIndex]="0"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator>
</div>
