<ngx-charts-polar-chart
  [results]="data"
  [view]="view"
  [legend]="legend?.visible || true"
  [legendPosition]="legend?.position || 'right'"
  [legendTitle]="legend?.title || 'Legend'"
  [xAxis]="axis?.showXAxis || true"
  [yAxis]="axis?.showYAxis || true"
  [xAxisLabel]="axis?.xAxisLabel || 'xAxis'"
  [yAxisLabel]="axis?.yAxisLabel || 'xAxis'"
  [showXAxisLabel]="axis?.showXAxisLabel || true"
  [showYAxisLabel]="axis?.showYAxisLabel || true"
  [autoScale]="autoScale"
  [curve]="curve"
  [showGridLines]="showGridLines"
  [animations]="enableAnimations"
  [tooltipDisabled]="tooltipDisabled"
  (select)="onSelect($event)"
>
</ngx-charts-polar-chart>
