import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent extends FieldType {
  constructor() {
    super();
  }

  get label(): string {
    return this.to.label || '';
  }
  get customStyle() {
    return this.to.labelAlign ? { 'text-align': this.to.labelAlign } : {};
  }
}
