<div class="searchbar-container">
  <mat-form-field appearance="outline" class="search-input">
    <mat-icon class="search-icon" matPrefix [color]="searchIconColor">search</mat-icon>
    <input
      type="text"
      #searchControl
      matInput
      [placeholder]="placeholder"
      (keyup.enter)="searchSubmit()"
      [formControl]="control"
    />
    <div matSuffix class="row">
      <mat-icon class="search-icon margin-right-1 pointer" [color]="closeIconColor" (click)="clearInput()"
        >close</mat-icon
      >
      <mat-icon class="pointer" [color]="sendButtonColor" (click)="searchSubmit()"> send </mat-icon>
    </div>
    <mat-hint *ngIf="searchHint" align="start">{{ searchHint }}</mat-hint>
  </mat-form-field>
</div>
