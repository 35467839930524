import { Injectable } from '@angular/core';
import { CollectionNames } from '@cmx/shared/util/interfaces';
import { BaseDataLoaderService } from './base-data-loader.service';

@Injectable({
  providedIn: 'root',
})
export class FormLoaderService extends BaseDataLoaderService {
  load(formId: string) {
    return this.loadData(formId, CollectionNames.forms);
  }
}
