import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardWidgetComponent } from './card-widget.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { ChipsModule } from '@cmx/shared/ui/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardWidgetChipsComponent } from './card-widget-chips/card-widget-chips.component';
import { ChipsListWithPropsComponent } from './chips-list-with-props/chips-list-with-props.component';
import { MatDividerModule } from '@angular/material/divider';
import { DynamicCardComponent } from './card.component';
@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatChipsModule,
    MatListModule,
    ChipsModule,
    MatBadgeModule,
    MatTooltipModule,
  ],
  declarations: [CardWidgetComponent, CardWidgetChipsComponent, ChipsListWithPropsComponent, DynamicCardComponent],
  exports: [CardWidgetComponent, CardWidgetChipsComponent, ChipsListWithPropsComponent, DynamicCardComponent],
})
export class CardWidgetModule {}
