export const seriesData = [
  {
    name: 'Germany',
    value: 8940000,
  },
  {
    name: 'USA',
    value: 5000000,
  },
  {
    name: 'France',
    value: 7200000,
  },
  {
    name: 'UK',
    value: 6200000,
  },
  {
    name: 'Italy',
    value: 4200000,
  },
  {
    name: 'Spain',
    value: 8200000,
  },
];

export const multiSeriesData = [
  {
    name: 'Germany',
    series: [
      {
        name: '1990',
        value: 62000000,
      },
      {
        name: '2010',
        value: 73000000,
      },
      {
        name: '2011',
        value: 89400000,
      },
    ],
  },

  {
    name: 'USA',
    series: [
      {
        name: '1990',
        value: 250000000,
      },
      {
        name: '2010',
        value: 309000000,
      },
      {
        name: '2011',
        value: 311000000,
      },
    ],
  },

  {
    name: 'France',
    series: [
      {
        name: '1990',
        value: 58000000,
      },
      {
        name: '2010',
        value: 50000020,
      },
      {
        name: '2011',
        value: 58000000,
      },
    ],
  },
  {
    name: 'UK',
    series: [
      {
        name: '1990',
        value: 57000000,
      },
      {
        name: '2010',
        value: 62000000,
      },
    ],
  },
];
export const multiSeriesDataRange = [
  {
    name: 'Germany',
    series: [
      {
        name: '2010',
        value: 7300000,
        min: 7000000,
        max: 7600000,
      },
      {
        name: '2011',
        value: 8940000,
        min: 8840000,
        max: 9300000,
      },
    ],
  },

  {
    name: 'USA',
    series: [
      {
        name: '2010',
        value: 7870000,
        min: 7800000,
        max: 7950000,
      },
      {
        name: '2011',
        value: 8270000,
        min: 8170000,
        max: 8300000,
      },
    ],
  },
];
export const bubbleChartData = [
  {
    name: 'Germany',
    series: [
      {
        name: '2010',
        x: 2010,
        y: 80.3,
        r: 80.4,
      },
      {
        name: '2000',
        x: 2000,
        y: 80.3,
        r: 78,
      },
      {
        name: '1990',
        x: 1990,
        y: 75.4,
        r: 79,
      },
    ],
  },
  {
    name: 'United States',
    series: [
      {
        name: '2010',
        x: 2010,
        y: 78.8,
        r: 310,
      },
      {
        name: '2000',
        x: 2000,
        y: 76.9,
        r: 283,
      },
      {
        name: '1990',
        x: 1990,
        y: 75.4,
        r: 253,
      },
    ],
  },
  {
    name: 'France',
    series: [
      {
        name: '2010',
        x: 2010,
        y: 81.4,
        r: 63,
      },
      {
        name: '2000',
        x: 2000,
        y: 79.1,
        r: 59.4,
      },
      {
        name: '1990',
        x: 1990,
        y: 77.2,
        r: 56.9,
      },
    ],
  },
  {
    name: 'United Kingdom',
    series: [
      {
        name: '2010',
        x: 2010,
        y: 80.2,
        r: 62.7,
      },
      {
        name: '2000',
        x: 2000,
        y: 77.8,
        r: 58.9,
      },
      {
        name: '1990',
        x: 1990,
        y: 75.7,
        r: 57.1,
      },
    ],
  },
];

export const cardViewForMobile = [
  {
    title: 'Horizontal Bar Chart',
    widget: 'horizontal-bar-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Normalized Horizontal Bar Chart',
    widget: 'normalized-horizontal-bar-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Stacked Horizontal Bar Chart',
    widget: 'stacked-horizontal-bar-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Grouped Horizontal Bar Chart',
    widget: 'grouped-horizontal-bar-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Vertical Bar Chart',
    widget: 'vertical-bar-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Normalized Vertical Bar Chart',
    widget: 'normalized-vertical-bar-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Stacked Vertical Bar Chart',
    widget: 'stacked-vertical-bar-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Grouped Vertical Bar Chart',
    widget: 'grouped-vertical-bar-chart',
    cols: 1,
    rows: 1,
  },

  {
    title: 'Advance Pie Chart',
    widget: 'advance-pie-chart',
    cols: 1,
    rows: 1,
  },
  { title: 'Normal Pie Chart', widget: 'pie-chart', cols: 1, rows: 1 },
  {
    title: 'Doughnut Pie Chart',
    widget: 'doughnut-pie-chart',
    cols: 1,
    rows: 1,
  },
  { title: 'Grid Pie Chart', widget: 'grid-pie-chart', cols: 1, rows: 1 },
  { title: 'Line Chart', widget: 'line-chart', cols: 1, rows: 1 },
  { title: 'Area Chart', widget: 'area-chart', cols: 1, rows: 1 },
  {
    title: 'Stacked Area Chart',
    widget: 'stacked-area-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Normalized Area Chart',
    widget: 'normalized-area-chart',
    cols: 1,
    rows: 1,
  },
  { title: 'Heat Map Chart', widget: 'heat-map-chart', cols: 1, rows: 1 },
  { title: 'Tree Map Chart', widget: 'tree-map-chart', cols: 1, rows: 1 },
  { title: 'Bubble Chart', widget: 'bubble-chart', cols: 1, rows: 1 },
  {
    title: 'Number Card Chart',
    widget: 'number-card-chart',
    cols: 1,
    rows: 1,
  },
  { title: 'Gauge Chart', widget: 'gauge-chart', cols: 1, rows: 1 },
  { title: 'Polar Chart', widget: 'polar-chart', cols: 1, rows: 1 },
];

export const cardViewForDesktop = [
  {
    title: 'Horizontal Bar Chart',
    widget: 'horizontal-bar-chart',
    cols: 2,
    rows: 1,
  },
  {
    title: 'Normalized Horizontal Bar Chart',
    widget: 'normalized-horizontal-bar-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Stacked Horizontal Bar Chart',
    widget: 'stacked-horizontal-bar-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Grouped Horizontal Bar Chart',
    widget: 'grouped-horizontal-bar-chart',
    cols: 2,
    rows: 1,
  },
  {
    title: 'Vertical Bar Chart',
    widget: 'vertical-bar-chart',
    cols: 2,
    rows: 1,
  },
  {
    title: 'Normalized Vertical Bar Chart',
    widget: 'normalized-vertical-bar-chart',
    cols: 1,
    rows: 2,
  },
  {
    title: 'Stacked Vertical Bar Chart',
    widget: 'stacked-vertical-bar-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Grouped Vertical Bar Chart',
    widget: 'grouped-vertical-bar-chart',
    cols: 1,
    rows: 1,
  },

  {
    title: 'Advance Pie Chart',
    widget: 'advance-pie-chart',
    cols: 2,
    rows: 1,
  },
  { title: 'Normal Pie Chart', widget: 'pie-chart', cols: 1, rows: 1 },
  {
    title: 'Doughnut Pie Chart',
    widget: 'doughnut-pie-chart',
    cols: 1,
    rows: 1,
  },
  { title: 'Grid Pie Chart', widget: 'grid-pie-chart', cols: 2, rows: 1 },
  { title: 'Line Chart', widget: 'line-chart', cols: 1, rows: 1 },
  { title: 'Area Chart', widget: 'area-chart', cols: 1, rows: 1 },
  {
    title: 'Stacked Area Chart',
    widget: 'stacked-area-chart',
    cols: 1,
    rows: 1,
  },
  {
    title: 'Normalized Area Chart',
    widget: 'normalized-area-chart',
    cols: 2,
    rows: 1,
  },
  { title: 'Heat Map Chart', widget: 'heat-map-chart', cols: 1, rows: 1 },
  { title: 'Tree Map Chart', widget: 'tree-map-chart', cols: 1, rows: 1 },
  { title: 'Bubble Chart', widget: 'bubble-chart', cols: 1, rows: 1 },
  {
    title: 'Number Card Chart',
    widget: 'number-card-chart',
    cols: 1,
    rows: 1,
  },
  { title: 'Gauge Chart', widget: 'gauge-chart', cols: 2, rows: 1 },
  { title: 'Polar Chart', widget: 'polar-chart', cols: 1, rows: 1 },
];
