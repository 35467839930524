import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  HostListener,
  ViewEncapsulation,
} from '@angular/core';
import { UiEventRaised, MilestoneSteps } from '@cmx/shared/feature/platform-configuration';

import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-milestone',
  templateUrl: './milestone.component.html',
  styleUrls: ['./milestone.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MilestoneComponent extends FieldType implements OnInit {
  @ViewChild('boxcontainer', { static: false }) boxContainer: any;
  @Input() isFormlyField = true;

  @Input() data: MilestoneSteps[] = [];
  scale = 1;
  autoScale = true;
  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  constructor(private element: ElementRef) {
    super();
  }

  ngOnInit() {
    if (this.isFormlyField) {
      this.data = this.to.data && this.to.data.length ? this.to.data : this.data || [];
    }
    this.data.sort((a: MilestoneSteps, b: MilestoneSteps) => {
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;
      return 0;
    });

    setTimeout(() => {
      if (this.autoScale) {
        this.resize();
      }
    });
  }

  ngAfterViewInit() {
    if (this.autoScale) {
      this.resize();
    }
  }

  resize() {
    // this.scale = 1;
    this.scale = 0.6;
    // this.boxContainer.nativeElement.style.zoom = this.scale;
    this.boxContainer.nativeElement.style.transform = `scale(${this.scale})`;
    setTimeout(() => {
      while (
        this.scale > 0.06 &&
        (this.boxContainer.nativeElement.clientWidth * this.scale > this.element.nativeElement.clientWidth ||
          this.boxContainer.nativeElement.clientHeight * this.scale > this.element.nativeElement.clientHeight)
      ) {
        this.scale = this.scale - 0.04;
        // this.boxContainer.nativeElement.style.zoom = this.scale;
        this.boxContainer.nativeElement.style.transform = `scale(${this.scale})`;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (this.autoScale) {
      this.resize();
    }
  }
}
