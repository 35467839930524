<mat-form-field>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select [formControl]="tagBoxcontrol" multiple (selectionChange)="raiseTagBoxEvent()">
    <mat-select-trigger>
      <mat-chip-list>
        <mat-chip *ngFor="let item of tagBoxcontrol.value" [removable]="true" (removed)="onToppingRemoved(item)">
          {{ item }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>

    <mat-option *ngFor="let item of data" [value]="item">{{ item }}</mat-option>
  </mat-select>
</mat-form-field>
