import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { CollectionNames } from '@cmx/shared/util/interfaces';
import { FirestoreQuery, FirestoreService } from '@cmx/shared/feature/firestore';

@Component({
  selector: 'cmx-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarComponent extends FieldType implements OnInit, OnChanges, OnDestroy {
  selectedFilterValue: TaskFilterButton;
  color?: 'primary' | 'warn' | 'accent' | 'success';
  count?: number | string;
  @Input() selectable = false;
  @Input() removable = false;
  @Input() isFormlyField = true;
  @Input() data: TaskFilterButton[];
  @Input() filterFormList: any[];
  @Input() filteredData = {};

  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  constructor(private readonly firestore: FirestoreService) {
    super();
  }

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.data = this.to.data || this.data;
      this.selectable = this.to.selectable || this.selectable;
      this.removable = this.to.removable || this.removable;
      this.filteredData = this.to.filteredData || this.filteredData;
      this.filterFormList = this.to.filterFormList || this.filterFormList;
      this.updateFilters();
    }
  }

  ngOnChanges(): void {
    this.updateFilters();
  }

  ngOnDestroy(): void {
    if (this.selectedFilterValue && this.selectedFilterValue?.hideExpression) {
      this.selectedFilterValue.hideExpression = !this.selectedFilterValue.hideExpression;
    }
  }

  async updateFilters() {
    const promises = this.data?.map(async (d: TaskFilterButton) => {
      if (d.formId) {
        const formData = this.getForm(d.formId);
        const form: {
          model: any;
          fields: FormlyFieldConfig[];
          dataSource: any;
        } = formData;
        if (d.formId === 'clientFilter') {
          const clients = await this.getClients();
          form.fields[0].templateOptions.options = clients.map(client => {
            return {
              label: client.name,
              value: client.id,
            };
          });
        }
        if (d.formId === 'assigneesDesktopFilter') {
          const users = await this.getusers();
          form.fields[0].templateOptions.options = users.map(user => {
            return {
              label: user.name,
              value: user.id,
            };
          });
        }

        const model = this.filteredData[d.property];

        if (model) {
          form.model[d.property] = {};
          if (typeof model == 'object') {
            for (const value of model) {
              if (value) form.model[d.property][value] = true;
            }
          } else if (typeof model == 'string' || typeof model == 'number') {
            form.model[d.property] = model;
          }
        }
        d.form = form;
      }
    });

    if (promises) {
      await Promise.all(promises);
    }
  }

  removeFilter(filter: TaskFilterButton): void {
    const filterEventToRaise: UiEventRaised = {
      eventName: 'remove-filter',
      isNavigation: false,
      data: filter,
    };
    if (filter.property) {
      if (typeof this.filteredData[filter.property] == 'object') {
        this.filteredData[filter.property] = [];
      } else if (
        typeof this.filteredData[filter.property] == 'string' ||
        typeof this.filteredData[filter.property] == 'number'
      ) {
        this.filteredData[filter.property] = '';
      }
      filter.form.model[filter.property] = {};
      const eventToRaise: UiEventRaised = {
        eventName: 'FILTER_DATA_EVENT',
        data: this.filteredData,
        isNavigation: false,
      };
      this.emitFilterEvent(eventToRaise);
      this.selectedFilterValue.hideExpression = false;
    } else {
      this.emitFilterEvent(filterEventToRaise);
      this.selectedFilterValue.hideExpression = false;
    }
  }

  selectFilter(filter: TaskFilterButton = null): void {
    this.selectedFilterValue = filter;
    this.selectedFilterValue.hideExpression = !this.selectedFilterValue.hideExpression;
    this.data.forEach(d => (d.name !== this.selectedFilterValue.name ? (d.hideExpression = false) : ''));
    const filterEventToRaise: UiEventRaised = {
      eventName: 'select-filter',
      isNavigation: false,
      data: filter,
    };
    if (!this.data.some(item => item.form)) this.emitFilterEvent(filterEventToRaise);
  }

  emitFilterEvent(filterEventToRaise: UiEventRaised): void {
    if (!this.isFormlyField) {
      this.raiseEvent.emit(filterEventToRaise);
    } else {
      this.to.getFieldEvent(filterEventToRaise);
    }
  }

  getForm(formId: string) {
    return this.filterFormList.find(form => form.name === formId);
  }

  raiseFormValue(event) {
    const { value } = event.data;

    const eventToRaise: UiEventRaised = {
      eventName: 'FILTER_DATA_EVENT',
      data: {},
      isNavigation: false,
    };
    for (const key in value) {
      if (key) {
        const currentFilter = this.data.find(item => item.property == key);
        switch (currentFilter.operator) {
          case '$in':
            eventToRaise.data[key] = [];
            if (typeof value[key] == 'object') {
              for (const key1 in value[key]) {
                if (value[key][key1]) eventToRaise.data[key].push(key1);
              }
            }
            break;
          case '==':
            if (value[key] && typeof value[key] == 'object' && !Object.entries(value[key]).length) {
              eventToRaise.data[key] = null;
            } else {
              eventToRaise.data[key] = value[key];
            }
            break;
        }
      }
    }
    eventToRaise.data = this.filteredData = { ...this.filteredData, ...eventToRaise.data };
    this.selectedFilterValue.hideExpression = false;
    this.emitFilterEvent(eventToRaise);
  }

  async getClients() {
    try {
      const query: FirestoreQuery[] = [
        {
          property: 'collectionName',
          operator: '==',
          value: CollectionNames.companies,
        },
      ];

      const clients = await this.firestore.queryDocument(query, undefined, [{ property: 'name', order: 'asc' }]);

      return clients;
    } catch (error) {
      console.log(error);
    }
  }

  async getusers() {
    try {
      const query: FirestoreQuery[] = [
        {
          property: 'collectionName',
          operator: '==',
          value: CollectionNames.users,
        },
      ];

      const users = await this.firestore.queryDocument(query, undefined, [{ property: 'name', order: 'asc' }]);
      return users;
    } catch (error) {
      console.log(error);
    }
  }
}

export interface TaskFilterButton {
  id: string;
  icon: string;
  name: string;
  property?: string;
  operator?: string;
  type?: string;
  hideExpression?: boolean;
  formId?: string;
  form?: any;
}
