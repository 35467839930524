import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@cmx/shared/feature/error-handler';
import { ConfirmationDialogService } from '@cmx/shared/ui/confirmation-alert';
import { BaseMachine, MachineLoaderService } from '@cmx/state-machines/shared';
import { FirestoreQuery, FirestoreService, ProdeoFirestoreQuery } from '@cmx/shared/feature/firestore';
import { formatOnHandNumber, uuidv4 } from '@cmx/shared/util/helper-functions';
import { VideoService } from '@cmx/shared/ui/video';
import { BehaviorSubject, Subject } from 'rxjs';
import { lastValueFrom, of } from 'rxjs';
import jsonata from 'jsonata';
import { isArray } from 'util';
import { SnackbarService } from '@cmx/shared/ui/snackbar';
import { CollectionNames, SyncStatus } from '@cmx/shared/util/interfaces';
import getBlobDuration from 'get-blob-duration';
import { arrayUnion } from '@angular/fire/firestore';
import { saveAs } from 'file-saver';
import { RXJSDBService } from '@cmx/shared/data-access/rxdb';

export enum FileType {
  VIDEO = 'video',
  IMAGE = 'image',
}

@Injectable({
  providedIn: 'root',
})
export class CoreMachine extends BaseMachine {
  toggleMenu$ = new BehaviorSubject<string>('');
  isTaskCurrentlyActive$ = new BehaviorSubject<boolean>(false);
  public loader$ = new Subject<boolean>();
  public initialWorkflowData: any = {};
  constructor(
    machineLoaderService: MachineLoaderService,
    private confirmationDialogService: ConfirmationDialogService,
    private readonly database: FirestoreService,
    private errorHandlerService: ErrorHandlerService,
    private readonly videoService: VideoService,
    private readonly snackbarService: SnackbarService,
    private readonly rxjsdb: RXJSDBService,
  ) {
    super(machineLoaderService, database);
  }

  toggleMenu = () => this.toggleMenu$.next('toggle');

  toggleVideoRecorder = (eventName: string) => {
    if (eventName === 'VIDEO_RECORDER_PLAY') {
      setTimeout(() => {
        this.videoService.toggleVideoRecorder(eventName);
      }, 4000);
    } else {
      this.videoService.toggleVideoRecorder(eventName);
    }
  };

  showError = (errorMessage: string, title?: string) => {
    const message = errorMessage ? errorMessage : 'Unexpected error has occurred';

    this.errorHandlerService.openSimpleErrorAlert(title ? title : null, message);
  };

  showSnackbar = (snackbarOptions: any) => {
    this.snackbarService.openInfoSnackBar(
      snackbarOptions.message,
      snackbarOptions.durationInSeconds,
      snackbarOptions.horizontalPosition,
      snackbarOptions.verticalPosition,
    );
  };

  async displayConfirmationDialog(message: string): Promise<boolean> {
    const isConfirmation = await this.confirmationDialogService.openConfirmationDialog(message);
    return Promise.resolve(isConfirmation);
  }

  downloadFile(url: string, fileName = 'ExportedFile') {
    saveAs(url, fileName);
  }

  expandFile() {
    const ele = document.getElementsByClassName('cdk-overlay-pane');
    if (ele[0].classList.contains('full-screen')) {
      ele[0].classList.remove('full-screen');
    } else {
      ele[0].classList.add('full-screen');
    }
  }

  updateUnscreenCount = (context: any, event: any, selector: string): void => {
    context.model.parentTask.shipments.forEach((shipment: any) => {
      if (shipment.reference.id == event.data.shipments || shipment.reference.id == event.data.id) {
        shipment.reference.unscreenedReason = event.data.unscreenedReason || '';
        shipment.reference.unscreenedCount = event.data.unscreenedCount || 0;
        shipment.reference.screenedCount =
          shipment.reference.totalPieces - (shipment.reference.alarmedCount + shipment.reference.unscreenedCount);
        context.model.parentTask.referenceDocument.unscreenedCount =
          context.model.parentTask.referenceDocument.unscreenedCount + event.data.unscreenedCount;
      }
    });
  };

  updateAlarmedCount = (context: any, event: any): void => {
    context.model.parentTask.shipments.forEach((shipment: any) => {
      if (shipment.reference.id == event.data.shipments || shipment.reference.id == event.data.id) {
        shipment.reference.alarmedReason = event.data.alarmedReason || '';
        shipment.reference.alarmedCount = event.data.alarmedCount || 0;
        shipment.reference.screenedCount =
          shipment.reference.totalPieces - (shipment.reference.alarmedCount + shipment.reference.unscreenedCount) || 0;
        context.model.parentTask.referenceDocument.alarmedCount =
          context.model.parentTask.referenceDocument.alarmedCount + shipment.reference.alarmedCount;
      }
    });
  };

  addShipments = (context: any, event: any) => {
    console.warn(event);
    if (event.data.shipment) {
      const editedShipment = event.data.shipment;
      editedShipment.task = {
        ...editedShipment.task,
        ...{
          maxIndex: event.data.pieces,
          timestamp: Date.now(),
          extraProperties: {
            totalPieces: event.data.pieces,
            alarmedCount: 0,
            screenedCount: 0,
            unscreenedCount: 0,
          },
        },
      };
      editedShipment.reference = {
        ...editedShipment.reference,
        ...{
          totalPieces: event.data.pieces,
        },
      };

      const shipmentIndex = context.model.parentTask.shipments.findIndex(
        (shipment: any) => shipment.index === event.data.shipment.index,
      );

      context.model.parentTask.shipments[shipmentIndex] = editedShipment;
    } else {
      const taskId = uuidv4();
      const referenceId = uuidv4();
      const a = {
        id: taskId,
        parent: context.model.parentTask.id,
        reference: context.model.barCode,
        maxIndex: event.data.pieces,
        timestamp: Date.now(),
        referenceDocument: referenceId,
        extraProperties: {
          totalPieces: event.data.pieces,
          alarmedCount: 0,
          screenedCount: 0,
          unscreenedCount: 0,
        },
      };
      context.model.parentTask.shipments.push({
        task: { ...a },
        reference: {
          ...{
            collectionName: 'caninescreenings',
            id: referenceId,
            reference: context.model.barCode,
            totalPieces: event.data.pieces,
          },
        },
        index: uuidv4(),
      });
    }
    context.model.barCode = '';
  };

  public calculateShipmentProperties(context: any) {
    const totalPieces = context.model.parentTask.shipments.reduce((acc, curr) => acc + curr.reference.totalPieces, 0);
    const alarmedCount = context.model.parentTask.shipments.reduce((acc, curr) => acc + curr.reference.alarmedCount, 0);
    const unscreenedCount = context.model.parentTask.shipments.reduce(
      (acc, curr) => acc + curr.reference.unscreenedCount,
      0,
    );
    return {
      totalPieces,
      alarmedCount,
      screenedCount: totalPieces - (alarmedCount + unscreenedCount),
      unscreenedCount,
    };
  }

  executeDBAction(databaseOperation: ProdeoFirestoreQuery) {
    const { action, data, documentId, queryList, limitTo, sortBy } = databaseOperation;
    switch (action) {
      case 'STORE':
        if (!data['id']) {
          data['id'] = uuidv4();
        }
        return this.database.storeDocument(data);
      case 'DELETE':
        if (!documentId) {
          return;
        }
        return this.database.deleteDocumentById(documentId);
      case 'DELETE_MULTIPLE':
        if (!data || !isArray(data)) {
          return;
        }

        return Promise.all(data.map(async documentId => this.database.deleteDocumentById(documentId)));
      case 'FIND':
        if (!queryList) {
          return;
        }
        return this.database.queryDocument(queryList, limitTo, sortBy);
      case 'FIND_BY_ID':
        if (!documentId) {
          return;
        }
        return this.database.getDocumentById(documentId);
      case 'SUBSCRIBE_TO_QUERY':
        if (!queryList) {
          return;
        }
        return this.database.getRealTimeUpdatesFromQuery(queryList);
      default:
        return null;
    }
  }

  populateDocument(documentToPopulate: any, propertiesToPopulate: string[]): Promise<any> {
    return this.database.populateDocument(documentToPopulate, propertiesToPopulate);
  }

  getObjectTemplate(templateName: string): Promise<any> {
    return this.loadObjectTemplate(templateName);
  }

  createObjectURL(blob: Blob): string {
    return URL.createObjectURL(blob);
  }

  loadObjectTemplate(templateName: string): any {
    const completedStatus = this.initialWorkflowData.statuses.find((statusItem: any) => statusItem.name === 'Pending');

    let template = {};
    switch (templateName) {
      case 'tasks':
        template = {
          id: uuidv4(),
          facility: null,
          referenceDocument: null,
          assignees: [],
          subType: null,
          type: null,
          progress: 0,
          reference: null,
          status: completedStatus?.id || null,
          statusDisplayName: completedStatus?.displayName || null,
          collectionName: templateName,
          currentIndex: 0,
          maxIndex: 0,
          start: new Date().getTime(),
          end: 0,
          extraProperties: {},
          shipments: [],
          milestones: [],
          transactions: [],
          documents: [],
          images: [],
        } as any;
        break;
      case 'cargoscreenings':
        template = {
          alarmedCount: 0,
          authorizedRepresentative: null,
          canine: null,
          client: null,
          handler: null,
          screenedCount: 0,
          screeningMode: null,
          totalPieces: 0,
          totalSlac: 0,
          unscreenedCount: 0,
        } as any;
        break;
      case 'transactions':
        template = {
          description: null,
          action: null,
          timestamp: 0,
        } as any;
        break;
      case 'milestones':
        template = {
          displayName: null,
          timestamp: 0,
          code: null,
        } as any;
        break;
      case 'assets':
        template = {
          collectionName: templateName,
          id: null,
          icon: null,
          name: null,
          displayName: null,
          taskId: null,
          mimeType: null,
          src: null,
          thumbnail: null,
          storageBucketPath: null,
          videoLength: 0,
          user: null,
          timestamp: 0,
        } as any;
        break;
    }
    return template;
  }

  generateReference() {
    return formatOnHandNumber('');
  }

  loadObjectTemplateFromDB(templateName: string): Promise<any> {
    // TODO: Implement loading from database
    return this.database.getDocumentById(templateName);
  }

  createTransaction(task: any, transactionCode: string, userId: string) {
    const transaction = this.getObjectTemplate('transactions') as any;

    transaction.code = transactionCode || '';
    transaction.timestamp = new Date().getTime() || '';

    const transactionCodeObject = this.initialWorkflowData.transactioncodes.find(
      (transactionItem: any) => transactionCode === transactionItem.code,
    );

    transaction.description = transactionCodeObject.description || '';
    transaction.action = jsonata(transactionCodeObject.action).evaluate(task) || '';

    let milestoneCodeObject: any = null;
    let milestone: any = null;

    if (transactionCodeObject && transactionCodeObject.milestoneCode) {
      milestoneCodeObject = this.initialWorkflowData.milestonecodes.find(
        (milestoneItem: any) => transactionCodeObject.milestoneCode === milestoneItem.code,
      );

      if (milestoneCodeObject) {
        milestone = this.getObjectTemplate('milestones') as any;
        milestone.displayName = milestoneCodeObject.displayName || '';
        milestone.timestamp = new Date().getTime() || '';
        milestone.code = transactionCodeObject.milestoneCode || '';
      }
    }

    return { transaction, milestone };
  }

  saveContextData = async (data: any, selector: string) => {
    if (!data) throw new Error('no data to save');
    const dataToSave = this.evaluate(selector, data);
    if (isArray(dataToSave)) {
      return Promise.all(
        dataToSave.map(
          async element =>
            await this.executeDBAction({
              action: 'STORE',
              data: {
                ...element,
                timestamp: new Date().getTime(),
              },
            }),
        ),
      );
    } else {
      return this.executeDBAction({
        action: 'STORE',
        data: {
          ...dataToSave,
          timestamp: new Date().getTime(),
        },
      });
    }
  };

  dataURLToBlob(dataURL: string) {
    var BASE64_MARKER = ';base64,';

    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = decodeURIComponent(parts[1]);

      return new Blob([raw], { type: contentType });
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  async getEstimatedQuota() {
    let estimation;
    if (navigator.storage && navigator.storage.estimate) {
      estimation = await navigator.storage.estimate();
      console.log(`Quota: ${estimation.quota}`);
      console.log(`Usage: ${estimation.usage}`);
    } else {
      console.error('StorageManager not found');
    }

    return estimation ? `${Math.round((estimation.usage / (estimation.quota || 1)) * 100)}` : 'NA';
  }

  createAssetFile = async (context: any, event: any) => {
    const assetId = uuidv4();

    let assetObj = this.getObjectTemplate('assets') as any;
    const duration = await getBlobDuration(context.model.video);
    assetObj = {
      ...assetObj,
      ...{
        id: assetId,
        name: context.model.parentTask.reference + '.' + context.model.video.name.split('.')[1],
        timestamp: new Date().getTime(),
        storageBucketPath: 'files/videos/',
        mimeType: context.model.video.type,
        taskId: context.model.parentTask.id,
        user: context.model.currentUser.id,
        displayName: context.model.parentTask.reference,
        videoLength: duration,
        syncStatus: SyncStatus.default,
        ignoreSync: true,
        type: 'video',
        uploadProgress: 0,
      },
    };

    const assetDoc: any = await this.executeDBAction({
      action: 'STORE',
      data: assetObj,
    });

    if (assetDoc) {
      await this.rxjsdb.saveAssetFile(assetDoc.id, context.model.video, assetObj.mimeType);
    }
  };

  async getIncompleteAssets(userId: string, taskId: string) {
    const query: FirestoreQuery[] = [
      {
        property: 'collectionName',
        operator: '==',
        value: CollectionNames.assets,
      },
      {
        property: 'syncStatus',
        operator: '==',
        value: SyncStatus.default,
      },
      {
        property: 'taskId',
        operator: '==',
        value: taskId,
      },
      {
        property: 'user',
        operator: '==',
        value: userId,
      },
    ];

    const incompleteAssets = await this.database.queryDocument(query);
    return incompleteAssets;
  }

  public async removeFailedAssets(userId: string, taskId: string) {
    const assets = await this.getIncompleteAssets(userId, taskId);
    const promises = assets?.map(async (asset: any) => {
      const result = await this.database.deleteDocumentById(asset.id);
      return result;
    });
    if (promises) {
      await Promise.all(promises);
    }
  }

  createPictureAssetFile = async (
    assetConfig: { userId: string; taskId: string; destinationBucket: string; fileType: string },
    imageFile: any,
  ) => {
    if (imageFile) {
      const blob = this.dataURLToBlob(imageFile.src);
      const assetId = uuidv4();
      const fileName = imageFile.displayName.split('.')[0];
      let assetObj = this.getObjectTemplate('assets') as any;
      assetObj = {
        ...assetObj,
        ...{
          id: assetId,
          name: fileName,
          timestamp: new Date().getTime(),
          storageBucketPath: assetConfig.destinationBucket,
          mimeType: blob.type,
          taskId: assetConfig.taskId,
          user: assetConfig.userId,
          displayName: fileName,
          syncStatus: SyncStatus.default,
          ignoreSync: true,
          type: assetConfig.fileType,
        },
      };

      if (assetConfig.fileType === FileType.VIDEO) {
        await this.removeFailedAssets(assetConfig.userId, assetConfig.taskId);
      }

      const assetDoc: any = await this.executeDBAction({
        action: 'STORE',
        data: assetObj,
      });

      if (assetDoc) {
        await this.rxjsdb.saveAssetFile(assetDoc.id, blob, assetObj.mimeType);
      }
    }
  };

  saveTransaction = async (model: any, taskSelector: string, code: string, userId: string) => {
    if (!model) throw new Error('no data to save');

    if (taskSelector === 'shipment') {
      model.parentTask.shipments.map((shipment: any) => {
        const unboundTask = { ...shipment.task, referenceDocument: { ...shipment.reference } };
        const { transaction, milestone } = this.createTransaction(unboundTask, code, userId);
        if (transaction) {
          if (
            !model.parentTask.transactions.some(
              (currentTransaction: any) =>
                currentTransaction.code === transaction.code && currentTransaction.action === transaction.action,
            )
          ) {
            model.parentTask.transactions.push(transaction);
          }
        }
        if (milestone) {
          if (!model.parentTask.milestones.some((currentMilestone: any) => currentMilestone.code === milestone.code)) {
            model.parentTask.milestones.push(milestone);
          }
        }
      });
    } else {
      const { transaction, milestone } = this.createTransaction({ ...model.parentTask }, code, userId);
      if (transaction) {
        if (
          !model.parentTask.transactions.some(
            (currentTransaction: any) =>
              currentTransaction.code === transaction.code && currentTransaction.action === transaction.action,
          )
        ) {
          model.parentTask.transactions.push(transaction);
        }
      }
      if (milestone) {
        if (!model.parentTask.milestones.some((currentMilestone: any) => currentMilestone.code === milestone.code)) {
          model.parentTask.milestones.push(milestone);
        }
      }
    }
  };

  async getAssets(assetIDs: string[]) {
    if (assetIDs.length <= 0 || !Array.isArray(assetIDs)) {
      return [];
    }

    const assetsQuery: FirestoreQuery[] = [
      { property: 'collectionName', operator: '==', value: 'assets' },
      { property: 'id', operator: 'in', value: assetIDs },
    ];

    const assetsQueryResponse = await this.database.queryDocument(assetsQuery);
    return assetsQueryResponse;
  }

  async getTransactions(taskId: string) {
    const query: FirestoreQuery[] = [
      { property: 'collectionName', operator: '==', value: 'transactions' },
      { property: 'task', operator: '==', value: taskId },
    ];

    const response = await this.database.queryDocument(query);
    return response;
  }

  async getMilestones(referenceId: string) {
    const query: FirestoreQuery[] = [
      { property: 'collectionName', operator: '==', value: 'milestones' },
      { property: 'referenceDocument', operator: '==', value: referenceId },
    ];

    const response = await this.database.queryDocument(query);
    return response;
  }

  loadLists = async (lists: []) => {
    let query: FirestoreQuery[] = [];
    const listPromises = [];
    for (const list of lists) {
      if (Array.isArray(list)) {
        query = list;
      } else {
        query = [{ property: 'collectionName', operator: '==', value: list }];
      }

      const listPromise = this.database.queryDocument(query);
      listPromises.push(listPromise);
    }
    const result = await Promise.all(listPromises);
    return result;
  };

  loadInitialWorflowData = () => {
    return lastValueFrom(of(this.initialWorkflowData));
  };

  escapeBackslash = (str: string) => {
    return str.replace(/\\/g, '\\\\');
  };

  getTaskAndDetails = async (taskId: string, isComplete: boolean, userId: string) => {
    const context: any = {
      model: {
        taskId,
        parentTask: {
          transactions: [],
          milestones: [],
          shipments: [],
        },

        currentUser: null,
      },
    };

    const currentUser = await this.database.getDocumentById(userId);
    context.model.currentUser = currentUser;
    if (taskId && taskId === 'new') return context;

    const completedTaskModel = await this.database.getDocumentById(`${taskId}`);
    console.log(completedTaskModel);
    context.model = {
      taskId,
      currentUser,
      parentTask: completedTaskModel,
    };
    console.log('context', context);
    return context;
  };

  getBaseDataForReports = async (userId: string) => {
    const context = {
      model: {
        companies: [] as any[],
        canines: [] as any[],
        currentUser: null,
      },
    };

    const userPromise = this.database.getDocumentById(userId);
    const companiesQuery: FirestoreQuery[] = [{ property: 'collectionName', operator: '==', value: 'companies' }];
    const caninesQuery: FirestoreQuery[] = [{ property: 'collectionName', operator: '==', value: 'canines' }];

    const companiesPromise = this.database.queryDocument(companiesQuery, undefined, [
      { property: 'name', order: 'asc' },
    ]);
    const caninesPromise = this.database.queryDocument(caninesQuery, undefined, [{ property: 'name', order: 'asc' }]);

    const [user, companies, canines] = await Promise.all([userPromise, companiesPromise, caninesPromise]);

    context.model.currentUser = { ...user };
    context.model.companies = [...companies];
    context.model.canines = [...canines];

    console.log('context', context);
    return context;
  };

  checkCountsAndReasonsBeforeFinalize(references: any[]): boolean {
    let invalid = false;
    let errorMsg = '';
    for (const ref of references) {
      if (!/^\d+$/.test(ref.alarmedCount)) {
        errorMsg = errorMsg
          ? errorMsg + `<br><b>${ref.reference}:</b>  Alarmed count should be a valid number.`
          : `<b>${ref.reference}:</b> Alarmed count should be a valid number.`;
        invalid = true;
      }
      if (!/^\d+$/.test(ref.unscreenedCount)) {
        errorMsg = errorMsg
          ? errorMsg + `<br><b>${ref.reference}:</b> Unscreened count should be a valid number.`
          : `<b>${ref.reference}:</b> Unscreened count should be a valid number.`;
        invalid = true;
      }
      if (ref.totalPieces < ref.alarmedCount + ref.unscreenedCount) {
        errorMsg = errorMsg
          ? errorMsg + `<br><b>${ref.reference}:</b> Alarmed and Unscreened Count should be less than total Pieces.`
          : `<b>${ref.reference}:</b>  Alarmed and Unscreened Count should be less than total Pieces.`;
        invalid = true;
      }
      if (ref.alarmedCount > 0 && (!ref.alarmedReason || !ref.alarmedReason.trim())) {
        errorMsg = errorMsg
          ? errorMsg + `<br><b>${ref.reference}:</b> Reason for alarmed pieces is mandatory.`
          : `<b>${ref.reference}:</b> Reason for alarmed pieces is mandatory.`;
        invalid = true;
      }
      if (ref.unscreenedCount > 0 && (!ref.unscreenedReason || !ref.unscreenedReason.trim())) {
        errorMsg = errorMsg
          ? errorMsg + `<br><b>${ref.reference}:</b> Reason for unscreened pieces is mandatory.`
          : `<b>${ref.reference}:</b> Reason for unscreened pieces is mandatory.`;
        invalid = true;
      }
    }
    if (invalid) {
      this.errorHandlerService.openSimpleErrorAlert(null, errorMsg, 'scroll-error-320');
    }
    return invalid;
  }

  finalizeTask = async (model: any) => {
    try {
      let completedStatus: any;
      if (this.database.initialWorkflowData && this.database.initialWorkflowData.statuses) {
        completedStatus = this.database.initialWorkflowData.statuses.find(
          (statusItem: any) => statusItem.name === 'Completed',
        );
      } else {
        const completedStatusObject = await this.database.queryDocument([
          {
            property: 'name',
            operator: '==',
            value: 'Completed',
          },
          {
            property: 'collectionName',
            operator: '==',
            value: 'statuses',
          },
        ]);

        completedStatus = completedStatusObject && completedStatusObject.length > 0 ? completedStatusObject[0] : null;
      }

      model.parentTask.status = completedStatus?.id || null;
      model.parentTask.statusDisplayName = completedStatus?.displayName || null;
      model.parentTask.end = new Date().getTime();
      model.parentTask.start = model.parentTask.start || new Date().getTime();
      model.parentTask.progress = 100;
      model.parentTask.currentIndex = model.parentTask.maxIndex;

      for (const shipment of model.parentTask.shipments) {
        shipment.task.status = completedStatus?.id || null;
        shipment.task.end = new Date().getTime();
        shipment.task.progress = 100;
        shipment.task.start = shipment.task.start || new Date().getTime();
        shipment.task.currentIndex = shipment.task.maxIndex;
      }

      this.database.deleteDocumentById(`machineState:${model.parentTask.id}`);
      this.isTaskCurrentlyActive$.next(false);

      return this.saveModelToDatabase(model, model.parentTask);
    } catch (error) {
      this.database.storeDocument({
        referenceDocId: `${model.parentTask.id}`,
        task: model.parentTask,
        error: JSON.stringify(error),
        ignoreSync: true,
        collectionName: 'log',
        timestamp: Date.now(),
      });
    }
  };

  private saveModelToDatabase(_: any, task: any) {
    const backupTask = { ...task };
    const taskWithoutTransactions = { ...task };
    delete taskWithoutTransactions.milestones;
    delete taskWithoutTransactions.transactions;
    delete taskWithoutTransactions.images;
    const properties: any = {
      ...taskWithoutTransactions,
    };

    if (backupTask.transactions && backupTask.transactions.length > 0) {
      properties.transactions = arrayUnion(...backupTask.transactions);
    }

    if (backupTask.milestones && backupTask.milestones.length > 0) {
      properties.milestones = arrayUnion(...backupTask.milestones);
    }

    return this.database.storeDocument({
      ...properties,
      ...{ id: `${task.id}`, collectionName: 'tasks' },
    });
  }
}
