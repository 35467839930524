import { Inject, Injectable } from '@angular/core';
import { AuthenticationService } from '@cmx/shared/feature/authentication';
import { FirestoreService } from '@cmx/shared/feature/firestore';
import { HttpServiceSettings } from '@cmx/shared/feature/platform-configuration';
import { HTTPSERVICESETTINGS } from '@cmx/shared/util/environment-config';
import { TraceType, CollectionNames } from '@cmx/shared/util/interfaces';
import PouchDB from 'pouchdb';
import * as PouchdbHttpAdapter from 'pouchdb-adapter-http';
import * as PouchdbAdapterIdb from 'pouchdb-adapter-idb';
import { addRxPlugin, CollectionsOfDatabase, createRxDatabase, RxCollection, RxDatabase, RxDocument } from 'rxdb';
import { BehaviorSubject, from, of, Subscription } from 'rxjs';
import { concatMap, distinctUntilChanged } from 'rxjs/operators';
import { QuerySelector, FileInfo } from '@cmx/shared/util/interfaces';
import * as AttachmentsSchema from './schemas/attachments-schema.json';
import * as ModelsSchema from './schemas/models-schema.json';

@Injectable({
  providedIn: 'root',
})
export class RXJSDBService {
  localDB: RxDatabase;

  dbCreated$ = new BehaviorSubject<boolean>(false);
  pushCollectionChanges$ = new BehaviorSubject<string>(null);

  newAttachmentAdded$ = new BehaviorSubject<FileInfo>(null);
  assetFileCreated$ = new BehaviorSubject<FileInfo>(null);
  replicationStateCompleteSub: Subscription;

  constructor(
    @Inject(HTTPSERVICESETTINGS) private httpServiceSettings: HttpServiceSettings,
    private firestore: FirestoreService,
    private auth: AuthenticationService,
  ) {}

  public createDB() {
    addRxPlugin(PouchdbAdapterIdb);
    return from(
      createRxDatabase({
        name: 'logistics', // <- name
        adapter: 'idb', // <- storage-adapter
        password: '123123123',
        multiInstance: false, // <- multiInstance (optional, default: true)
        eventReduce: false, // <- eventReduce (optional, default: true)
        ignoreDuplicate: true,
      }),
    ).pipe(
      concatMap(database => {
        this.localDB = database as RxDatabase;
        const modelsSchema: any = (ModelsSchema as any).default;
        return from(
          (database as RxDatabase).addCollections({
            models: {
              schema: modelsSchema,
            },
          }),
        );
      }),
      concatMap(({ models }) => {
        this.dbCreated$.next(true);
        return of(models);
      }),
    );
  }

  getDatabaseInfo(): RxDatabase<CollectionsOfDatabase> {
    return this.localDB;
  }

  async storeDocument(doc: any, addDocumentId = true): Promise<RxDocument> {
    try {
      const collection = doc.collectionName;
      if (addDocumentId) {
        doc.documentId = doc.id;
      }
      const result = await this.localDB[collection].upsert(doc);
      this.pushCollectionChanges$.next(collection);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async upsertDocument(doc: any, addDocumentId = true): Promise<RxDocument> {
    try {
      const collection = doc.collectionName;
      if (addDocumentId) {
        doc.documentId = doc.id;
      }

      const result = await this.localDB[collection]?.upsert(doc);
      if (result) {
        this.pushCollectionChanges$.next(collection);
      }
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  async upsertDocumentWithCollection(doc: any, collectionName: string, addDocumentId = true): Promise<RxDocument> {
    try {
      if (addDocumentId) {
        doc.documentId = doc.id;
      }

      const result = await this.localDB[collectionName]?.upsert(doc);
      if (result) {
        this.pushCollectionChanges$.next(collectionName);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  storeBulkDocuments(docs: unknown[], collection: string): Promise<unknown> {
    return this.localDB[collection]?.bulkInsert(docs);
  }

  getDocumentById(documentId: string, collection: string): Promise<RxDocument> {
    const query = this.localDB[collection]?.findOne().where('_id').eq(documentId);
    return query?.exec();
  }

  getAllDocuments(collection: string): Promise<any[]> {
    try {
      return this.localDB[collection]?.find().exec();
    } catch (error) {
      console.log(error);
    }
  }

  async populateDocument(document: RxDocument<any>, propertiesToPopulate: string[]) {
    const populatedDocument = document.toJSON();
    for (const documentProperty of propertiesToPopulate) {
      const populatedProperty = await document.populate(documentProperty);
      if (Array.isArray(populatedProperty)) {
        populatedDocument[documentProperty] = [];
        populatedProperty.forEach(property => {
          populatedDocument[documentProperty].push(property.toJSON());
        });
      } else {
        populatedDocument[documentProperty] = populatedProperty.toJSON();
      }
    }

    return populatedDocument;
  }

  async populateTaskDocument(document: RxDocument<any>, propertiesToPopulate: string[]) {
    const populatedDocument = document.toJSON();
    for (const documentProperty of propertiesToPopulate) {
      let populatedProperty: any;
      if (documentProperty == 'referenceDocument') {
        populatedProperty = await this.getDocumentById(
          populatedDocument.referenceDocument,
          populatedDocument.parent ? 'caninescreenings' : 'cargoscreenings',
        );
      } else {
        populatedProperty = await document.populate(documentProperty);
      }
      if (Array.isArray(populatedProperty)) {
        populatedDocument[documentProperty] = [];
        populatedProperty.forEach(property => {
          populatedDocument[documentProperty].push(property.toJSON());
        });
      } else {
        populatedDocument[documentProperty] = populatedProperty?.toJSON();
      }
    }

    return populatedDocument;
  }

  async addAttachmentToDocument(
    documentId: string,
    attachmentName: string,
    attachment: Blob | string,
    contentType: string,
    collection: string,
  ): Promise<unknown> {
    try {
      const assetInfo = await this.getDocumentById(documentId, CollectionNames.assets);
      const collectionName = `a${documentId.replace(/-/g, '_')}`;
      await this.createAttachmentCollection(collectionName);
      const attachmentDoc = { collectionName, id: documentId };
      const document = await this.upsertDocument(attachmentDoc, false);

      const resultAttachment = await document.putAttachment(
        {
          id: documentId,
          data: attachment,
          type: contentType,
        },
        true,
      );

      if (collection === CollectionNames.assets) {
        this.newAttachmentAdded$.next({ asset: assetInfo.toJSON(), file: attachment as Blob });
      }

      return resultAttachment;
    } catch (error) {
      this.trace({
        data: error.message,
        type: TraceType.error,
      });
    }

    return;
  }

  async saveAssetFile(documentId: string, attachment: Blob | string, contentType: string): Promise<unknown> {
    try {
      const assetInfo = await this.firestore.getDocumentById(documentId);
      const collectionName = `a${documentId.replace(/-/g, '_')}`;
      await this.createAttachmentCollection(collectionName);
      const attachmentDoc = { collectionName, id: documentId };
      const document = await this.upsertDocument(attachmentDoc, false);

      const resultAttachment = await document.putAttachment(
        {
          id: documentId,
          data: attachment,
          type: contentType,
        },
        true,
      );

      this.assetFileCreated$.next({ asset: assetInfo, file: attachment as Blob });

      return resultAttachment;
    } catch (error) {
      this.trace({
        data: error.message,
        type: TraceType.error,
      });
    }

    return;
  }

  async createAttachmentCollection(collectionName: string) {
    const attachmentsSchema: any = (AttachmentsSchema as any).default;
    try {
      if (!this.localDB[collectionName]) {
        await this.localDB?.addCollections({
          [collectionName]: {
            schema: attachmentsSchema,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async createCollection(collectionName: string, collectionSchema: any) {
    const newSchema: any = collectionSchema;
    try {
      if (!this.localDB[collectionName]) {
        await this.localDB?.addCollections({
          [collectionName]: {
            schema: newSchema,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async removeAttachmentCollection(collectionName: string) {
    return this.localDB[collectionName]?.remove();
  }

  async getAllAttachmentsForDocument(documentId: string, collection: string): Promise<any> {
    const document = await this.getDocumentById(documentId, collection);
    return document.allAttachments();
  }

  async deleteDocumentById(documentId: string, collection: string): Promise<any> {
    const document = await this.getDocumentById(documentId, collection);
    return document.remove();
  }

  async findAll(querySelector: QuerySelector, sortBy: unknown[] = null) {
    let selector = querySelector.selector ? { selector: querySelector.selector } : null;

    if (sortBy) {
      selector = { ...selector, ...{ sort: sortBy } };
    }
    return this.localDB[querySelector.collection]?.find(selector).exec();
  }

  findOne(querySelector: QuerySelector) {
    const selector = querySelector.selector ? { selector: querySelector.selector } : null;
    return this.localDB[querySelector.collection]?.findOne(selector).exec();
  }

  public getFormAsync(formId: string): Promise<any> {
    const query: unknown = {
      collection: CollectionNames.forms,
      selector: {
        name: formId,
      },
    };
    return this.findOne(query as QuerySelector);
  }

  trace(traceEvent: any) {
    console.log(traceEvent);
    const error = {
      userId: this.auth.currentUser.id,
      type: traceEvent.type,
      collectionName: CollectionNames.log,
      timestamp: Date.now(),
      data: traceEvent.data,
      ignoreSync: true,
    };
    return this.firestore.storeDocument(error);
  }
}
