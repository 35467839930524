<mat-tab-group [(selectedIndex)]="selectedIndex" (selectedIndexChange)="selectedIndexChange($event)">
  <mat-tab *ngFor="let tab of tabs; let index = index">
    <ng-template mat-tab-label>
      <div class="tab-label">
        <mat-icon>{{ tab?.icon }}</mat-icon>
        <span>{{ tab?.label }}</span>
      </div>
      <button
        class="close-button"
        type="button"
        mat-mini-fab
        [color]="tab?.iconColor"
        *ngIf="tab?.closable && index"
        [disabled]="tabs.length === 1"
        (click)="removeTab(tab, index)"
      >
        <mat-icon>close</mat-icon>
      </button>
    </ng-template>
  </mat-tab>
</mat-tab-group>
