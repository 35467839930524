import { Injectable, OnDestroy } from '@angular/core';
import { CollectionNames } from '@cmx/shared/util/interfaces';
import { FirestoreQuery, FirestoreService } from '@cmx/shared/feature/firestore';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface DataDictionary {
  [key: string]: {
    [key: string]: any;
  };
}

@Injectable({
  providedIn: 'root',
})
export abstract class BaseDataLoaderService implements OnDestroy {
  constructor(private readonly firestore: FirestoreService) {}
  private subjectOnDestroy = new Subject<boolean>();
  private alreadyLoadedData: DataDictionary = {};

  protected async loadData(id: string, collectionName: CollectionNames): Promise<any> {
    if (this.alreadyLoadedData[collectionName] && this.alreadyLoadedData[collectionName][id]) {
      //state machine sets data null. clone it to avoid multiple loads
      const data = this.clone(this.alreadyLoadedData[collectionName][id]);
      return Promise.resolve(data);
    }

    const query: FirestoreQuery[] = [
      {
        property: 'name',
        operator: '==',
        value: id,
      },
      {
        property: 'collectionName',
        operator: '==',
        value: collectionName,
      },
    ];

    if (!this.alreadyLoadedData[collectionName]) {
      this.alreadyLoadedData[collectionName] = {};
    }

    const promise = new Promise((resolve, reject) => {
      this.firestore
        .getRealTimeUpdatesFromQuery(query)
        .pipe(takeUntil(this.subjectOnDestroy))
        .subscribe({
          error: e => reject(e),
          next: data => {
            this.alreadyLoadedData[collectionName][id] = data;
            resolve(this.clone(data));
          },
        });
    });

    return await promise;
  }

  ngOnDestroy() {
    this.subjectOnDestroy.next(true);
    this.subjectOnDestroy.complete();
  }

  private clone(data: any) {
    return JSON.parse(JSON.stringify(data));
  }
}
