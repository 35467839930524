import { Injectable } from '@angular/core';
import { getToken, Messaging } from '@angular/fire/messaging';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(private readonly messaging: Messaging) {}

  initializeFirebaseMessaging(vapidKey: string) {
    getToken(this.messaging, { vapidKey })
      .then(currentToken => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // TODO: Here we need to store the token in the user's profile
          console.log(currentToken);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch(err => {
        console.log('An error occurred while retrieving token. ', err);
        // TODO: Handle if the user doesn't give notification permission.
      });
  }
}
