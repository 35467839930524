<div class="scroll-shipment">
  <div *ngFor="let shipment of data" class="shipment-lists">
    <div class="padding-bottom-3 padding-top-1" *ngIf="shipment.properties && shipment.properties?.length">
      <ng-container *ngFor="let item of shipment.properties">
        <div *ngIf="item?.type === 'date'">
          <strong *ngIf="item?.key">{{ item?.key | titlecase }}: </strong>
          <span class="word-break">{{ item.value | date: 'medium' }}</span>
        </div>
        <div *ngIf="item?.type !== 'date'">
          <strong *ngIf="item?.key">{{ item?.key | titlecase }}: </strong>
          <span class="word-break">{{ item.value }}</span>
        </div>
      </ng-container>
    </div>

    <ng-container *ngFor="let tag of shipment?.tags">
      <cmx-card-widget-chips
        *ngIf="tag && (tag.label || tag.icon) && tag.count"
        [showChipsLabel]="tag?.showChipsLabel"
        [label]="tag.label"
        [count]="tag.count"
        [icon]="tag.icon"
        [iconType]="tag.iconType"
        [badgeColor]="tag.badgeColor"
        [isFormlyField]="false"
      ></cmx-card-widget-chips>
    </ng-container>
  </div>
  <div class="text-center full-width" *ngIf="!data?.length">No Data</div>
</div>
