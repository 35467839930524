import { CommentsService } from '../services/comments.service';
import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Comment } from '../shared-feature-comments.model';
import { skip } from 'rxjs/operators';

@Component({
  selector: 'cmx-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent implements OnInit {
  @Input() comment: Comment;
  @Input() replies: Comment[];
  @Output() newComment = new EventEmitter<Comment>();
  @Output() deleteComment = new EventEmitter<Comment>();
  @Output() updateComment = new EventEmitter<Comment>();
  public editing = false;
  public replying = false;
  private replyingTo: number;

  constructor(private commentsService: CommentsService) {}

  ngOnInit(): void {
    if (!this.comment.parentId) {
      this.commentsService.replyComment.pipe(skip(1)).subscribe((commentId: number) => {
        this.replyingTo = commentId;

        this.replying = commentId === this.comment.id || commentId === this.comment.parentId;
      });
    }
  }

  reply() {
    const replyTo = this.comment.parentId ? this.comment.parentId : this.comment.id;

    this.commentsService.replyComment.next(replyTo);
    this.commentsService.replyTo.next(this.comment.from?.fullName);
  }

  delete() {
    this.deleteComment.emit(this.comment);
  }

  deleteReply(reply: Comment) {
    this.deleteComment.emit(reply);
  }

  doneEditing(comment: Comment) {
    if (comment.id) {
      this.comment = comment;
      this.toggleEditing();
      this.updateComment.emit(comment);
    } else {
      this.newComment.emit(comment);
    }
  }

  doneReplying(comment: Comment) {
    if (this.replyingTo) {
      comment.parentId = this.replyingTo;
    }
    this.newComment.emit(comment);
  }

  toggleEditing() {
    this.editing = !this.editing;
  }
}
