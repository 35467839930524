<mat-tab-group>
  <ng-container *ngIf="field.fieldGroup">
    <mat-tab
      *ngFor="let tab of field.fieldGroup; let i = index; let last = last"
      [disabled]="i !== 0 && !isValid(field.fieldGroup[i - 1])"
    >
      <ng-template mat-tab-label>
        <mat-icon *ngIf="tab?.templateOptions?.icon">{{ tab?.templateOptions?.icon }}</mat-icon>
        {{ tab?.templateOptions?.label }}
      </ng-template>
      <formly-field [field]="tab"></formly-field> </mat-tab
  ></ng-container>
</mat-tab-group>
