import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType } from '@ngx-formly/core';
import { CameraComponent } from '../camera.component';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';

@Component({
  selector: 'cmx-image-capture',
  templateUrl: './image-capture.component.html',
  styleUrls: ['./image-capture.component.scss'],
})
export class ImageCaptureComponent extends FieldType implements OnInit {
  public images: any = [];
  @Input() isFormlyField = true;
  cameraHeight = 600;
  cameraWidth = 400;
  label = 'camera';
  icon = 'photo_camera';
  cameraButtonColor = '';
  panelClass = '';

  @Output() raiseEvent = new EventEmitter<UiEventRaised>();

  constructor(public dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.cameraButtonColor = this.to.cameraButtonColor || this.cameraButtonColor;
      this.label = this.to.label || this.label;
      this.icon = this.to.icon || this.icon;
      this.cameraWidth = this.to.cameraWidth || this.cameraWidth;
      this.cameraHeight = this.to.cameraHeight || this.cameraHeight;
      this.panelClass = this.to.panelClass || this.panelClass;
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CameraComponent, {
      panelClass: this.panelClass,
      data: {
        width: this.cameraWidth,
        height: this.cameraHeight,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.raiseCameraEvent({
          data: {
            src: result._imageAsDataUrl,
            displayName: new Date().getTime() + '',
            type: result._mimeType,
          },
          eventName: 'IMAGE_CAPTURE_EVENT',
          isNavigation: false,
        });
      }
    });
  }

  raiseCameraEvent(eventToRaise: UiEventRaised): void {
    if (this.isFormlyField) {
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseEvent.emit(eventToRaise);
    }
  }

  openCamera() {
    this.openDialog();
  }
}
