<div style="display: none" data-vjs-player id="container">
  <div id="waveform"></div>
  <audio
    #myAudio
    id="myAudio{{ this.identifier ? this.identifier : 'NewComment' }}"
    class="video-js vjs-default-skin"
  ></audio>
</div>
<button
  mat-fab
  title="Record audio files"
  [class.recording]="recording"
  (mousedown)="startRecording()"
  (mouseup)="stopRecording()"
>
  <mat-icon>graphic_eq</mat-icon>
</button>
