<ngx-charts-number-card
  [view]="view"
  [results]="data"
  [animations]="enableAnimations"
  [cardColor]="cardColor"
  [bandColor]="bandColor"
  [textColor]="textColor"
  [emptyColor]="emptyColor"
  (select)="onSelect($event)"
>
</ngx-charts-number-card>
