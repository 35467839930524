<ngx-charts-bubble-chart
  [results]="data"
  [view]="view"
  [xAxis]="axis?.showXAxis || true"
  [yAxis]="axis?.showYAxis || true"
  [xAxisLabel]="axis?.xAxisLabel || 'xAxis'"
  [yAxisLabel]="axis?.yAxisLabel || 'yAxis'"
  [showXAxisLabel]="axis?.showXAxisLabel || true"
  [showYAxisLabel]="axis?.showYAxisLabel || true"
  [showGridLines]="showGridLines"
  [animations]="enableAnimations"
  [legend]="legend?.visible || true"
  [legendTitle]="legend?.title || 'Legend'"
  [legendPosition]="legend?.position || 'right'"
  [tooltipDisabled]="tooltipDisabled"
  [xScaleMin]="scale.xMin"
  [xScaleMax]="scale.xMax"
  [yScaleMin]="scale.yMin"
  [yScaleMax]="scale.yMax"
  [minRadius]="minRadius"
  [maxRadius]="maxRadius"
  (select)="onSelect($event)"
>
</ngx-charts-bubble-chart>
