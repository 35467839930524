import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { ConfirmationAlertComponent } from './confirmation-alert.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  async openConfirmationDialog(message: string, actionText = 'Confirm', title: string = null): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmationAlertComponent, {
      data: { message, actionText, title },
    });

    const result = await lastValueFrom(dialogRef.afterClosed());

    return result === 'confirm';
  }
}
