<div #boxcontainer class="box-container flex-center vertical-center">
  <div class="time-arrow-container" *ngIf="data.length"></div>
  <div *ngFor="let item of data" class="box" [style.marginBottom.px]="75" [style.marginTop.px]="0">
    <div class="outside">
      <div class="inside flex vertical-center" [ngClass]="{ 'no-date': !item.date }">
        <mat-icon *ngIf="item.icon" class="src-icon">{{ item?.icon }}</mat-icon>
        <span class="text bold-txt horizontal-padding-1 flex-horizontal vertical-center">{{ item.name }}</span>
      </div>
    </div>

    <div [ngClass]="{ 'no-date': !item.date }" class="brokerage-date">
      <span>{{ item.date ? (item.date | date: 'M/d/yyyy, h:mm:ss a') : item.status || 'NA' }}</span>
    </div>
  </div>
</div>
<div *ngIf="!data.length" class="flex-center vertical-center">No Data</div>
