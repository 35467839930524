export function getItemFromLocalStorage(itemId: string): unknown | null {
  const storedItem = localStorage.getItem(itemId);
  if (!storedItem) {
    return null;
  }
  return JSON.parse(storedItem);
}

export function setItemToLocalStorage(itemId: string, item: unknown): void {
  return localStorage.setItem(itemId, JSON.stringify(item));
}

export function getStringFromLocalStorage(itemId: string): string | null {
  return localStorage.getItem(itemId);
}

export function setStringToLocalStorage(itemId: string, item: string): void {
  return localStorage.setItem(itemId, item);
}

export function deleteItemFromLocalStorage(itemId: string): void {
  return localStorage.removeItem(itemId);
}
