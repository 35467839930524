<ngx-charts-gauge
  [view]="view"
  [results]="data"
  [legend]="legend?.visible || true"
  [legendTitle]="legend?.title || 'Legend'"
  [legendPosition]="legend?.position || 'right'"
  [angleSpan]="angleSpan"
  [showAxis]="showAxis"
  [showText]="showText"
  [max]="maxScale"
  [min]="minScale"
  [units]="valueUnit"
  [animations]="enableAnimations"
  [tooltipDisabled]="tooltipDisabled"
  (select)="onSelect($event)"
>
</ngx-charts-gauge>
