import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppVersionLoaderModule } from '@cmx/shared/feature/app-version-loader';
import { ModalSimpleFormGeneratorModule } from '@cmx/shared/feature/modal-simple-form-generator';
import { SyncLoaderModule } from '@cmx/shared/feature/sync-loader';
import { ButtonModule } from '@cmx/shared/ui/button';
import { SharedUiHeaderModule } from '@cmx/shared/ui/header';
import { LoaderModule } from '@cmx/shared/ui/loader';
import { SidenavModule } from '@cmx/shared/ui/sidenav';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appVersionProvider, createDBProvider } from './app.initilizer';
import { services } from './services';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { provideFirestore, getFirestore, enableIndexedDbPersistence } from '@angular/fire/firestore';

import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HammerModule,
    SidenavModule,
    ButtonModule,
    SharedUiHeaderModule,
    ModalSimpleFormGeneratorModule,
    MatSnackBarModule,
    LoaderModule,
    SyncLoaderModule,
    AppVersionLoaderModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideMessaging(() => getMessaging()),
    provideAuth(() => getAuth()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    provideFirestore(() => {
      const firestore = getFirestore();
      enableIndexedDbPersistence(firestore);
      return firestore;
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [createDBProvider, appVersionProvider, ...services],
  bootstrap: [AppComponent],
})
export class AppModule {}
