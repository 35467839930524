import { Component, Input, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { mapDataWithTemplate } from '@cmx/shared/util/helper-functions';
interface ChipsData {
  label?: string;
  icon?: string;
  svgIcon?: string;
  iconType?: 'mat-icon' | 'custom' | 'svg' | undefined;
  color?: 'primary' | 'warn' | 'accent' | 'success';
  badgeColor?: 'primary' | 'warn' | 'accent';
  count?: number | string;
}
@Component({
  selector: 'cmx-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
})
export class ChipsComponent extends FieldType implements OnInit {
  @Input() isFormlyField = true;
  @Input() showLabel = false;
  @Input() data: { chips: ChipsData[] } = { chips: [] };

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.data = this.to.data || this.data;
      this.showLabel = this.to.showLabel || this.showLabel;
    }

    this.parseTemplateData();
  }

  parseTemplateData() {
    try {
      this.data = mapDataWithTemplate(this.data, this.to.template, this.data);
    } catch (error) {
      console.error(error);
    }
  }
}
