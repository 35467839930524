<div class="authentication-wrapper">
  <div class="auth-page-container">
    <div class="Logo-wrapper">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/prodeo-admin-app.appspot.com/o/icons%2Flogos%2Fprodeo-full-logo.svg?alt=media&token=3e11dbfb-91bc-4f1d-9f6a-6b6a480df666"
        alt="logo"
      />
    </div>
    <cmx-simple-form-generator
      class="login-form"
      [formTitle]="formTitle"
      [formModel]="formModel"
      [formFields]="formFields"
      (raiseFormValue)="submitUserCredentials($event)"
    ></cmx-simple-form-generator>
    <div class="reset-password">
      <a *ngIf="isLoginPage" mat-button color="primary" href="/password-reset">Forgot your password? </a>
    </div>
    <div class="reset-password">
      <a *ngIf="!isLoginPage" mat-button color="primary" href="/login">Back to login</a>
    </div>
    <div class="terms-services"><a href="#">Terms of services </a>and<a href="#"> Privacy Policy</a></div>
    <div class="app-version">v{{ version }}</div>
  </div>
</div>
<cmx-loader *ngIf="loading"></cmx-loader>
