<mat-card [ngClass]="cardColor">
  <img
    class="card-image"
    *ngIf="showLeftIcon && data?.imageSrc"
    [src]="data?.imageSrc"
    alt="Image for {{ data?.cardContent && data?.cardContent[0]?.value }}"
    (click)="iconClicked()"
  />
  <mat-icon
    class="card-icon"
    *ngIf="showLeftIcon && data?.icon && !data?.imageSrc"
    (click)="iconClicked()"
    [color]="leftIconColor"
  >
    {{ data?.icon }}
  </mat-icon>
  <mat-icon
    class="card-icon"
    *ngIf="showLeftIcon && data?.svgIcon && !data?.imageSrc"
    [svgIcon]="data?.svgIcon"
    (click)="iconClicked()"
    [color]="leftIconColor"
  >
  </mat-icon>

  <div class="card-content" *ngIf="data?.cardContent && data?.cardContent?.length" (click)="cardClicked()">
    <div *ngFor="let item of data?.cardContent">
      <span class="card-key" *ngIf="item?.key">{{ item?.key | titlecase }}: </span>
      <span class="word-break" *ngIf="!item.type">{{ item?.value }}</span>
      <span class="word-break" *ngIf="item.type && item.type ==='date'">{{ item?.value | date: 'medium'  }}</span>
    </div>
  </div>

  <mat-chip-list class="card-badge" *ngIf="showBadge" aria-label="Task Counter">
    <mat-chip color="primary" selected>{{ data?.count || 0 }}</mat-chip>
  </mat-chip-list>

  <button
    class="close-icon"
    mat-button
    *ngIf="showRightIcon && data?.rightIcon"
    [color]="rightIconColor"
    (click)="rightIconClicked()"
  >
    <mat-icon *ngIf="showRightIcon">{{ data?.rightIcon }}</mat-icon>
  </button>

  <cmx-counter
    *ngIf="showCounter"
    [itemCount]="data.count"
    [maxCount]="data.maxCount"
    [isFormlyField]="false"
    (raiseEvent)="counterEvent($event)"
    [buttonColor]="counterButtonColor"
  ></cmx-counter>
</mat-card>
