import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSimpleFormGeneratorComponent } from './modal-simple-form-generator.component';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGeneratorModule } from '@cmx/shared/feature/form-generator';

@NgModule({
  imports: [CommonModule, MatDialogModule, FormGeneratorModule],
  declarations: [ModalSimpleFormGeneratorComponent],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],
  exports: [ModalSimpleFormGeneratorComponent],
})
export class ModalSimpleFormGeneratorModule {}
