import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { InfoSnackbarComponent } from './info-snackbar/info-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private _snackBar: MatSnackBar) {}

  openInfoSnackBar(
    message: string,
    durationInSeconds: number = 3,
    horizontalPosition?: string,
    verticalPosition?: string,
  ) {
    const durationInMiliseconds: number = durationInSeconds * 1000;
    this._snackBar.openFromComponent(InfoSnackbarComponent, {
      duration: durationInMiliseconds,
      data: message,
      horizontalPosition: horizontalPosition as MatSnackBarHorizontalPosition,
      verticalPosition: verticalPosition as MatSnackBarVerticalPosition,
    });
  }
}
