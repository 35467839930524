import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UiEventRaised } from '@cmx/shared/feature/platform-configuration';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'cmx-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent extends FieldType implements OnInit {
  @Input() placeholder = '';
  @Input() searchIconColor = '';
  @Input() closeIconColor = '';
  @Input() sendButtonColor = 'primary';
  @Input() searchHint: string;
  @Input() searchText = '';
  @Input() isFormlyField = true;
  @Output() raiseSearchTerm = new EventEmitter<UiEventRaised>();
  @ViewChild('searchControl') searchControl: ElementRef;

  control = new FormControl();

  ngOnInit(): void {
    if (this.isFormlyField) {
      this.placeholder = this.to.placeholder || this.placeholder;
      this.searchHint = this.to.searchHint || this.searchHint;
      this.searchText = this.to.searchText || this.searchText;
      this.sendButtonColor = this.to.sendButtonColor || this.sendButtonColor;
      this.closeIconColor = this.to.closeIconColor || this.closeIconColor;
      this.searchIconColor = this.to.searchIconColor || this.searchIconColor;
    }
    this.control.setValue(this.searchText);
    this.control.valueChanges.subscribe(searchTerm => {
      this.searchText = searchTerm;
    });

    setTimeout(() => {
      if (this.searchControl && this.searchControl.nativeElement) {
        this.searchControl.nativeElement.focus();
      }
    });
  }

  onSearchChange(searchTerm: string): void {
    const eventToRaise: UiEventRaised = {
      eventName: 'SEARCH_KEY_UP',
      data: searchTerm,
      isNavigation: false,
    };
    if (this.isFormlyField) {
      this.formControl.setValue(searchTerm);
      this.to.getFieldEvent(eventToRaise);
    } else {
      this.raiseSearchTerm.emit(eventToRaise);
    }
  }

  clearInput() {
    if (this.searchText) {
      this.control.setValue('');
      this.onSearchChange(this.searchText);
    }
  }

  searchSubmit() {
    if (this.searchText) this.onSearchChange(this.searchText);
  }
}
