<ng-container *ngIf="files">
  <ul class="abs-uploader-list-view" *ngIf="files.length">
    <cmx-uploader-tile
      *ngFor="let file of files"
      [file]="file"
      (remove)="removeFile($event)"
      [readOnly]="readOnly"
    ></cmx-uploader-tile>
  </ul>
</ng-container>
