import { Component } from '@angular/core';
import { AuthenticationService } from '@cmx/shared/feature/authentication';

@Component({
  selector: 'cmx-sync-loader',
  templateUrl: './sync-loader.component.html',
  styleUrls: ['./sync-loader.component.scss'],
})
export class SyncLoaderComponent {
  constructor(public auth: AuthenticationService) {}
}
