import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'cmx-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
  static cached: string[] = [];

  private url: string | ArrayBuffer;

  @Input() set image(url: string | ArrayBuffer) {
    this.url = url;
    this.loadImage();
  }

  @Input() readonly = false;
  @Input() filled = true;

  private source$: BehaviorSubject<string>;
  public src$: Observable<string>;

  ngOnInit(): void {
    const url = this.url as string;
    const useCustomPic = url && UserAvatarComponent.cached.includes(url);
    const defaultSource = useCustomPic
      ? url
      : 'https://st3.depositphotos.com/1767687/16607/v/600/depositphotos_166074422-stock-illustration-default-avatar-profile-icon-grey.jpg';
    this.filled = useCustomPic;
    this.source$ = new BehaviorSubject<string>(defaultSource);
    this.src$ = this.source$.asObservable().pipe(distinctUntilChanged());
  }

  loadImage(): void {
    if (this.url) {
      const img = new Image();
      const url = this.url as string;

      img.onload = () => {
        this.filled = true;
        UserAvatarComponent.cached.push(url);
        this.source$.next(url);
      };

      img.src = url;
    }
  }
}
