import { Injectable, EventEmitter } from '@angular/core';
import { stripRestrictedKeys } from '@cmx/shared/util/helper-functions';
import { fromEvent } from 'rxjs';

export interface ScanEvent {
  barcodeData: string;
  barcodeType?: string;
  houseBillNumber?: string;
  masterBillNumber?: string;
  carrierNumber?: string;
  uldNumber?: string;
  location?: string;
  pieceNumber?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BarcodeListnerService {
  public scan: EventEmitter<ScanEvent> = new EventEmitter<ScanEvent>();
  private currentTime = 0;
  private currentTimeDiff = 0;
  private currentKey = '';
  private firstKey = '';
  private outputString = '';
  private scanStarted = false;
  private timeout: any = null;
  public barcodeTypes = Object.freeze({
    NA: 'NA',
    HouseBill: 'HouseBill',
    MasterBill: 'MasterBill',
    Uld: 'Uld',
    Door: 'Door',
    Area: 'Area',
    Truck: 'Truck',
    User: 'User',
    ScreeningArea: 'ScreeningArea',
    BuildArea: 'BuildArea',
    WeightScale: 'WeightScale',
  });
  private regexParserArray = [
    {
      regex: /^O(.?)*\+[Y|S][0-9]{4}\+$/g,
      parse: (barcode: string, scan: any) => {
        const barcodeSegments = barcode.split('+');
        scan.barcodeType = this.barcodeTypes.HouseBill;
        scan.houseBillNumber = barcodeSegments[0].substr(1);
        scan.pieceNumber = Number(barcodeSegments[1].substr(1));
      },
    },
    {
      regex: /^O\w{8}\+[Y|S][0-9]{4}\+$/g,
      parse: (barcode: string, scan: any) => {
        const barcodeSegments = barcode.split('+');
        scan.barcodeType = this.barcodeTypes.HouseBill;
        scan.houseBillNumber = barcodeSegments[0].substr(1);
        scan.pieceNumber = Number(barcodeSegments[1].substr(1));
      },
    },
    {
      regex: /[A-Z][A-Z][P][A-Z][A-Z][0-9][0-9][0-9][0-9][0-9]/g,
      parse: (barcode: string, scan: any) => {
        scan.barcodeType = this.barcodeTypes.Uld;
        scan.uldNumber =
          barcode.substr(9, 1) == '0'
            ? barcode.substr(2, 7) + barcode.substr(0, 2)
            : barcode.substr(2, 8) + barcode.substr(0, 2);
      },
    },
    {
      regex: /(\w{7,1000}|H\w{7,1000})(\s)*\+[Y][0-9][0-9][0-9][0-9](\+)*/g,
      parse: (barcode: string, scan: any) => {
        scan.barcodeType = this.barcodeTypes.HouseBill;

        const hawbPieceArray = barcode.split('+Y');

        scan.houseBillNumber = hawbPieceArray[0].trim();
        if (scan.houseBillNumber.length > 7 && scan.houseBillNumber[0] == 'H') {
          scan.houseBillNumber = scan.houseBillNumber.substr(1);
        }

        scan.pieceNumber = Number(hawbPieceArray[1].substr(0, 4));
      },
    },
    {
      regex: /^H\w{7,1000}(\+)*/g,
      parse: (barcode: string, scan: any) => {
        scan.barcodeType = this.barcodeTypes.HouseBill;
        scan.houseBillNumber = barcode.split('+')[0].trim().substr(1);
        scan.pieceNumber = 0;
      },
    },
    {
      // regex: /^[BDSTCWL][-|*](.?)*/g,
      regex: /^t/,
      parse: (barcode: string, scan: any) => {
        switch (barcode[0].toUpperCase()) {
          case 'B':
            scan.barcodeType = this.barcodeTypes.Area;
            break;
          case 'D':
            scan.barcodeType = this.barcodeTypes.Door;
            break;
          case 'S':
            scan.barcodeType = this.barcodeTypes.ScreeningArea;
            break;
          case 'T':
            scan.barcodeType = this.barcodeTypes.Truck;
            break;
          case 'C':
            scan.barcodeType = this.barcodeTypes.BuildArea;
            break;
          case 'W':
            scan.barcodeType = this.barcodeTypes.WeightScale;
            break;
          case 'L':
            scan.barcodeType = this.barcodeTypes.Uld;
            break;
        }
        if (barcode[0].toUpperCase() == 'L') {
          scan.uldNumber = barcode.substr(2);
        } else {
          scan.location = barcode.substr(2);
        }
      },
    },
    {
      regex: /^U[-|*]\d+/g,
      parse: (barcode: string, scan: any) => {
        if (barcode.length <= 10) {
          scan.barcodeType = this.barcodeTypes.User;
          scan.userNumber = Number(barcode.substr(2));
        }
      },
    },
    {
      regex: /^(T999)\w+/g,
      parse: (barcode: string, scan: any) => {
        if (barcode.length <= 11) {
          scan.barcodeType = this.barcodeTypes.MasterBill;
          scan.carrierNumber = 'T999';
          scan.masterBillNumber = barcode.substr(4);
        }
      },
    },
    {
      regex: /(^([0-9]{11})$)|(^([0-9]{16})$)|(^([0-9]{15})$)/g,
      parse: (barcode: string, scan: any) => {
        scan.barcodeType = this.barcodeTypes.MasterBill;
        scan.carrierNumber = barcode.substr(0, 3);
        scan.masterBillNumber = barcode.substr(3, 8);
      },
    },
    {
      regex: /^(\w\w\w-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]-\w\w\w)$/g,
      parse: (barcode: string, scan: any) => {
        scan.barcodeType = this.barcodeTypes.MasterBill;
        scan.carrierNumber = barcode.substr(4, 3);
        scan.masterBillNumber = barcode.substr(8, 8);
      },
    },
    {
      regex: /^[A-Z][A-Z][A-Z]\w{7}[0-9][0-9][0-9][0-9][0-9]/g,
      parse: (barcode: string, scan: any) => {
        scan.barcodeType = this.barcodeTypes.HouseBill;
        scan.houseBillNumber = barcode.substr(3, 7);
        scan.pieceNumber = Number(barcode.substr(10, 5));
      },
    },
    {
      regex: /\w{7}/g,
      parse: (barcode: string, scan: any) => {
        scan.barcodeType = this.barcodeTypes.HouseBill;
        scan.houseBillNumber = barcode.substr(1);
        scan.pieceNumber = 0;
      },
    },
  ];

  constructor() {
    fromEvent(document, 'keypress').subscribe(event => {
      this.keypressHandler(event);
    });
  }

  private keypressHandler(e: any) {
    const timestamp = new Date().getTime();
    if (this.firstKey && !this.scanStarted && this.currentTime && timestamp - this.currentTime >= 80) {
      this.reset();
    }

    if (!this.firstKey && !this.scanStarted) {
      this.firstKey = e.key;
    } else {
      this.currentKey = e.key;
    }

    if (this.currentTime) {
      this.currentTimeDiff = timestamp - this.currentTime;
    }

    this.currentTime = timestamp;

    if (this.currentTimeDiff > 0) {
      if (this.currentTimeDiff < 80) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.scanStarted = true;
        this.outputString += this.firstKey;
        this.outputString += this.currentKey;
        this.firstKey = '';

        this.timeout = setTimeout(() => {
          try {
            const barcodePrefix = '#';
            let outputString = this.outputString.trim();
            outputString = outputString.replace(/Enter/g, '');
            outputString = outputString.replace(/undefined/g, '');

            if (outputString.startsWith(barcodePrefix)) {
              outputString = outputString.replace(barcodePrefix, '');
              this.scan.emit({ barcodeData: stripRestrictedKeys(outputString, ["'", '\\', '"', '<', '>']) });
            }
            this.scanStarted = false;
            this.reset();
          } catch (e) {
            alert(e);
          }
        }, 200);
      } else {
        this.reset();
      }
    }
  }

  private reset() {
    if (this.scanStarted) {
      return;
    }

    this.scanStarted = false;
    this.outputString = '';
    this.currentTime = 0;
    this.currentTimeDiff = 0;
    this.firstKey = '';
    this.currentKey = '';
    this.timeout = null;
  }

  public parse(barcodeData: string) {
    const scan = {
      barcodeData: barcodeData,
      barcodeType: this.barcodeTypes.NA,
      houseBillNumber: null,
      masterBillNumber: null,
      carrierNumber: null,
      uldNumber: null,
      location: null,
      pieceNumber: null,
    };

    for (let i = 0; i < this.regexParserArray.length; ++i) {
      const matchArr = barcodeData.match(this.regexParserArray[i].regex);
      if (matchArr) {
        this.regexParserArray[i].parse(matchArr[0].trim(), scan);
        break;
      }
    }

    return scan;
  }
}
