import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommentsService {
  public replyComment = new BehaviorSubject<number>(null);
  public replyTo = new BehaviorSubject<string>('');
  constructor() {}
}
